// import MeasureTools from './Tools/CesiumMeasure'
import MeasureTools from './Tools/cesium-measure'
import { getEntityPolygonCenter } from '@/utils/cesiumUtil'

const measureRefKey = {
  width: 'measureDistanceContainter',
  long: 'measureDistanceContainter',
  height: 'measureHeightContainter',
  area: 'measureAreaContainter',

  distance: 'measureDistanceContainter'
}

const measureRefKey2 = {
  width: 'drawLineMeasureGraphics',
  long: 'drawLineMeasureGraphics',
  height: 'measureHeight',
  area: 'drawAreaMeasureGraphics',

  distance: 'drawLineMeasureGraphics'
}

export default {
  data() {
    return {
      isMeasureIng: false
    }
  },

  methods: {
    // @NODE: 参考其他源码写的测量工具
    handlerModelMeasure2(data) {
      if (!this._measureTool2) {
        const viewer = this.getViewer()
        this._measureTool2 = new MeasureTools(viewer)
      }
      this._measureTool2[measureRefKey2[data.value]]()
    },

    clearMeasure2() {
      this._measureTool2?.destroy()
      this._measureTool2 = null
    },

    // @NODE: 这里使用的是vue-cesium提供的测量工具
    handlerModelMeasure(data) {
      const lastData = this.lastMeasureData

      if (lastData && lastData.value) {
        return this.clearMeasureDrawdata()
      }

      this.lastMeasureData = data

      const key = data.value

      this.$refs[measureRefKey[key]].measuring = true
    },

    measureActiveEvt({ isActive }) {
      this.isMeasureIng = isActive
    },

    measureMeasureEvt(result) {
      // 有结果, 删除原格式, 传递数据
      if (result.finished) {
        const data = this.lastMeasureData

        const polyline = result.polyline
        this.$emit('measure-success', {
          data,
          polyline
        })

        // this.measureDrawData = polyline
        // this.handlerDrawMeasure()

        this.clearMeasureDrawdata()
      }
    },

    clearMeasureDrawdata() {
      if (!this.lastMeasureData) return
      const key = this.lastMeasureData.value

      this.$refs[measureRefKey[key]].measuring = false
      this.$refs[measureRefKey[key]].clear()

      this.lastMeasureData = null
    },

    getDrawMeasureData(data = [], params = {}, colors) {
      let result = {}
      Object.keys(data).map((key) => {
        result[key] = this.handlerDrawMeasure(data[key], key, params, {
          color: colors[1] || '',
          backgroundColor: colors[0] || ''
        })
      })

      return result
    },

    handlerDrawMeasure({ data, polyline }, key, params = {}, otherOptions = {}) {
      let { value, label } = data
      let { positions, distance, height } = polyline
      const entityGenerate = this.getEntity()

      if (value === 'height') {
        positions.splice(1, 1)
      }

      if (params.drawTool) {
        const drawConfig = JSON.parse(params.drawTool.config)
        let type = drawConfig.child || drawConfig.drawType

        label = label.replace(/测量/, '')

        if (type === 'road') {
          label = label === '宽度' ? '路宽' : '路长'
          otherOptions = {
            color: '#000',
            backgroundColor: '#fff'
          }
        }
      }

      // const cartesian3s = positions.map(p => new Cesium.Cartesian3(p.x, p.y, p.z))

      const text = `${label.replace(/测量/, '')}：${(distance || height).toFixed(2)}米`

      let centerPosition = getEntityPolygonCenter(positions)

      // 高度的中心为其中一个点加上高度一半
      if (value === 'height') {
        const { lon, lat } = this.cartesian3ToLonLat(positions[0])
        centerPosition = Cesium.Cartesian3.fromDegrees(lon, lat, height / 2)
      }

      const primitive = entityGenerate.measureDraw(`measure-${key}`, positions, {
        type: value,
        text,
        width: 15,
        clampToGround: value !== 'height',
        centerPosition,
        distanceDisplayCondition: otherOptions.distanceDisplayCondition,
        ...otherOptions
      })

      return {
        params,
        ...primitive
      }
    }
  }
}
