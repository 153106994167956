//定义流动线
// @NODE: 来源ysCesium, 待整理

/* eslint-disable */
function initPolylineTrailLinkMaterialProperty(data) {
  function PolylineTrailLinkMaterialProperty(color, duration) {
    this._definitionChanged = new Cesium.Event()
    this._color = null
    this._colorSubscription = null
    this.color = color
    this.duration = duration
    this._time = new Date().getTime()
  }
  Object.defineProperties(PolylineTrailLinkMaterialProperty.prototype, {
    isConstant: {
      get: function () {
        return false
      }
    },
    definitionChanged: {
      get: function () {
        return this._definitionChanged
      }
    },
    color: Cesium.createPropertyDescriptor('color')
  })
  PolylineTrailLinkMaterialProperty.prototype.getType = function (time) {
    return 'PolylineTrailLink'
  }
  PolylineTrailLinkMaterialProperty.prototype.getValue = function (time, result) {
    if (!Cesium.defined(result)) {
      result = {}
    }
    result.color = Cesium.Property.getValueOrClonedDefault(this._color, time, Cesium.Color.WHITE, result.color)
    result.image = Cesium.Material.PolylineTrailLinkImage
    result.time = ((new Date().getTime() - this._time) % this.duration) / this.duration
    return result
  }
  PolylineTrailLinkMaterialProperty.prototype.equals = function (other) {
    return this === other || (other instanceof PolylineTrailLinkMaterialProperty && Cesium.Property.equals(this._color, other._color))
  }
  Cesium.PolylineTrailLinkMaterialProperty = PolylineTrailLinkMaterialProperty
  Cesium.Material.PolylineTrailLinkType = 'PolylineTrailLink'
  Cesium.Material.PolylineTrailLinkImage = data.flowImage //图片
  Cesium.Material.PolylineTrailLinkSource =
    'czm_material czm_getMaterial(czm_materialInput materialInput)\n\
                                               {\n\
                                                    czm_material material = czm_getDefaultMaterial(materialInput);\n\
                                                    vec2 st = materialInput.st;\n\
                                                    vec4 colorImage = texture2D(image, vec2(fract(st.s * repeat - time), st.t));\n\
                                                    material.alpha = colorImage.a * color.a;\n\
                                                    material.diffuse = (colorImage.rgb+color.rgb)/2.0;\n\
                                                    return material;\n\
                                                }'
  // material.alpha:透明度;material.diffuse：颜色;
  Cesium.Material._materialCache.addMaterial(Cesium.Material.PolylineTrailLinkType, {
    fabric: {
      type: Cesium.Material.PolylineTrailLinkType,
      uniforms: {
        color: new Cesium.Color(1.0, 0.0, 0.0, 0.5),
        image: Cesium.Material.PolylineTrailLinkImage,
        time: 0,
        repeat: data.repeat || 10
      },
      source: Cesium.Material.PolylineTrailLinkSource
    },
    translucent: function (material) {
      return true
    }
  })
}

export default (viewer, data) => {
  initPolylineTrailLinkMaterialProperty(data)
  data.options.polyline.material = new Cesium.PolylineTrailLinkMaterialProperty(data.color, data.interval)
  return viewer.entities.add(data.options)
}

export const getMaterial = data => {
  initPolylineTrailLinkMaterialProperty(data)
  return new Cesium.PolylineTrailLinkMaterialProperty(data.color, data.interval)
}
