import { mapState } from 'vuex'

import modelApi from '@/api/model'
import HtmlHostMsg from 'HtmlHostMsg'
import { SCEN_ARIO, TOOL_COLOR_LIST } from '@/utils/conf'

import { getEntityPolygonCenter } from '@/utils/cesiumUtil'

export default {
  data() {
    return {
      floorLists: [],
      currentSelectFloor: ''
    }
  },

  created() {
    this.cacheEntity = {}
    this.cacheLists = []
    // 基础数据的测量
    this.measureEntityLists = []

    this.fetchBildTreeByBuildId()
  },

  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.listenLeftClickData()
      }, 3000)
    })
  },

  beforeDestroy() {
    if (this.modifyHandler) {
      // 移除侦听器
      this.modifyHandler.destroy()
    }
  },

  computed: {
    ...mapState('planDrill', ['enterInside'])
  },

  watch: {
    '$store.state.iframe.isRelevance': {
      deep: true,
      immediate: true,
      handler(data) {
        if (!data) return

        const key = data?.data?.modelKey

        if (data.singleLayer !== void 0) {
          this.floorStatusIsViewOtherFloor = data.singleLayer
        }

        if (data.convert3D !== void 0) {
          this.floorStatusIs3d = data.convert3D
        }

        if (key && this.currentSelectFloor !== key) {
          this.currentSelectFloor = key
        }
      }
    }
  },

  methods: {
    fetchBildTreeByBuildId() {
      modelApi
        .getbuildTreeData({
          id: this.$route.query.id,
          buildId: this.$store.state.planDrill.moduleBuildId
        })
        .then((data) => {
          this.floorLists = data[0].children
        })
    },

    // 分配执行方法
    async distributedExecutionMethod(data) {
      if (data.key === 'info') {
        return this.$refs.infoModel.open()
      }
      // 义务消防队
      if (data.key === 'duty') {
        return this.$refs.fireDutyModel.open()
      }
      // 组织结构
      if (data.key === 'organization') {
        return this.$refs.organizationModel.open()
      }
      this.handlerDistributedMethod(data)
    },

    // 重置上一个类型的数据
    resetLastKeyDrawData(data) {
      if (!data) return

      if (data.key === 'info') {
        this.$refs.infoModel.onClose()
      } else {
        const handler = (item) => {
          const key = item.key
          if (item.all) {
            this.BasicOverview.resetInsideData(key)
            this.BasicOverview.resetOutsideDataByKey(key)
          } else {
            const isInside = item.scene === SCEN_ARIO.INTERNAL

            this.BasicOverview[isInside ? 'resetInsideData' : 'resetOutsideDataByKey'](key)
          }
        }

        if (data.lists) {
          return data.lists.forEach(item => handler(item))
        }
        handler(data)
      }
    },

    async handlerDistributedMethod(data) {
      const handler = (item) => {
        const { scene, all } = item
        const isInside = scene !== void 0

        if (all) {
          return this.BasicOverview.handlerAllDrawData(item)
        } else if (isInside) {
          this.changeSceneStatus(true)
          return this.BasicOverview.handlerInside(item)
        }
        this.checkCurrentSceneStatus()
        return this.BasicOverview.handlerOutside(item)
      }

      if (data.lists) {
        let i = 0
        const fn = () => {
          handler(data.lists[i]).then(() => {
            i++
            if (i === data.lists.length) return
            return fn()
          })
        }
        fn()
      }
      handler(data)
    },

    // 选择重点部位
    selectKeyPartData(item) {
      const isInside = item.scene === SCEN_ARIO.INTERNAL
      if (isInside) {
        this.BasicOverview.flyToInsideModel(item, 'keyParts')
      } else {
        this.flyToEntityByData(item)
      }
      this.changeSceneStatus(isInside)
    },

    selectWaterSystemData(item) {
      const isInside = item.scene === SCEN_ARIO.INTERNAL
      if (isInside) {
        this.BasicOverview.flyToInsideModel(item, item.drawTool.name)
      } else {
        this.flyToEntityByData(item)
      }
      this.changeSceneStatus(isInside)
    },

    checkCurrentSceneStatus() {
      if (this.enterInside) {
        this.changeSceneStatus(false)
      }
    },

    changeSceneStatus(isInside = false) {
      this.$parent.changeSceneStatus(isInside)
    },

    onChangeFloorInside(item) {
      this.currentSelectFloor = item.modelKey
      HtmlHostMsg.event('floor', { view: true, modelKey: item.modelKey, otherFloors: true, html: this.isPc })
    },

    flyToEntityByData(data) {
      let positions
      if (typeof data.config === 'object') {
        positions = data.config.positions
      } else {
        positions = JSON.parse(data.config).positions
      }

      let center
      if (positions instanceof Array) {
        center = getEntityPolygonCenter(positions)
      } else {
        center = new Cesium.Cartesian3(positions.x, positions.y, positions.z)
      }

      try {
        // 避免聚焦太近， 需要一个固定高
        let { lon, lat } = this.cesiumBox().c2LonLat(center)

        this.cesiumBox().handlerFlyTo(Cesium.Cartesian3.fromDegrees(lon, lat, 350), {
          duration: 2
        })
        // 获取实体并闪烁
        const { primitive } = this.cacheEntity[data.drawTool.id][data.id]
        this.cesiumBox().focuAndflash(primitive.entity, data)
      } catch (error) {
        console.log(error)
      }
    },

    // 处理绘画数据, 进行绘画, 添加信息弹窗
    handlerDrawData({ data, reset, callback }) {
      if (reset) {
        // 每次绘制时清空上一次绘制缓存
        this.resetCesiumModel()
      }

      for (const item of data) {
        try {
          // 获得配置表
          const config = item.config ? JSON.parse(item.config) : {}
          const drawConfig = JSON.parse(item.drawTool.config)
          drawConfig.options = drawConfig.options || {}

          const key = item.id

          // 绘画工具id
          const drawToolId = item.drawTool.id
          // 生成信息弹窗
          const overlay = this.cesiumBox().generateOverlay({ ...item, drawConfig })
          // 获取绘制类
          const entityGenerate = this.cesiumBox().getEntity()

          let positions = config.positions

          if (positions instanceof Array) {
            if (positions[0] instanceof Object) {
              positions = positions.map(p => new Cesium.Cartesian3(p.x, p.y, p.z))
            } else {
              // positions = arrayHeightsFormat(positions)
            }
          } else {
            positions = new Cesium.Cartesian3(positions.x, positions.y, positions.z)
          }
          const icon = drawConfig?.marker?.url || item?.drawTool?.icon

          let type = drawConfig.child || drawConfig.drawType

          // @TODO: 后续要将消防设施等点类型改成textLabel
          if (type === 'point') {
            type = 'textLabel'

            drawConfig.options = {
              ...drawConfig.options,
              color: '#fff',
              backgroundColor: '#f39c12'
            }
          }

          const entityId = `${drawToolId}-${key}`

          if (!config.label) {
            config.label = {
              text: item.drawName
            }
          }

          const primitive = entityGenerate[type](entityId, positions, {
            iconWidth: 30,
            iconHeight: 40,
            text: item.drawName,
            modelUrl: item.modelUrl,
            label: config.label || '',
            minimumHeights: config.minimumHeights || [],
            waterPositions: drawConfig.waterPositions || [],
            waterPipePositions: drawConfig.waterPipePositions || [],
            centerPosition: { lon: item.longitude, lat: item.latitude },
            icon,
            pointIcon: icon,
            pointPixelOffset: [0, -20],
            ...drawConfig.options,
            backgroundColor: item.color || drawConfig.options.backgroundColor,
            distanceDisplayCondition: [0, Number.POSITIVE_INFINITY]
          })

          if (!this.cacheEntity[drawToolId]) {
            this.cacheEntity[drawToolId] = {}
          }

          // 绘制量算结果
          let measureData = null

          if (config.measureData && +drawToolId === 54) {
            measureData = this.cesiumBox().getDrawMeasureData(config.measureData, item, TOOL_COLOR_LIST[item.drawType])
            // 数据格式
            // { timeStamp: { params, entity, hide, show, remove } }
            this.measureEntityLists = {
              ...this.measureEntityLists,
              ...measureData
            }
          }

          // 添加实体
          // 这个对象用来查询
          this.cacheEntity[drawToolId][key] = {
            primitive,
            overlay,
            data: item,
            measureData: config.measureData
          }
          // 这个用来方便做清除操作或循环查询
          if (!this.cacheLists) this.cacheLists = []

          this.cacheLists.push({
            id: key,
            overlay,
            primitive,
            data: item,
            drawToolId
          })

          if (callback && typeof callback === 'function') {
            // eslint-disable-next-line callback-return
            callback({
              id: key,
              entityId,
              overlay,
              primitive,
              measureData
            })
          }
        } catch (error) {
          console.log(error)
        }
      }
    },

    // 监听点击实体
    listenLeftClickData() {
      const viewer = window.viewerData

      this.modifyHandler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas)

      this.modifyHandler.setInputAction((movement) => {
        let target = viewer.scene.pick(movement.position)
        // const cartesian = viewer.sc/ene.pickPosition(movement.position)

        this.handlerLeftClick(target)
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK)
    },

    handlerLeftClick(target) {
      // 隐藏建筑体弹窗
      if (this.lastOverlayId) {
        this.changeOverlay(this.lastOverlayId)
        this.lastOverlayId = null
      }

      // 绘画时禁止
      if (this.cesiumBox().isDrawing) return

      if (!Cesium.defined(target) || !Cesium.defined(target.id)) return

      // 这是建筑体的弹窗
      this.changeOverlay(target.id?._objId, true)
    },

    changeOverlay(objId, isShown = false) {
      if (!objId) return

      const [toolId, id] = objId.split('-')

      if (!this.cacheEntity[toolId]) return
      const overlay = this.cacheEntity[toolId][id].overlay

      if (!overlay) return

      overlay[isShown ? 'show' : 'hide']()

      if (isShown) {
        this.lastOverlayId = objId
      }
    },

    // 删除已经绘制的数据
    resetCesiumModel(resetViews) {
      this.handlerResetCommon('cacheLists', resetViews)
      this.resetMeasureEntity()

      this.handlerResetMeasureData('measureEntityLists')

      this.handlerHideLastOverlay()
    },

    handlerHideLastOverlay() {
      // 建筑体弹窗
      if (this.lastOverlayId) {
        this.changeOverlay(this.lastOverlayId)
        this.lastOverlayId = null
      }
    }
  }
}
