import fireIcon from '@/assets/images/model/fire4.png'
// import fireIcon2 from '@/assets/images/model/fire-icon.png'

// https://cesium.com/docs/cesiumjs-ref-doc/ParticleSystem.html?classFilter=ParticleSystem

export default class Particle {
  constructor(viewer, position, options = {}) {
    this.__viewer = viewer

    if (!position) throw new Error('position is undefined')

    this.__position = position
    // 赋予默认值
    // options.id = options.id

    // 图片
    options.image = options.image || fireIcon

    // 图片大小
    options.particleSize = options.particleSize || 25.0
    options.imageSize = options.imageSize || new Cesium.Cartesian2(options.particleSize, options.particleSize)

    // 粒子生命开始到结束颜色过渡
    options.startColor = options.startColor || Cesium.Color.RED.withAlpha(0.7)
    options.endColor = options.endColor || Cesium.Color.WHITE.withAlpha(0.0)

    // 粒子变形大小
    options.startScale = options.startScale || 3.0
    options.endScale = options.endScale || 6.0

    // 每秒发射的粒子数
    options.emissionRate = options.emissionRate || 10.0

    // 设置粒子寿命可能的持续时间的最小限制（以秒为单位），在该范围内将随机选择粒子的实际寿命。
    options.minimumParticleLife = options.minimumParticleLife || 1.2
    options.maximumParticleLife = options.maximumParticleLife || 1.2

    // 设置以米/秒为单位的最小范围，在该范围上可以随机选择粒子的实际速度。
    options.minimumSpeed = options.minimumSpeed || 1.0
    options.maximumSpeed = options.maximumSpeed || 4.0

    // 表示粒子系统在生命周期的给定时间内产生的粒子爆发。
    // 常用于爆炸
    // options.bursts = options.bursts || []
    if (options.bursts) {
      options.bursts = options.bursts.map(item => new Cesium.ParticleBurst(item))
    } else {
      options.bursts = [
        new Cesium.ParticleBurst({
          time: 5.0,
          minimum: 150,
          maximum: 200
        }),
        new Cesium.ParticleBurst({
          time: 10.0,
          minimum: 200,
          maximum: 250
        })
      ]
    }

    // 粒子发射器/ 默认圆形发射
    // https://cesium.com/docs/cesiumjs-ref-doc/ParticleEmitter.html
    options.emitter = options.emitter ? new Cesium[options.emitter]() : new Cesium.CircleEmitter(2.0)

    this._generate(options)
  }

  get entity() {
    return this.__entity
  }

  get particle() {
    return this.__particleSystem
  }

  destroy() {
    const viewer = this.__viewer
    viewer.entities.remove(this.__entity)
    viewer.scene.primitives.remove(this.__particleSystem)

    viewer.scene.preUpdate.removeEventListener(this.__listeners)
  }

  // 设置粒子属性
  setParticleAttr(attr, val) {
    try {
      this.__particleSystem[attr] = val
    } catch (error) {
      // console.log(error)
    }
  }

  _generate(options) {
    const viewer = this.__viewer

    const start = Cesium.JulianDate.now()
    const stop = Cesium.JulianDate.addSeconds(start, 120, new Cesium.JulianDate())

    // 粒子根据地球时间流动而运动
    viewer.clock.startTime = start.clone()
    viewer.clock.stopTime = stop.clone()
    viewer.clock.currentTime = start.clone()
    viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP //Loop at the end
    viewer.clock.multiplier = 1
    viewer.clock.shouldAnimate = true

    const emitterModelMatrix = new Cesium.Matrix4()
    const translation = new Cesium.Cartesian3()
    const rotation = new Cesium.Quaternion()
    let hpr = new Cesium.HeadingPitchRoll()
    const trs = new Cesium.TranslationRotationScale()

    const computeModelMatrix = (entity, time) => entity.computeModelMatrix(time, new Cesium.Matrix4())

    const computeEmitterModelMatrix = () => {
      hpr = Cesium.HeadingPitchRoll.fromDegrees(0.0, 0.0, 0.0, hpr)
      trs.translation = Cesium.Cartesian3.fromElements(0.0, 0.0, 1.4, translation)
      trs.rotation = Cesium.Quaternion.fromHeadingPitchRoll(hpr, rotation)

      return Cesium.Matrix4.fromTranslationRotationScale(trs, emitterModelMatrix)
    }

    const entity = viewer.entities.add({
      objId: options.id,
      position: this.__position
      // billboard: {
      //   image: fireIcon2,
      //   pixelOffset: new Cesium.Cartesian2(0, -20),
      //   // 随相机距离改变的缩放参数
      //   scaleByDistance: new Cesium.NearFarScalar(500, 1, 1000, 0.5),
      //   // 随相机距离显隐参数
      //   distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 2000)
      // }
      // scaleByDistance: new Cesium.NearFarScalar(200, 1, 600, 0.5)
    })

    const particleSystem = viewer.scene.primitives.add(
      new Cesium.ParticleSystem({
        objId: options.id,

        image: options.image,
        imageSize: options.imageSize,

        startColor: options.startColor,
        endColor: options.endColor,

        startScale: options.startScale,
        endScale: options.endScale,

        minimumParticleLife: options.minimumParticleLife,
        maximumParticleLife: options.maximumParticleLife,

        minimumSpeed: options.minimumSpeed,
        maximumSpeed: options.maximumSpeed,

        emissionRate: options.emissionRate,

        bursts: options.bursts,

        emitter: options.emitter,

        // 粒子系统发射粒子的时间（以秒为单位）。
        lifetime: 16.0,
        // 4x4转换矩阵，用于转换粒子系统局部坐标系内的粒子系统发射器。
        emitterModelMatrix: computeEmitterModelMatrix()
      })
    )

    this.__entity = entity
    this.__particleSystem = particleSystem

    // 摄像机视角变换，位置相对应更新
    this.__listeners = (scene, time) => {
      if (particleSystem) {
        particleSystem.modelMatrix = computeModelMatrix(entity, time)

        // Account for any changes to the emitter model matrix.
        particleSystem.emitterModelMatrix = computeEmitterModelMatrix()
      }

      let distance = Cesium.Cartesian3.distance(scene.camera.position, this.__position)

      if (distance < 100) {
        particleSystem.startScale = options.startScale * 1
        particleSystem.endScale = options.endScale * 1
      } else {
        particleSystem.startScale = options.startScale * 100 / distance * 1
        particleSystem.endScale = options.endScale * 100 / distance * 1
      }
    }

    this.__viewer.scene.preUpdate.addEventListener(this.__listeners)
  }
}
