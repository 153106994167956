import { UPLOAD } from '@/utils/http'

/**
 * @desc 上传文件
 * @export uploadFile
 */
export function uploadFile(params, config) {
  let formData = new FormData()
  formData.append('file', params.file)
  formData.append('md5', params.md5)

  return UPLOAD(formData, config)
}
