<template>
  <div>
    <div :class="{ info_model: true, info_model_plan: inPlan }" v-if="visibleInfoModel">
      <ul class="fireduty-head-row">
        <div v-for="(item, index) in tabList" :key="index" :style="{ color: actName === item ? '#18CFFD' : '' }" @click="actName = item">{{ item }}</div>
      </ul>

      <div class="fireduty-main">
        <template v-if="actName === '消防队车辆'">
          <ul class="ul-car">
            <li>
              <span class="title">车辆类型</span>
              <div class="content">
                <span>车辆数</span>
              </div>
            </li>
            <li v-for="(item, index) in powerCarList" :key="index">
              <span class="title">{{ item.typeName }}</span>
              <div class="content">
                <span>{{ item.id.length }}</span>
              </div>
            </li>
            <li v-if="!powerCarList.length" class="no-data">暂无数据</li>
          </ul>
        </template>

        <template v-else>
          <div v-if="dutyHumanList.length && !dutyHumanList[0].length && !dutyHumanList[1].length">暂无数据</div>
          <template v-if="dutyHumanList.length && dutyHumanList[0].length">
            <h1>队长</h1>
            <ul class="ul-people">
              <li v-for="(item, index) in dutyHumanList[0]" :key="index">
                <el-avatar class="head-portrait" :size="30" :src="item.headImage || errorImg"></el-avatar>
                <div>
                  <span>{{ item.name }}</span>
                  <span>{{ item.contact }}</span>
                </div>
              </li>
              <li v-if="dutyHumanList[0].length % 2 == 1" class="isHidden"></li>
            </ul>
          </template>

          <template v-if="dutyHumanList.length && dutyHumanList[1].length">
            <h1>队员</h1>
            <ul class="ul-people">
              <li v-for="(item, index) in dutyHumanList[1]" :key="index">
                <el-avatar class="head-portrait" :size="30" :src="item.headImage || errorImg"></el-avatar>
                <div>
                  <span>{{ item.name }}</span>
                  <span>{{ item.contact }}</span>
                </div>
              </li>
              <li v-if="dutyHumanList[1].length % 2 == 1" class="isHidden"></li>
            </ul>
          </template>
        </template>
      </div>
    </div>
    <div v-if="visibleInfoModel" class="infor-model_mask" @click="onClose"></div>
  </div>
</template>

<script>
import { deepClone } from '@/utils/util'
import { getPowerCarList, getDutyHumanList } from '@/api/file-info'

const infoData = {
  id: null
}

const tabList = ['消防队车辆', '消防员']

export default {
  props: {
    inPlan: Boolean,
    info: {
      type: Object,
      default: () => deepClone(infoData)
    }
  },

  watch: {
    info: {
      handler(val = {}) {
        let userCompanyInfo = deepClone(val)
        this.$set(this, 'userCompanyInfo', userCompanyInfo)
        if (userCompanyInfo) {
          this.getPowerCarList()
          this.getDutyHumanList()
        }
      },
      deep: true
    }
  },

  data() {
    return {
      tabList,
      actName: '消防队车辆',
      visibleInfoModel: false,
      userCompanyInfo: deepClone(infoData),
      powerCarList: [],
      dutyHumanList: [],
      errorImg: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
    }
  },

  methods: {
    getPowerCarList() {
      getPowerCarList({
        unitId: this.userCompanyInfo.id
      }).then((res) => {
        if (!res) return
        let hash = {},
          i = 0,
          list = []
        res.forEach(function (item) {
          let typeId = item.typeId
          hash[typeId]
            ? list[hash[typeId] - 1].id.push(item.typeId)
            : hash[typeId] =
                ++i &&
                list.push({
                  id: [item.typeId],
                  typeId,
                  typeName: item.typeName
                })
        })
        this.powerCarList = list
      })
    },

    getDutyHumanList() {
      this.dutyHumanList = []
      getDutyHumanList({
        unitId: this.userCompanyInfo.id
      }).then((res) => {
        if (!res) return
        let captain = []
        let teamMember = []
        res.forEach((value) => {
          if (value.duty === '' || value.duty === '队员') {
            teamMember.push(value)
          } else {
            captain.push(value)
          }
        })
        this.dutyHumanList.push(captain, teamMember)
      })
    },

    open() {
      this.visibleInfoModel = true
      if (this.userCompanyInfo.id) {
        this.getPowerCarList()
        this.getDutyHumanList()
      }
    },

    onClose() {
      this.visibleInfoModel = false
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

$colorYellow: #d09f29;
$colorWhite: #ececec;
$bgColor: rgba(3, 9, 18, 0.9);
$colorBlue: #07eef5;

.infor-model_mask {
  @include size(100vw, 100vh);
  background-color: rgba($color: #000000, $alpha: 0.5);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 4;
}

.info_model {
  @include size(357.2px, 74%);
  background: rgba(1, 18, 38, 0.9);
  border-radius: 5px;
  transition: transform 0.6s;
  position: absolute;
  left: 50%;
  top: 125px;
  z-index: 5;
  transform: translateX(-50%);

  &_plan {
    left: 50%;
    top: 59px;
    transform: translateX(-50%);
  }

  .el-icon-close {
    font-size: 25px;
    color: #fff;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3;
  }

  @media screen and (max-width: 768px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.638);
    zoom: 1;
  }
}

.fireduty-head-row {
  display: flex;
  font-size: 16px;
  padding: 16px 22px 13px;
  color: #fff;
  border-bottom: 1px solid rgba(16, 119, 186, 0.7);
  > div {
    margin-right: 63px;
    cursor: pointer;
  }
}

.fireduty-main {
  padding: 19px 22px 0;
  color: #fff;
  overflow: auto;
  height: calc(100% - 80px);

  ul.ul-people {
    display: flex;
    flex-wrap: wrap;
    &:first-child {
      margin-bottom: 13px;
    }

    .isHidden {
      visibility: hidden;
    }
    li {
      flex: 1;
      @include flex-center;
      margin-right: 14px;
      margin-bottom: 16px;
      border: 1px solid #1077ba;
      padding: 8px;
      &:nth-child(2n) {
        margin-right: 0;
      }
      > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        span {
          font-size: 14px;
          width: 80px;
          &:first-child {
            @include text-ellipsis(2);
          }
        }
      }
      .head-portrait {
        @include size(40px);
        background: #ccc;
        margin-right: 5px;
        border-radius: 50%;
      }
    }
  }

  h1 {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 13px;
  }

  ul.ul-car {
    border: 1px solid #3d5166;
    border-bottom: none;
    color: #fff;
    font-size: 14px;
    > li {
      min-height: 49px;
      display: flex;
      border-bottom: 1px solid #3d5166;
      &:first-child {
        background: rgba(8, 40, 79, 0.71);
      }
    }

    > li .title {
      @include flex-center(flex-start);
      flex: 1;
      border-right: 1px solid #3d5166;
      padding-left: 18px;
    }

    > li .content {
      @include flex-center(center);
      width: 100px;
    }
    .no-data {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
