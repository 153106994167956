import xp from './algorithm'
import GlobeTooltip from './GlobeTooltip'

import CreateToolbar from './CreateToolbar'
var PlotStraightArrowDrawer = function () {
  this.init.apply(this, arguments);
};

PlotStraightArrowDrawer.prototype = {
  viewer: null,
  scene: null,
  clock: null,
  canvas: null,
  camera: null,
  ellipsoid: null,
  tooltip: null,
  entity: null,
  positions: [],
  tempPositions: [],
  drawHandler: null,
  modifyHandler: null,
  okHandler: null,
  cancelHandler: null,
  dragIcon: require('@/assets/images/model/point/circle_gray.png'),
  dragIconLight: require('@/assets/images/model/point/circle_red.png'),
  material: null,
  outlineMaterial: null,
  fill: true,
  outline: true,
  outlineWidth: 2,
  extrudedHeight: 0,
  toolBarIndex: null,
  markers: {},
  layerId: "globeDrawerLayer",
  init: function (viewer) {
    var _this = this;
    _this.viewer = viewer;
    _this.scene = viewer.scene;
    _this.clock = viewer.clock;
    _this.canvas = viewer.scene.canvas;
    _this.camera = viewer.scene.camera;
    _this.ellipsoid = viewer.scene.globe.ellipsoid;
    _this.tooltip = new GlobeTooltip(viewer.container);
  },
  clear: function () {
    var _this = this;
    if (_this.drawHandler) {
      _this.drawHandler.destroy();
      _this.drawHandler = null;
    }
    if (_this.modifyHandler) {
      _this.modifyHandler.destroy();
      _this.modifyHandler = null;
    }
    if (_this.toolBarIndex != null) {
      layer.close(_this.toolBarIndex);
    }
    _this._clearMarkers(_this.layerId);
    _this.tooltip.setVisible(false);
  },
  showModifyStraightArrow: function (positions, okHandler, cancelHandler) {
    var _this = this;
    var arr = [];
    arr.push(positions[7]);
    arr.push(positions[3]);
    _this.positions = arr;
    _this.okHandler = okHandler;
    _this.cancelHandler = cancelHandler;
    _this._showModifyRegion2Map();
  },

  _getCartesian: function (event) {
    const _this = this
    const position = event.endPosition || event.position
    const target = _this.viewer.scene.pick(position)

    if (target) {
      return _this.scene.pickPosition(position)
    }

    const ray = _this.camera.getPickRay(position)

    return _this.scene.globe.pick(ray, _this.scene)
  },

  startDrawStraightArrow: function (okHandler, cancelHandler) {
    var _this = this;
    _this.okHandler = okHandler;
    _this.cancelHandler = cancelHandler;

    _this.positions = [];
    var floatingPoint = null;
    _this.drawHandler = new Cesium.ScreenSpaceEventHandler(_this.canvas);

    _this.drawHandler.setInputAction(function (event) {
      var position = event.position;
      if (!Cesium.defined(position)) {
        return;
      }
      var cartesian = _this._getCartesian(event)
      var num = _this.positions.length;
      if (num == 0) {
        _this.positions.push(cartesian);
        floatingPoint = _this._createPoint(cartesian, -1);
        _this._showRegion2Map();
      }
      _this.positions.push(cartesian);
      var oid = _this.positions.length - 2;
      _this._createPoint(cartesian, oid);
      if (num > 1) {
        _this.positions.pop();
        _this.viewer.entities.remove(floatingPoint);
        _this.tooltip.setVisible(false);
        _this._startModify();
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

    _this.drawHandler.setInputAction(function (event) {
      var position = event.endPosition;
      if (!Cesium.defined(position)) {
        return;
      }
      if (_this.positions.length < 1) {
        _this.tooltip.showAt(position, "<p>选择起点</p>");
        return;
      }
      _this.tooltip.showAt(position, "<p>选择终点</p>");

      var cartesian = _this._getCartesian(event)
      floatingPoint.position.setValue(cartesian);
      _this.positions.pop();
      _this.positions.push(cartesian);
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  },
  _startModify: function () {
    var _this = this;
    var isMoving = false;
    var pickedAnchor = null;
    if (_this.drawHandler) {
      _this.drawHandler.destroy();
      _this.drawHandler = null;
    }
    _this._showToolBar();

    _this.modifyHandler = new Cesium.ScreenSpaceEventHandler(_this.canvas);

    _this.modifyHandler.setInputAction(function (event) {
      var position = event.position;
      if (!Cesium.defined(position)) {
        return;
      }
      var cartesian = _this._getCartesian(event)
      if (isMoving) {
        isMoving = false;
        pickedAnchor.position.setValue(cartesian);
        var oid = pickedAnchor.oid;
        _this.positions[oid] = cartesian;
        _this.tooltip.setVisible(false);
      } else {
        var pickedObject = _this.scene.pick(position);
        if (!Cesium.defined(pickedObject)) {
          return;
        }
        if (!Cesium.defined(pickedObject.id)) {
          return;
        }
        var entity = pickedObject.id;
        if (entity.layerId != _this.layerId || entity.flag != "anchor") {
          return;
        }
        pickedAnchor = entity;
        isMoving = true;
        _this.tooltip.showAt(position, "<p>移动控制点</p>");
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

    _this.modifyHandler.setInputAction(function (event) {
      if (!isMoving) {
        return;
      }
      var position = event.endPosition;
      if (!Cesium.defined(position)) {
        return;
      }
      _this.tooltip.showAt(position, "<p>移动控制点</p>");

      var cartesian = _this._getCartesian(event)
      pickedAnchor.position.setValue(cartesian);
      var oid = pickedAnchor.oid;
      _this.positions[oid] = cartesian;
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  },
  _showRegion2Map: function () {
    var _this = this;
    if (_this.material == null) {
      _this.material = Cesium.Color.fromCssColorString('#ff0').withAlpha(0.5);
    }
    if (_this.outlineMaterial == null) {
      _this.outlineMaterial = new Cesium.PolylineDashMaterialProperty({
        dashLength: 16,
        color: Cesium.Color.fromCssColorString('#f00').withAlpha(0.7)
      });
    }

    var dynamicHierarchy = new Cesium.CallbackProperty(function () {
      if (_this.positions.length > 1) {
        var p1 = _this.positions[0];
        var p2 = _this.positions[1];
        if (_this._isSimpleXYZ(p1, p2)) {
          return null;
        }
        var firstPoint = _this._getLonLat(p1);
        var endPoints = _this._getLonLat(p2);
        var arrow = xp.algorithm.fineArrow([firstPoint.lon, firstPoint.lat], [endPoints.lon, endPoints.lat]);
        var pHierarchy = new Cesium.PolygonHierarchy(arrow);
        return pHierarchy;
      } else {
        return null;
      }
    }, false);
    var outlineDynamicPositions = new Cesium.CallbackProperty(function () {
      if (_this.positions.length < 2) {
        return null;
      }
      var p1 = _this.positions[0];
      var p2 = _this.positions[1];
      if (_this._isSimpleXYZ(p1, p2)) {
        return null;
      }
      var firstPoint = _this._getLonLat(p1);
      var endPoints = _this._getLonLat(p2);
      var arrow = xp.algorithm.fineArrow([firstPoint.lon, firstPoint.lat], [endPoints.lon, endPoints.lat]);
      arrow.push(arrow[0]);
      return arrow;
    }, false);
    var bData = {
      polygon: new Cesium.PolygonGraphics({
        hierarchy: dynamicHierarchy,
        material: _this.material,
        show: _this.fill
      }),
      polyline: {
        positions: outlineDynamicPositions,
        clampToGround: true,
        width: _this.outlineWidth,
        material: _this.outlineMaterial,
        show: _this.outline
      }
    };
    if (_this.extrudedHeight > 0) {
      bData.polygon.extrudedHeight = _this.extrudedHeight;
      bData.polygon.extrudedHeightReference = Cesium.HeightReference.RELATIVE_TO_GROUND;
      bData.polygon.closeTop = true;
      bData.polygon.closeBottom = true;
    }
    _this.entity = _this.viewer.entities.add(bData);
    _this.entity.layerId = _this.layerId;
  },
  _showModifyRegion2Map: function () {
    var _this = this;

    _this._startModify();
    _this._computeTempPositions();

    var dynamicHierarchy = new Cesium.CallbackProperty(function () {
      if (_this.positions.length > 1) {
        var p1 = _this.positions[0];
        var p2 = _this.positions[1];
        if (_this._isSimpleXYZ(p1, p2)) {
          return null;
        }
        var firstPoint = _this._getLonLat(p1);
        var endPoints = _this._getLonLat(p2);
        var arrow = xp.algorithm.fineArrow([firstPoint.lon, firstPoint.lat], [endPoints.lon, endPoints.lat]);
        var pHierarchy = new Cesium.PolygonHierarchy(arrow);
        return pHierarchy;
      } else {
        return null;
      }
    }, false);
    var outlineDynamicPositions = new Cesium.CallbackProperty(function () {
      if (_this.positions.length < 2) {
        return null;
      }
      var p1 = _this.positions[0];
      var p2 = _this.positions[1];
      if (_this._isSimpleXYZ(p1, p2)) {
        return null;
      }
      var firstPoint = _this._getLonLat(p1);
      var endPoints = _this._getLonLat(p2);
      var arrow = xp.algorithm.fineArrow([firstPoint.lon, firstPoint.lat], [endPoints.lon, endPoints.lat]);
      arrow.push(arrow[0]);
      return arrow;
    }, false);

    if (_this.material == null) {
      _this.material = Cesium.Color.fromCssColorString('#ff0').withAlpha(0.5);
    }
    if (_this.outlineMaterial == null) {
      _this.outlineMaterial = new Cesium.PolylineDashMaterialProperty({
        dashLength: 16,
        color: Cesium.Color.fromCssColorString('#f00').withAlpha(0.7)
      });
    }
    var bData = {
      polygon: new Cesium.PolygonGraphics({
        hierarchy: dynamicHierarchy,
        material: _this.material,
        show: _this.fill
      }),
      polyline: {
        positions: outlineDynamicPositions,
        clampToGround: true,
        width: _this.outlineWidth,
        material: _this.outlineMaterial,
        show: _this.outline
      }
    };
    if (_this.extrudedHeight > 0) {
      bData.polygon.extrudedHeight = _this.extrudedHeight;
      bData.polygon.extrudedHeightReference = Cesium.HeightReference.RELATIVE_TO_GROUND;
      bData.polygon.closeTop = true;
      bData.polygon.closeBottom = true;
    }
    _this.entity = _this.viewer.entities.add(bData);
    _this.entity.layerId = _this.layerId;
    var positions = _this.positions;
    for (var i = 0; i < positions.length; i++) {
      _this._createPoint(positions[i], i);
    }
  },
  _createPoint: function (cartesian, oid) {
    var _this = this;
    var point = _this.viewer.entities.add({
      position: cartesian,
      billboard: {
        image: _this.dragIconLight,
        // eyeOffset: new Cesium.ConstantProperty(new Cesium.Cartesian3(0, 0, -500)),
        // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
      }
    });
    point.oid = oid;
    point.layerId = _this.layerId;
    point.flag = "anchor";
    _this.markers[oid] = point;
    return point;
  },
  _computeTempPositions: function () {
    var _this = this;

    var pnts = [].concat(_this.positions);
    var num = pnts.length;
    var first = pnts[0];
    var last = pnts[num - 1];
    if (_this._isSimpleXYZ(first, last) == false) {
      pnts.push(first);
      num += 1;
    }
    _this.tempPositions = [];
    for (var i = 1; i < num; i++) {
      var p1 = pnts[i - 1];
      var p2 = pnts[i];
      var cp = _this._computeCenterPotition(p1, p2);
      _this.tempPositions.push(p1);
      _this.tempPositions.push(cp);
    }
  },
  _computeCenterPotition: function (p1, p2) {
    var _this = this;
    var c1 = _this.ellipsoid.cartesianToCartographic(p1);
    var c2 = _this.ellipsoid.cartesianToCartographic(p2);
    var cm = new Cesium.EllipsoidGeodesic(c1, c2).interpolateUsingFraction(0.5);
    var cp = _this.ellipsoid.cartographicToCartesian(cm);
    return cp;
  },
  _showToolBar: function () {
    var _this = this;
    _this._createToolBar();
    var width = $(window).width();
    var wTop = 60;
    var wLeft = parseInt((width - 145) / 2);
    _this.toolBarIndex = layer.open({
      title: false,
      type: 1,
      fixed: false,
      resize: false,
      shade: 0,
      content: $("#shapeEditContainer"),
      offset: [wTop + "px", wLeft + "px"],
      move: "#shapeEditRTCorner"
    });
    var cssSel = "#layui-layer" + _this.toolBarIndex + " .layui-layer-close2";
    $(cssSel).hide();
  },
  _createToolBar: function () {

    var _this = this;

    CreateToolbar(() => {
      _this.clear();
      layer.close(_this.toolBarIndex);
      if (_this.okHandler) {
        var p1 = _this.positions[0];
        var p2 = _this.positions[1];
        var firstPoint = _this._getLonLat(p1);
        var endPoints = _this._getLonLat(p2);

        const textareaContent = $('#shapeEditContainer textarea.layui-textarea').val() || ''

        var arrow = xp.algorithm.fineArrow([firstPoint.lon, firstPoint.lat], [endPoints.lon, endPoints.lat]);

        _this.okHandler(arrow, textareaContent);
      }
    }, () => {
      _this.clear();
      layer.close(_this.toolBarIndex);
      if (_this.cancelHandler) {
        _this.cancelHandler();
      }
    })

    // var _this = this;
    // var objs = $("#shapeEditContainer");
    // objs.remove();
    // var html = `<div id="shapeEditContainer" style="padding: 10px 10px;">
    //     <div class="layui-form-item">
    //       <label class="layui-form-label">描述</label>
    //       <div class="layui-input-block">
    //         <textarea name="desc" placeholder="描述内容" class="layui-textarea"></textarea>
    //       </div>
    //     </div>
    //     <div>
    //       <button name="btnCancel" class="layui-btn layui-btn-xs layui-btn-danger"> <i class="layui-icon">ဆ</i> 取消 </button>
    //       <button name="btnOK" class="layui-btn layui-btn-xs layui-btn-normal"> <i class="layui-icon"></i> 确定 </button>
    //     </div>
    //     <div id="shapeEditRTCorner" style="width: 16px; position: absolute; right: 0px; top: 0px; bottom: 0px">
    //   </div>
    // </div>`
    // $("#cesiumContainerBox").append(html);

    // var btnOK = $("#shapeEditContainer button[name='btnOK']");
    // var btnCancel = $("#shapeEditContainer button[name='btnCancel']");
    // btnOK.unbind("click").bind("click", function () {
    //   _this.clear();
    //   layer.close(_this.toolBarIndex);
    //   if (_this.okHandler) {
    //     var p1 = _this.positions[0];
    //     var p2 = _this.positions[1];
    //     var firstPoint = _this._getLonLat(p1);
    //     var endPoints = _this._getLonLat(p2);

    //     const textareaContent = $('#shapeEditContainer textarea.layui-textarea').val() || ''

    //     var arrow = xp.algorithm.fineArrow([firstPoint.lon, firstPoint.lat], [endPoints.lon, endPoints.lat]);

    //     _this.okHandler(arrow, textareaContent);
    //   }
    // });
    // btnCancel.unbind("click").bind("click", function () {
    //   _this.clear();
    //   layer.close(_this.toolBarIndex);
    //   if (_this.cancelHandler) {
    //     _this.cancelHandler();
    //   }
    // });
  },
  _getLonLat: function (cartesian) {
    var _this = this;
    var cartographic = _this.ellipsoid.cartesianToCartographic(cartesian);
    cartographic.height = _this.viewer.scene.globe.getHeight(cartographic);
    var pos = {
      lon: cartographic.longitude,
      lat: cartographic.latitude,
      alt: cartographic.height
    };
    pos.lon = Cesium.Math.toDegrees(pos.lon);
    pos.lat = Cesium.Math.toDegrees(pos.lat);
    return pos;
  },
  _isSimpleXYZ: function (p1, p2) {
    if (p1.x == p2.x && p1.y == p2.y && p1.z == p2.z) {
      return true;
    }
    return false;
  },
  _clearMarkers: function (layerName) {
    var _this = this;
    var viewer = _this.viewer;
    var entityList = viewer.entities.values;
    if (entityList == null || entityList.length < 1)
      return;
    for (var i = 0; i < entityList.length; i++) {
      var entity = entityList[i];
      if (entity.layerId == layerName) {
        viewer.entities.remove(entity);
        i--;
      }
    }
  },
  _clearAnchors: function () {
    var _this = this;
    for (var key in _this.markers) {
      var m = _this.markers[key];
      _this.viewer.entities.remove(m);
    }
    _this.markers = {};
  },
  CLASS_NAME: "PlotStraightArrowDrawer"
};

export default PlotStraightArrowDrawer;