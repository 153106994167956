<template>
  <div class="menu-container">
    <div class="change-room" v-if="!isPetrifyFirm">
      <span v-if="scene !== 'inside'" :class="{ 'change-room-item': isInternal === 1 }" @click="menuToggle(1)">室外</span>
      <span v-if="scene !== 'outside'" :class="{ 'change-room-item': isInternal === 0 }" @click="menuToggle(0)">室内</span>
    </div>

    <div class="menu-list">
      <ul v-for="(navs, index) in navLists" :key="index">
        <template v-for="(item, _index) in navs">
          <li
            v-if="!item.hide && item.sceneBak !== isInternal"
            :key="_index"
            :class="{ active: selected.includes(`${isInternal}-${index}-${_index}`) }"
            @click="tabSubMenu(isInternal, index, _index, item)"
          >
            <span class="name">{{ item.name }}</span>
            <i class="el-icon-s-unfold" v-if="item.onList" @click.stop="opendRightList(item)" />
            <ol class="other-list" v-if="item.childList && item.show">
              <li v-for="(it, i) in item.childList" :key="i" @click.stop="openOther(isInternal, index, _index, i)" :class="{ active: selected.includes(`${isInternal}-${index}-${_index}-${i}`) }">
                {{ it.name }}
                <i class="el-icon-s-unfold" v-if="it.onList" @click.stop="opendRightList(it)" />
              </li>
              <div class="arrow"></div>
            </ol>
          </li>
        </template>
      </ul>
    </div>

    <div class="mobile-change-room">{{ inside ? '室内' : '室外' }}</div>
    <div class="switch-3-d-icon">
      <i class="iconfont ZK-YA-V2-miaozhun" @click="flyModelCenter"></i>
      <i
        :class="{
          iconfont: true,
          'ZK-YA-V2-open-eye': openClose,
          'ZK-YA-V2-close-eye': !openClose
        }"
        @click="toggleLayerImagery"
      ></i>
    </div>
  </div>
</template>

<script>
import { PLANDRAWTYPE, SCEN_ARIO } from '@/utils/conf'

export default {
  props: {
    inside: Boolean,
    buildType: String,
    scene: String
  },

  data() {
    return {
      selected: [],
      isInternal: 1,

      openClose: true,
      isPetrifyFirm: false
    }
  },

  computed: {
    navLists() {
      return this.getNavLists(this.buildType)
    }
  },

  watch: {
    inside: {
      immediate: true,
      handler(val) {
        this.menuToggle(+!val)
      }
    },
    specifyScene: {
      immediate: true,
      handler(val) {
        if (!val) return
        this.menuToggle(Number(val === 'outside'))
      }
    }
  },

  methods: {
    menuToggle(type) {
      if (+this.isInternal === +type) return

      this.isInternal = type
      this.$EventBus.$emit('menu-toggle', type)
    },

    async opendRightList(info) {
      let { childId, drawType, lists } = info

      let tableList = []

      try {
        if (lists) {
          for (let i in lists) {
            let data = await this.getUnitDrawFnBak({
              drawType: lists[i].drawType,
              drawToolId: lists[i].childId
            })
            tableList.push(data)
          }
        } else {
          tableList = await this.getUnitDrawFnBak({
            drawType,
            drawToolId: childId
          })
        }
      } catch (error) {
        console.log(error)
      }
      // 楼层分区列表排序

      if (info.key === 'floorPartition') {
        tableList.sort((a, b) => JSON.parse(b.config).minimumHeights[0] - JSON.parse(a.config).minimumHeights[0])
      }
      this.$parent.$refs.examineTable.getTableList(info.name, tableList.flat())
    },

    getUnitDrawFnBak({ drawType, drawToolId }) {
      const params = {
        drawType,
        scene: SCEN_ARIO[this.isInternal ? 'EXTERNAL' : 'INTERNAL'],
        drawToolId
      }

      if (!this.isInternal) {
        params.buildId = this.$store.state.planDrill.moduleBuildId || null
      }
      return this.$parent.BasicOverview.getUnitDrawListFn(params, [drawToolId])
    },

    openOther(isInternal, index, _index, i) {
      const key = `${isInternal}-${index}-${_index}-${i}`
      const data = this.navLists[index][_index].childList[i]
      if (this.selected.includes(key)) {
        this.selected = this.selected.filter(item => item !== key)
        this.$emit('unactivated', data)
      } else {
        this.selected.push(key)
        this.$emit('active', data)
      }
    },

    tabSubMenu(isInternal, index, _index, itemInfo) {
      // 判断是否有子集,控制如---其他的显隐状态
      if (itemInfo.childList) {
        if (this.selected.some(item => item.split('').filter(it => it.includes('-') && it).length > 2)) return
        itemInfo.show = !itemInfo.show
      }
      const key = `${isInternal}-${index}-${_index}`
      const data = this.navLists[index][_index]
      //自动喷淋系统,横琴
      if (itemInfo.key === 'automaticSpray') {
        this.$parent.$refs.carouelBox.openCarouselBox()
        return
      }
      // //首页消防组织显示/隐藏
      // if (itemInfo.otherType === 'rightOrganization') {
      //   this.$EventBus.$emit('change.organization.state')
      // }

      if (this.selected.includes(key)) {
        this.selected = this.selected.filter(item => item !== key)
        this.$emit('unactivated', data)
      } else {
        this.selected.push(key)
        this.$emit('active', data)
      }
    },

    getNavLists(buildType) {
      const isHightBuild = buildType === '高层建筑' || buildType === '大型综合体'
      const isPetrifyFirm = buildType === '石化厂' || buildType === '石化企业'
      this.isPetrifyFirm = isPetrifyFirm
      const isAutomaticSpray = JSON.parse(localStorage.getItem('user.info'))?.unitId === 15
      return [
        [
          { name: '单位信息', key: 'info', sceneBak: 0 },
          {
            name: '义务消防队',
            key: 'duty',
            sceneBak: 0,
            hide: !isPetrifyFirm
          },
          {
            name: '建筑体',
            key: 'buildingSelf',
            drawType: PLANDRAWTYPE.BUILD,
            childId: 67,
            hide: !isPetrifyFirm,
            sceneBak: 0,
            onList: true
          },
          {
            name: '罐体',
            key: 'ometer',
            drawType: PLANDRAWTYPE.BUILD,
            childId: 56,
            hide: !isPetrifyFirm,
            sceneBak: 0,
            onList: true
          },
          // 仅限高层类型
          {
            name: '楼层分区',
            key: 'floorPartition',
            drawType: PLANDRAWTYPE.BUILDING_PARTITION,
            childId: 92,
            hide: !isHightBuild,
            sceneBak: 0,
            onList: true
          },
          {
            name: '救生窗',
            key: 'helpSaving',
            drawType: PLANDRAWTYPE.BUILDING_PARTITION,
            childId: 95,
            hide: !isHightBuild,
            sceneBak: 0
          },
          // 本质上是显示内景的功能分区
          {
            name: '防火分区',
            key: 'fireProof',
            drawType: PLANDRAWTYPE.BUILDING_PARTITION,
            childId: 100,
            scene: SCEN_ARIO.INTERNAL,
            sceneBak: 1
          },
          // 仅限石化类型
          {
            name: '功能分区',
            key: 'functionalPartition',
            drawType: PLANDRAWTYPE.BUILDING_PARTITION,
            childId: 51,
            all: true,
            hide: !isPetrifyFirm,
            sceneBak: 0,
            onList: true
          },
          {
            name: '周边建筑',
            key: 'surroundBuild',
            drawType: PLANDRAWTYPE.SURROUND_BUILD,
            childId: 54,
            sceneBak: 0
          },
          {
            name: '周边道路',
            key: 'surroundRoad',
            drawType: PLANDRAWTYPE.SURROUND_BUILD,
            childId: 52,
            sceneBak: 0
          },
          {
            name: '重点部位',
            key: 'keyParts',
            drawType: PLANDRAWTYPE.KEY_PARTS,
            all: true,
            sceneBak: isPetrifyFirm ? 0 : 1,
            onList: true,
            childId: 55
          },
          // 仅限石化类型
          {
            name: '工艺流程',
            key: 'keyTechnological',
            drawType: PLANDRAWTYPE.KEY_PARTS,
            all: false,
            hide: !isPetrifyFirm,
            sceneBak: 0,
            onList: true,
            childId: 103
          },
          {
            name: '登高面',
            key: 'highSurface',
            drawType: PLANDRAWTYPE.FIR_ECONTROLFACILITIES,
            childId: 94,
            sceneBak: 0,
            hide: isPetrifyFirm
          },
          {
            name: '安全出口',
            key: 'entranceInset',
            drawType: PLANDRAWTYPE.FIR_ECONTROLFACILITIES,
            childId: 84,
            sceneBak: 1,
            scene: SCEN_ARIO.INTERNAL,
            onList: true
          },
          {
            name: '出入口',
            key: 'entrance',
            onList: true,
            sceneBak: 0,
            lists: [
              {
                key: 'entrance1',
                drawType: PLANDRAWTYPE.SURROUND_BUILD,
                childId: 53
              },
              {
                key: 'entrance2',
                drawType: PLANDRAWTYPE.FIR_ECONTROLFACILITIES,
                childId: 97
              }
            ]
          },
          {
            name: '疏散路线',
            key: 'evacuationRoute',
            drawType: PLANDRAWTYPE.FIR_ECONTROLFACILITIES,
            hide: !isPetrifyFirm,
            childId: 89,
            sceneBak: 0
          },
          {
            name: '组织结构',
            sceneBak: 0,
            key: 'organization',
            otherType: 'rightOrganization'
          },
          {
            name: '楼梯信息',
            key: 'stairsInfo',
            drawType: PLANDRAWTYPE.FIR_ECONTROLFACILITIES,
            scene: SCEN_ARIO.INTERNAL,
            childId: 88,
            sceneBak: 1,
            onList: true
          },
          {
            name: '消防电梯',
            key: 'fireElevator',
            drawType: PLANDRAWTYPE.FIR_ECONTROLFACILITIES,
            scene: SCEN_ARIO.INTERNAL,
            childId: 102,
            sceneBak: 1,
            onList: true
          },
          {
            name: '内部通道',
            key: 'internalChannel',
            drawType: PLANDRAWTYPE.FIR_ECONTROLFACILITIES,
            scene: SCEN_ARIO.INTERNAL,
            childId: 96,
            sceneBak: 1
          },
          {
            name: '疏散路线',
            key: 'evacuationRoute',
            drawType: PLANDRAWTYPE.FIR_ECONTROLFACILITIES,
            scene: SCEN_ARIO.INTERNAL,
            childId: 89,
            sceneBak: 1
          }
        ],
        [
          {
            name: '室外消火栓',
            key: 'outdoorFireHydrant',
            drawType: PLANDRAWTYPE.FIR_ECONTROLFACILITIES,
            childId: 72,
            sceneBak: 0,
            onList: true
          },
          {
            name: '室内消火栓',
            key: 'indoorFireHydrant',
            drawType: PLANDRAWTYPE.FIR_ECONTROLFACILITIES,
            scene: SCEN_ARIO.INTERNAL,
            childId: 71,
            sceneBak: 1
          },
          {
            name: '水泵接合器',
            key: 'waterPump',
            drawType: PLANDRAWTYPE.FIR_ECONTROLFACILITIES,
            childId: 81,
            sceneBak: 0,
            onList: true
          },
          {
            name: '消防水泵房',
            key: 'fireWaterPumpHouse',
            drawType: PLANDRAWTYPE.FIR_ECONTROLFACILITIES,
            scene: isPetrifyFirm ? undefined : SCEN_ARIO.INTERNAL,
            childId: 86,
            sceneBak: isPetrifyFirm ? 0 : 1,
            onList: true
          },
          {
            name: '消防水池',
            key: 'firePool',
            drawType: PLANDRAWTYPE.FIR_ECONTROLFACILITIES,
            scene: isPetrifyFirm ? undefined : SCEN_ARIO.INTERNAL,
            childId: 69,
            sceneBak: isPetrifyFirm ? 0 : 1,
            onList: true
          },
          // { name: '自动喷淋系统', key: 'automaticSpray', drawType: PLANDRAWTYPE.FIR_ECONTROLFACILITIES, scene: SCEN_ARIO.INTERNAL, childId: 76  },
          {
            name: '自动喷淋系统',
            key: 'automaticSpray',
            hide: !isAutomaticSpray,
            sceneBak: 1
          },
          {
            name: '其它',
            show: false,
            all: true,
            sceneBak: 0,
            hide: !isPetrifyFirm,
            childList: [
              {
                name: '消火栓泵',
                key: 'inhabit',
                drawType: PLANDRAWTYPE.FIR_ECONTROLFACILITIES,
                childId: 80,
                sceneBak: 0,
                onList: true
              },
              {
                name: '消防水罐',
                key: 'waterpot',
                drawType: PLANDRAWTYPE.FIR_ECONTROLFACILITIES,
                childId: 85,
                sceneBak: 0,
                onList: true
              },
              {
                name: '自动喷淋',
                key: 'spray',
                drawType: PLANDRAWTYPE.FIR_ECONTROLFACILITIES,
                childId: 82,
                sceneBak: 0
              },
              {
                name: '泡沫泵房',
                key: 'frothroom',
                drawType: PLANDRAWTYPE.FIR_ECONTROLFACILITIES,
                childId: 87,
                sceneBak: 0,
                onList: true
              }
            ]
          }
        ]
      ]
    },

    flyModelCenter() {
      this.$EventBus.$emit('fly.model.center')
    },

    toggleLayerImagery() {
      this.openClose = !this.openClose
      this.$EventBus.$emit('change.eyes.state')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.menu-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) scale(0.9);
  transform-origin: bottom;
  zoom: 1;
  z-index: 3;
  user-select: none;
  @include flex-center(center);
  background-color: rgba(1, 18, 38, 0.8);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 10px 15px;

  .change-room {
    border-radius: 10px;
    margin-right: 22px;
    display: flex;
    flex-direction: column;
    position: relative;

    .change-room-item {
      color: #18cffd;
      white-space: nowrap;
      font-weight: bold;
      font-size: 15px;
    }

    span {
      color: #8be6fc;
      font-size: 12px;
      cursor: pointer;
      &:first-child {
        border-bottom: 1px solid #18cffd;
        padding-bottom: 4px;
        margin-bottom: 5px;
      }
    }
    &::after {
      content: '';
      width: 1px;
      height: 100%;
      background-color: #526075;
      position: absolute;
      top: 0;
      right: -10px;
    }
  }

  .menu-list {
    @include flex-center(center);
  }

  ul {
    @include flex-center(center);
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
    li {
      @include size(90px, 40px);
      @include flex-center(center);
      background: url('~@/assets/images/bottom-menu/menu-background.png') no-repeat;
      background-size: 100% 100%;
      transition: background 0.3s ease;
      line-height: 40px;
      margin-right: 6px;
      cursor: pointer;
      position: relative;
      .el-icon-s-unfold {
        color: #fff;
        position: absolute;
        top: 6px;
        right: 6px;
        font-size: 12px;
        transform: scale(0.8);
        color: rgb(2, 183, 255);
        opacity: 0.5;
        &:hover {
          opacity: 1;
        }
      }
      .other-list {
        position: absolute;
        bottom: 48px;
        right: -10px;
        display: flex;
        background-color: rgba(3, 9, 18, 0.9);
        padding: 8px 0 8px 15px;
        border-radius: 10px;
        color: #fff;
        > li {
          line-height: 1.2;
          font-size: 12px;
          text-align: center;
          box-sizing: border-box;
          padding: 5px;
          transform: scale(0.95);
        }
        .arrow {
          position: absolute;
          bottom: -5px;
          right: 30px;
          border-left: 5px solid transparent;
          border-top: 5px solid rgba(3, 9, 18, 0.9);
          border-right: 5px solid transparent;
        }
      }
      &:last-of-type {
        margin-right: 0;
      }

      .name {
        color: #fff;
        font-size: 14px;
        text-align: center;
        display: inline-block;
        white-space: normal;
      }

      &:hover,
      &.active {
        background-image: url('~@/assets/images/bottom-menu/menu-background_active.png');
      }
    }
  }

  .mobile-change-room {
    @include size(50px);
    color: #fff;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    border: 1px solid #2881ca;
    background-color: rgba(2, 15, 33, 0.94);
    position: absolute;
    left: 7px;
    bottom: 10px;
    z-index: 3;
    display: none;
  }

  .switch-3-d-icon {
    color: rgba(48, 215, 247, 1);
    margin-left: 12px;
    @include flex-center(space-between, column);
    i {
      font-size: 22px;
      cursor: pointer;
    }
  }

  // @media screen and (max-width: 768px) {
  //   left: 10px;
  //   bottom: 50%;
  //   height: calc(100% - 60px);
  //   transform: translateY(54%);
  //   flex-direction: column;

  //   .change-room {
  //     display: none;
  //   }

  //   .mobile-change-room {
  //     display: block;
  //   }

  //   .menu-list {
  //     flex-direction: column;
  //     max-height: 60%;
  //     overflow-y: auto;
  //     justify-content: flex-start;
  //   }

  //   ul {
  //     padding: 0;
  //     margin-right: 0;
  //     background: none;
  //     flex-direction: column;
  //     li {
  //       margin-right: 0;
  //       margin-bottom: 10px;
  //       i {
  //         display: none;
  //       }
  //     }
  //   }
  // }
}
</style>
