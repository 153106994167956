import Store from '@/store'
import HtmlHostMsg from 'HtmlHostMsg'
import modelApi from '@/api/model'

import { SCEN_ARIO } from '@/utils/conf'

export default class BaseData {
  constructor(scope, { unitId }) {
    this.scope = scope
    this._unitId = unitId

    // 外景的数据
    this._outsideBaseData = {}
  }

  resetStatus() {
    this._drawType = null
    this._childId = null
    this._childIds = null
  }

  async handlerInside({ drawType, childIds, childId, key }) {
    if (!drawType) return

    this.resetInsideData(key)

    this._drawType = drawType
    this._childIds = childIds
    if (!childIds && childId !== void 0) {
      this._childIds = [childId]
    }

    const data = await this.getUnitDrawFn({ isInside: true }) || []

    const renderData = this._formatInteriorData(data, key)

    HtmlHostMsg.event('tool', renderData)

    return renderData
  }

  resetInsideData(key) {
    if (!key) return

    this.resetStatus()

    HtmlHostMsg.event('tool', {
      key,
      clear: true,
      draw: true
    })
  }

  async handlerOutside({ drawType, childIds, childId, reset = false, key }) {
    if (!drawType) return

    this.resetOutsideDataByKey(key)

    this._drawType = drawType
    this._childIds = childIds
    if (!childIds && childId !== void 0) {
      this._childIds = [childId]
    }

    const data = await this.getUnitDrawFn()

    let lists = this._formatExternalData(data)

    this.scope.handlerDrawData({
      data: lists,
      reset,
      callback: (data) => {
        if (!this._outsideBaseData[key]) {
          this._outsideBaseData[key] = []
        }
        this._outsideBaseData[key].push(data)
      }
    })

    return lists
  }

  resetOutsideData() {
    this.resetStatus()
    this.scope.resetCesiumModel(true)
  }

  resetOutsideDataByKey(key) {
    if (!key) return
    const data = this._outsideBaseData[key]

    if (data && data.length > 0) {
      data.forEach((item) => {
        item.primitive && item.primitive.remove()
        item.overlay && item.overlay.destroy()

        // 如果又标注信息
        if (item.measureData) {
          const measureData = item.measureData
          for (const key in measureData) {
            if (Object.hasOwnProperty.call(measureData, key)) {
              measureData[key].remove()
            }
          }
        }
      })

      this._outsideBaseData[key] = []
    }
  }

  // 格式化内景数据
  _formatInteriorData(data, key) {
    data = data.map(item => this._formatInteriorDataItem(item, key))

    // 如果需要筛选子级id
    const childIds = this._childIds
    if (childIds) {
      return data.filter(item => childIds.includes(+item.drawToolId))
    }
    return data
  }

  _formatInteriorDataItem(item, key) {
    let { config, drawTool } = item

    config = config || drawTool.config

    if (config && typeof config === 'string') {
      config = JSON.parse(config)
    }

    if (item.config?.uiText) item.uiText = item.name

    key = key || item.drawTool?.name || ''

    return {
      ...item,
      config,
      isViews: true,
      key
    }
  }

  // 格式化外部模型数据
  _formatExternalData(data) {
    data = data.filter((item) => {
      if (item.drawTool) {
        if (!item.config) item.config = JSON.stringify({})
        item.isViews = true
        return true
      }
      return false
    })

    // 如果需要筛选子级id
    const childIds = this._childIds
    if (childIds) {
      return data.filter(item => childIds.includes(+item.drawToolId))
    }
    return data
  }

  // 内外都有的情况
  handlerAllDrawData({ drawType, childIds, childId, key, reset }) {
    if (!drawType) return

    this.resetInsideData(key)
    this.resetOutsideDataByKey(key)

    this._drawType = drawType
    this._childIds = childIds
    if (!childIds && childId !== void 0) {
      this._childIds = [childId]
    }

    return modelApi
      .getDrawList({
        unitId: this._unitId,
        drawType,
        drawToolId: childId
      })
      .then((data) => {
        let lists = [],
          lists2 = []
        const childIds = this._childIds

        data.forEach((item) => {
          // 去除没有参数的值
          if (!item.drawTool) return

          if (!item.config) item.config = JSON.stringify({})

          const inside = +item.scene === SCEN_ARIO.INTERNAL

          item.isViews = true

          if (!inside) return lists2.push(item)

          let { config } = item
          if (config && typeof config === 'string') {
            config = JSON.parse(config)
          }
          if (item.config?.uiText) item.uiText = item.name

          item.drawName2 = `内部结构：${item.buildName ? item.buildName + '/' : ''}${item.drawName}`

          lists.push({
            ...item,
            config,
            key
          })
        })

        if (childIds) {
          // 内景数据
          lists = lists.filter((item) => {
            return childIds.includes(+item.drawToolId) && item.buildId !== void 0
          })
          lists2 = lists2.filter(item => childIds.includes(+item.drawToolId))
        }

        if (lists2.length > 0) {
          this.scope.handlerDrawData({
            data: lists2,
            reset,
            callback: (data) => {
              if (!this._outsideBaseData[key]) {
                this._outsideBaseData[key] = []
              }
              this._outsideBaseData[key].push(data)
            }
          })
        }

        if (lists.length > 0) {
          HtmlHostMsg.event('tool', lists)
        }
        let lastLists = lists2.concat(lists)
        return lastLists
      })
  }

  // 获取已部署的消防设施列表
  getUnitDrawFn({ isInside = false, drawType } = {}) {
    const params = {
      drawType: drawType || this._drawType,
      scene: SCEN_ARIO[isInside ? 'INTERNAL' : 'EXTERNAL']
    }

    if (this._childIds && this._childIds[0] && isInside) {
      params.drawToolId = this._childIds[0]
    }

    if (isInside) {
      params.buildId = Store.state.planDrill.moduleBuildId || null
    }
    // if (this._childIds && this._childIds[0] === 72) {
    //   return getUnitDrawListBak({ ...params, radius: 3000 })
    // }
    // return getDrawList(params)
    return this.getUnitDrawListFn(params, this._childIds)
  }

  getUnitDrawListFn(params, childIds) {
    params.unitId = this._unitId

    if (childIds && childIds[0] === 72) {
      return modelApi.getUnitDrawListBak({ ...params, radius: 3000 })
    }
    return modelApi.getDrawList(params)
  }

  // other
  flyToInsideModel(item, key) {
    const data = this._formatInteriorDataItem(item, key)

    HtmlHostMsg.event('floor', { expend: true, otherFloors: false, modelKey: item.modelKey })
    HtmlHostMsg.event('tool', {
      ...data,
      view: true,
      flyTo: true
    })
  }
}
