<template>
  <div class="draw-container" v-show="shown">
    <button class="layui-btn layui-btn-sm text-danger" @click="handelcancel"><i class="layui-icon">ဆ</i> 取消</button>
    <button class="layui-btn layui-btn-sm text-normal" @click="handelConfirm"><i class="layui-icon"></i> 确定</button>
  </div>
</template>

<script>
export default {
  props: {
    shown: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handelcancel() {
      this.$emit('cancel')
    },
    handelConfirm() {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.draw-container {
  position: fixed;
  z-index: 99;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  background: #002772;
  border-radius: 10px;
  button {
    background: transparent;
    font-weight: bolder;
    font-size: 14px;
  }
}

.text-danger {
  color: #f05427;
}

.text-normal {
  color: #18cfd7;
}
</style>
