<template>
  <div class="examinetable-box" v-if="showItemBox" :class="modalClassName">
    <div class="item-close">
      <span>{{ `${name} ( ${tableList.length} )` }}</span>
      <span class="el-icon-close" @click="closeItemComponents" />
    </div>
    <ul class="box-list">
      <div class="nolist" v-if="!tableList.length">暂未部署该设施</div>
      <li v-else class="item-tab" v-for="(item, index) in tableList" :key="index" @click="onFocus(item)">
        <h3>{{ item.drawName }}</h3>
      </li>
    </ul>
  </div>
</template>

<script>
import HtmlHostMsg from 'HtmlHostMsg'
export default {
  data() {
    return {
      name: '',
      showItemBox: false,
      modalClassName: '',
      tableList: []
    }
  },
  mounted() {
    if (this.$route.name === 'PlanDeduction') {
      this.modalClassName = 'view-position'
    }
  },
  methods: {
    onFocus(data) {
      if (!data.scene) {
        this.$EventBus.$emit('change.key.unit.center.map.status', false)

        this.$parent.flyToEntityByData(data)
      } else {
        this.$parent.changeSceneStatus(true)
        HtmlHostMsg.event('tool', {
          ...data,
          view: true,
          flyTo: true,
          uiAnchor: true
        })
      }
    },

    getTableList(name, data) {
      this.showItemBox = true
      this.name = name
      this.tableList = data
    },

    closeItemComponents() {
      this.showItemBox = false
    }
  }
}
</script>

<style scoped lang="scss">
.examinetable-box {
  left: 0;
  top: 200px;
  position: fixed;
  z-index: 2;
  background-color: #000001dc;
  color: #fff;
  border-radius: 5px 0 0 5px;
  border: 1px solid #063f79;
  border-right: none;
  .item-close {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 5px 12px;
    .el-icon-close {
      font-size: 16px;
      border-radius: 50%;
      cursor: pointer;
      color: #fff;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
  .box-list {
    padding: 0 3px 10px 10px;
    width: 150px;
    height: 400px;
    overflow-y: scroll;
    .nolist {
      margin-top: 180px;
      text-align: center;
    }
    .item-tab {
      padding: 5px;
      border: 1px solid #063f79;
      margin-bottom: 5px;
      cursor: pointer;
      font-size: 12px;
      background-color: #0c1118;
      border-radius: 5px;
      &:hover {
        box-shadow: 0px 0px 15px 2px #0456ac inset;
      }
      > h3 {
        font-size: 12px;
        word-break: break-all;
      }
    }
  }
}
.view-position {
  top: 65px;
  right: 440px;
}
</style>
