
import EventBus from '@/utils/bus'

let isShowDiesc = false
let event = ['control.layer.description.box', status => isShowDiesc = status]

EventBus.$on(...event)

export default function (okHandler, cancelHandler) {
  const objs = $("#shapeEditContainer")

  if (objs) {
    objs.remove()
    EventBus.$off(...event)
  }

  EventBus.$on(...event)

  const html1 = `<div id="shapeEditContainer" style="padding: 10px 10px;">
      <div class="layui-form-item">
        <label class="layui-form-label">描述</label>
        <div class="layui-input-block">
          <textarea name="desc" placeholder="描述内容" class="layui-textarea"></textarea>
        </div>
      </div>
      <div class="shapeEditContainer-btn">
        <button name="btnCancel" class="layui-btn layui-btn-xs layui-btn-danger"> <i class="layui-icon">ဆ</i> 取消 </button>
        <button name="btnOK" class="layui-btn layui-btn-xs layui-btn-normal"> <i class="layui-icon"></i> 确定 </button>
      </div>
      <div id="shapeEditRTCorner" style="width: 16px; position: absolute; right: 0px; top: 0px; bottom: 0px">
    </div>
  </div>`

  const html2 = `<div id="shapeEditContainer" style="padding: 10px 10px;">
      <div class="shapeEditContainer-btn">
        <button name="btnCancel" class="layui-btn layui-btn-xs layui-btn-danger"> <i class="layui-icon">ဆ</i> 取消 </button>
        <button name="btnOK" class="layui-btn layui-btn-xs layui-btn-normal"> <i class="layui-icon"></i> 确定 </button>
      </div>
      <div id="shapeEditRTCorner" style="width: 16px; position: absolute; right: 0px; top: 0px; bottom: 0px">
    </div>
  </div>`

  $("#cesiumContainerBox").append(isShowDiesc ? html1 : html2)

  const btnOK = $("#shapeEditContainer button[name='btnOK']")
  const btnCancel = $("#shapeEditContainer button[name='btnCancel']")

  btnOK.unbind("click").bind("click", okHandler)
  btnCancel.unbind("click").bind("click", cancelHandler)
}