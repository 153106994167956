import Store from '@/store'

export default class MonitorListener {
  constructor(viewer) {
    this.__viewer = viewer
    //配置项
    this.__typeList = {
      ray: []
    }

    this.initListener()
  }

  initListener() {
    let isClick = false,
      viewer = this.__viewer

    const handler = () => {
      if (this.timer) clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        try {
          this.handlerList(viewer.camera.position)
        } catch (err) {
          // console.dir(err)
        }
        clearTimeout(this.timer)
        this.timer = null
      }, 1000)
    }

    this.listener = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas)

    this.listener.setInputAction(() => isClick = true, Cesium.ScreenSpaceEventType.LEFT_DOWN)
    this.listener.setInputAction(() => isClick = false, Cesium.ScreenSpaceEventType.LEFT_UP)

    this.listener.setInputAction(() => isClick = true, Cesium.ScreenSpaceEventType.RIGHT_DOWN)
    this.listener.setInputAction(() => isClick = false, Cesium.ScreenSpaceEventType.RIGHT_UP)

    this.listener.setInputAction(() => {
      if (!isClick) return
      handler()
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)

    this.listener.setInputAction(() => handler(), Cesium.ScreenSpaceEventType.WHEEL)
  }

  //监听集合
  handlerList(e) {
    this.exampleRay(e)
  }

  // 有关射线的遮挡监听
  exampleRay(cameraPosition) {
    if (!cameraPosition || Store.state.planDrill.enterInside) return

    this.__typeList.ray.forEach((item) => {
      let direction = Cesium.Cartesian3.normalize(Cesium.Cartesian3.subtract(item.position, cameraPosition, new Cesium.Cartesian3()), new Cesium.Cartesian3())
      let ray = new Cesium.Ray(cameraPosition, direction)
      // 这个方法没有暴露
      const { object, position } = this.__viewer.scene.pickFromRay(ray)

      if ((object?.id || object?.content) && position && Cesium.Cartesian3.distance(position, item.position) > 3) {
        if (!item.ischange) return
        item.ischange = false
        //遮挡回调
        item.shelter && item.shelter()
      } else {
        if (item.ischange) return
        item.ischange = true
        item.unshelter && item.unshelter()
      }
    })
  }

  /**
   * 暴露方法,实体加入监听器
   * @param {Object} dataList{
   *    @param entity 实体(必传)
   *     ...
   * }
   * @param {String} type
   */
  joinMonitor(dataList, type) {
    this.__typeList[type].push({ ...dataList, ischange: true })
  }

  // 移除监听器
  removeListner() {
    this.listener.destroy()
  }

  // 从监听中移除实体
  clearItemEntity(entity, type) {
    this.__typeList[type].splice(
      this.__typeList[type].findIndex(item => item.entity === entity),
      1
    )
  }
}
