import { GET, DELETE, POST } from '@/utils/http'

const HOST = '/service-fire/fire/sandTable'

/**
 * @description 沙盘记录相关接口
 *    - add          新增修改沙盘记录
 *    - get          获取修改沙盘记录列表
 *    - delete       删除沙盘记录
 *    - saveDraft    保存草稿(不保存退出)
 *    - saveOfficial 保存沙盘记录（保存退出）
 */
export const record = {
  add(params) {
    return POST({ url: `${HOST}/add`, params })
  },

  saveDraft({ id }) {
    return POST({ url: `${HOST}/saveDraft?id=${id}` })
  },

  saveOfficial({ id }) {
    return POST({ url: `${HOST}/saveOfficial?id=${id}` })
  },

  get(params) {
    return GET({ url: `${HOST}/list`, params })
  },

  delete({ id }) {
    return DELETE({ url: `${HOST}/delete?id=${id}` })
  }
}

/**
 * @description 灾情设定接口
 *    - add    新增修改灾情设定
 *    - get    获取灾情设定列表
 *    - delete 删除灾情设定
 */
export const disaster = {
  add(params) {
    return POST({ url: `${HOST}/disaster/add`, params })
  },

  delete({ id }) {
    return DELETE({ url: `${HOST}/disaster/delete?id=${id}` })
  },

  get(params) {
    return GET({ url: `${HOST}/disaster/list`, params })
  }
}

/**
 * @description 力量调派
 *    - add    新增修改调派队站
 *    - addCar 新增调派车辆
 *    - getList 获取调派队站车辆列表
 *    - getCarEquip 获取调派车辆装备列表
 *    - delete 删除调派队站
 *    - getNextOrgan 获取当前账户下所有的组织机构（队站列表）
 */
export const powerTransfer = {
  add(params) {
    return POST({ url: `${HOST}/organ/add`, params })
  },

  addCar(params) {
    return POST({ url: `${HOST}/organ/addCar`, params })
  },

  getNextOrgan() {
    return GET({ url: '/service-users/userBase/userNextOrgan' })
  },

  getList(params) {
    return GET({ url: `${HOST}/organ/list`, params })
  },

  getCarEquip(params) {
    return GET({ url: `${HOST}/organ/carEquipList`, params })
  },

  delete({ id }) {
    return DELETE({ url: `${HOST}/organ/delete?id=${id}` })
  }
}

/**
 * @description 力量部署
 *    - add    新增修改推演部署
 *    - get    获取推演部署列表
 *    - type 获取电子沙盘演练部署类型列表
 *    - delete 删除推演部署记录
 *    - getGrade 获取部署阶段
 *    - getCar 获取作战力量，车辆数据
 */
export const deploy = {
  add(params) {
    return POST({ url: `${HOST}/deploy/add`, params })
  },

  get(params) {
    return GET({ url: `${HOST}/deploy/list`, params })
  },

  type(params) {
    return GET({ url: `${HOST}/deploy/deployTypeList`, params })
  },

  delete({ id }) {
    return DELETE({ url: `${HOST}/deploy/delete?id=${id}` })
  },

  getGrade(params) {
    return GET({ url: `${HOST}/deploy/getGrade`, params })
  },

  getCar(params) {
    return GET({ url: `${HOST}/deploy/deployCarList`, params })
  }
}

/**
 * @description 白板相关接口
 *    - add    新增沙盘白板
 *    - get    获取修改沙盘白板列表
 *    - delete 删除沙盘白板
 */
export const whiteBoard = {
  add(params) {
    return POST({ url: `${HOST}/whiteBoard/add`, params })
  },

  delete({ id }) {
    return DELETE({ url: `${HOST}/whiteBoard/delete?id=${id}` })
  },

  get(params) {
    return GET({ url: `${HOST}/whiteBoard/list`, params })
  }
}

export default {
  record,
  disaster,
  powerTransfer,
  deploy,
  whiteBoard
}
