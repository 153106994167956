<template>
  <div>
    <div class="info_model" v-if="visibleInfoModel">
      <div class="head-style">
        <div class="head-bottom-style"></div>
        <span class="title">{{ info.name }}</span>
        <!-- <i class="el-icon-close" @click="onClose"></i> -->
      </div>

      <div class="building-main-information building-header">
        <p class="info-title">地址</p>
        <p class="info-detail">
          <i class="iconfont icondingwei"></i>
          {{ info.address || '无' }}
        </p>
        <p></p>
      </div>
      <ul class="building-information">
        <li>
          <div>
            <span>{{ info.buildHeight || '-' }}</span> m
          </div>
          <div>建筑高度</div>
        </li>
        <li>
          <div>
            <span>{{ info.overallFloorage || '-' }}</span> ㎡
          </div>
          <div>建筑面积</div>
        </li>
        <li>
          <div>
            <span>{{ info.floorArea || '-' }}</span> ㎡
          </div>
          <div>占地面积</div>
        </li>
        <li>
          <div>
            <span>{{ info.human || '-' }}</span> 人
          </div>
          <div>人数</div>
        </li>
      </ul>
      <ul class="building-main-information">
        <li>
          <p class="info-title_2">建筑结构</p>
          <p class="info-detail">{{ info.buildStructure || '无' }}</p>
        </li>
        <li>
          <p class="info-title_2">主要使用性质</p>
          <p class="info-detail">{{ info.useNatrue || '无' }}</p>
        </li>

        <li>
          <p class="info-title_2">单位属性</p>
          <p class="info-detail">{{ info.unitType || '无' }}</p>
        </li>
        <li>
          <p class="info-title_2">主要危险性</p>
          <p class="info-detail">{{ info.dangerousness || '无' }}</p>
        </li>
        <li>
          <p class="info-title_2">联系人</p>
          <p class="info-detail">{{ info.unitLeader || '无' }} {{ info.unitLeaderPhone }}</p>
        </li>
      </ul>
    </div>
    <div v-if="visibleInfoModel" class="infor-model_mask" @click="onClose"></div>
  </div>
</template>

<script>
export default {
  props: {
    info: Object
  },

  data() {
    return {
      visibleInfoModel: false
    }
  },

  methods: {
    open() {
      this.visibleInfoModel = true
    },

    onClose() {
      this.visibleInfoModel = false
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

$colorYellow: #d09f29;
$colorWhite: #ececec;
$bgColor: rgba(3, 9, 18, 0.9);
$colorBlue: #07eef5;

.infor-model_mask {
  @include size(100vw, 100vh);
  background-color: rgba($color: #000000, $alpha: 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
}

.info_model {
  @include size(550px, 74%);

  padding: 15px 25px;
  background: rgba(3, 9, 18, 0.9);
  border-radius: 5px;

  transition: transform 0.6s;

  position: absolute;
  left: 50%;
  top: 59px;
  transform: translateX(-50%);
  z-index: 5;

  .el-icon-close {
    font-size: 25px;
    color: #fff;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3;
  }

  .building-main-information {
    color: #fff;
    li {
      margin-bottom: 14px; //14
      p {
        font-weight: 400;
        color: #ffffff;
        letter-spacing: 5px;
      }
    }
    .info-title {
      font-size: 18px;
      margin: 23px 0 19px;
    }
    .info-title_2 {
      font-size: 18px;
      line-height: 42px;
    }
    .info-detail {
      font-size: 16px;
      line-height: 18px;
      opacity: 0.6;
    }
  }

  .building-information {
    width: 100%;
    margin-bottom: 14px;
    @include flex-center(space-between);
    li {
      div {
        &:first-child {
          font-size: 18px;
          font-weight: normal;
          color: #d5a12f;
          margin-bottom: 15px;
          span {
            font-size: 25px;
            font-family: oswald;
          }
        }
        &:last-child {
          font-size: 16px;
          color: #fff;
        }
      }
    }
  }
  .building-header {
    margin-bottom: 23px;
    .info-detail {
      opacity: 1;
    }
    i {
      color: #19d5db;
    }
  }

  @media screen and (max-width: 768px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.638);
    zoom: 1;

    .building-main-information {
      li {
        margin-bottom: 12px; //14
      }
      .info-title {
        font-size: 14px;
        line-height: 22px;
      }
      .info-detail {
        font-size: 12px;
        line-height: 18px;
        opacity: 0.6;
      }
    }
  }
}
</style>
