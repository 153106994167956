import { GET } from '@/utils/http'

const SOCIALIZE_HOST = '/service-socialize-v2'
/**
 * 获取单位消防车辆 默认按登录的单位id
 * @export getPowerCarList
 * @param {*} data
 */
export const getPowerCarList = (params) => {
  return GET({ url: `${SOCIALIZE_HOST}/socialize/power/car/list`, params })
}

/**
 * 获取单位义务消防人员列表
 * @export getDutyHumanList
 * @param {*} data
 */
export const getDutyHumanList = (params) => {
  return GET({ url: `${SOCIALIZE_HOST}/socialize/dutyHuman/list`, params })
}

/**
 * 组织结构
 * @export humanGroupList
 * @param {*} data
 */
export function humanGroupList(params) {
  return GET({ url: `${SOCIALIZE_HOST}/socialize/human/getHumanGroupList`, params })
}

export function findCarByOrganId(params) {
  return GET({ url: '/service-fire/fireData/organCar', params })
}

//获取单位附近消火栓
export function getUnitDrawListOutside(params) {
  return GET({ url: `${SOCIALIZE_HOST}/drawInfo/listByLonLatAndRadius`, params })
}

export default {
  getPowerCarList,
  getDutyHumanList,
  humanGroupList,
  findCarByOrganId
}
