// 绘画类型 0其他
export const PLANDRAWTYPE = {
  // 1消防设施
  FIR_ECONTROLFACILITIES: 1,
  // 2建筑分区
  BUILDING_PARTITION: 2,
  // 3重点部位
  KEY_PARTS: 3,
  // 3 建筑物/体
  BUILD: 4,
  // 5 建筑周边
  SURROUND_BUILD: 5,
  // 6 楼梯通道
  STAIR_SPASSAGE: 6
}

// 场景id， 场景内外
export const SCEN_ARIO = {
  EXTERNAL: 0,

  INTERNAL: 1
}

// 各类型背景和字颜色
export const TOOL_COLOR_LIST = {
  [PLANDRAWTYPE.FIR_ECONTROLFACILITIES]: ['#bf0000', '#fff'],
  [PLANDRAWTYPE.BUILDING_PARTITION]: ['#a3d206', '#000'],
  [PLANDRAWTYPE.KEY_PARTS]: ['#d25806', '#fff'],
  [PLANDRAWTYPE.BUILD]: ['#4d00b9', '#fff'],
  [PLANDRAWTYPE.SURROUND_BUILD]: ['#9013fe', '#fff'],
  [PLANDRAWTYPE.STAIR_SPASSAGE]: ['#d2009e', '#fff']
}

// 部署类别
export const DEPLOY_PHASE = {
  // 作战部署
  EXTERNAL: 1,
  // 内功部署
  INTERNAL: 2
}

// 建筑类型对应的值(后端不弄)
export const BUILD_TYPE_BY_NAME = {
  高层建筑: 0,
  地下空间: 1,
  大型综合体: 2,
  石化厂: 3,
  其他: 4
}

export const DEPLOY_GRADE_POLYGON_COLOR = ['#00fffc', '#35acf4', '#ffde00', '#ff4b4b', '#00fffc']
