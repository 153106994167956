<template>
  <div>
    <menu-bar
      :scene="specifyScene"
      :inside="enterInside"
      :build-type="userCompanyInfo ? userCompanyInfo.buildType : ''"
      @active="distributedExecutionMethod"
      @unactivated="resetLastKeyDrawData"
    ></menu-bar>

    <info-window ref="infoModel" :info="userCompanyInfo"></info-window>
    <fire-duty-window ref="fireDutyModel" :info="userCompanyInfo" :inPlan="inPlan"></fire-duty-window>
    <organization-window ref="organizationModel" :info="userCompanyInfo" :inPlan="inPlan"></organization-window>
    <template v-if="enterInside && !inPlan">
      <div v-if="floorListsModelVisible" class="floor-list-model">
        <ul>
          <li v-for="item in floorLists" :key="item.id" :class="{ active: currentSelectFloor === item.modelKey }" @click="onChangeFloorInside(item)">
            <span>{{ item.name }}</span>
          </li>
        </ul>
      </div>

      <div class="floor-list-model_visible" @click="floorListsModelVisible = !floorListsModelVisible">{{ currentSelectFloor || '楼层' }}</div>
    </template>

    <carou-sel ref="carouelBox"></carou-sel>
    <examine-table ref="examineTable"></examine-table>
  </div>
</template>

<script>
import HtmlHostMsg from 'HtmlHostMsg'
import modelApi from '@/api/model'

import { isPc } from '@/utils/util'
import { PLANDRAWTYPE, SCEN_ARIO } from '@/utils/conf'

import MenuBar from './components/Menu'
import InfoWindow from './components/InfoModel'
import FireDutyWindow from './components/FireDutyModel'
import OrganizationWindow from './components/OrganizationModel'
import CarouSel from './components/CarouSel'
import ExamineTable from './components/ExamineTable'

import commonMix from './mixins/common'
import BasicOverview from './mixins/BaseData'

export default {
  mixins: [commonMix],

  components: {
    MenuBar,
    InfoWindow,
    CarouSel,
    ExamineTable,
    FireDutyWindow,
    OrganizationWindow
  },

  props: {
    // 以组件的形式存在
    inPlan: {
      type: Boolean,
      default: true
    },

    // 指定室外或室内
    specifyScene: String
  },

  inject: ['cesiumBox'],

  data() {
    return {
      userCompanyInfo: {},

      isPc: isPc(),

      floorListsModelVisible: isPc(),

      floorStatusIs3d: true,
      // 是否显示其他楼层
      floorStatusIsViewOtherFloor: true
    }
  },

  created() {
    this.unitId = this.$route.query.id
    this.initEvent()
  },

  mounted() {
    this.$nextTick(() => {
      return modelApi
        .getCompanyInfo({
          unitId: this.unitId
        })
        .then((data) => {
          this.$store.commit('user/SET_USER_COMPANY_INFO', data)
          this.userCompanyInfo = data
          this.BasicOverview = new BasicOverview(this, {
            unitId: this.unitId
          })
        })

      // this.fetchSafetyExit()
    })
  },

  beforeDestroy() {
    this.$events.forEach(item => this.$EventBus.$off(...item))
    HtmlHostMsg.event('floor', { layerFloors: false, html: true })

    this.checkCurrentSceneStatus()
  },

  methods: {
    initEvent() {
      this.$events = [
        [
          'change.key.unit.center.map.status',
          (state) => {
            if (this.enterInside && this.enterInside === state) return

            if (state && !isPc()) {
              this.$nextTick(() => HtmlHostMsg.event('floor', { html: false }))
            }
          }
        ]
      ]

      this.$events.forEach(item => this.$EventBus.$on(...item))
    },

    // 绘制安全出口
    fetchSafetyExit() {
      const buildId = this.$store.state.planDrill.moduleBuildId || null

      if (!buildId) {
        let timer = setTimeout(() => {
          clearTimeout(timer)
          timer = null
          this.fetchSafetyExit()
        }, 500)

        return
      }

      return modelApi
        .getDrawList({
          buildId,
          unitId: this.unitId,
          drawType: PLANDRAWTYPE.FIR_ECONTROLFACILITIES,
          scene: SCEN_ARIO.INTERNAL,
          drawToolId: 84
        })
        .then((data) => {
          data = data.map(item => this.BasicOverview._formatInteriorDataItem(item, '安全出口'))
          this.safetyExitDataLists = data
        })
    },

    handleBack() {
      this.$router.back()
    },

    changeSceneSide() {
      this.changeSceneStatus(!this.enterInside)
    },

    changeFloorMenu(index) {
      const fns = [
        () => {
          const is3D = this.floorStatusIs3d

          this.floorStatusIs3d = !is3D
          HtmlHostMsg.event('floor', { convert3D: !is3D })
        },
        () => {
          const showOtherFloor = this.floorStatusIsViewOtherFloor
          this.floorStatusIsViewOtherFloor = !showOtherFloor

          HtmlHostMsg.event('floor', { singleLayer: !showOtherFloor })
        }
      ]

      fns[index]()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.back-to-outdoor {
  top: 129px;
  right: 28px;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.new-plan-button {
  position: absolute;
  right: 24px;
  top: 75px;
  z-index: 3;
  .el-button {
    margin-right: 5px;
  }
}

.menu-container_back {
  color: #fff;
  cursor: pointer;
  padding: 15px;
  position: absolute;
  left: 30px;
  top: 70px;
  z-index: 3;
  &:hover {
    color: #007eff;
  }
  .iconfont {
    font-size: 28px;
  }

  @media screen and (max-width: 768px) {
    left: 14px;
  }
}

.floor-list-model {
  width: 110px;
  height: 70%;
  overflow: auto;
  padding: 15px 10px;
  box-sizing: border-box;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: rgba(3, 9, 18, 0.9);
  box-shadow: 2px 3px 17px 1px rgba(105, 114, 131, 0.18);

  position: absolute;
  left: 0;
  top: 125px;
  z-index: 3;

  ul {
    user-select: none;
    flex-wrap: wrap;
    @include flex-center();

    li {
      @include size(38px);
      @include flex-center(center);
      border-radius: 50%;
      border: 1px solid #2881ca;
      background-color: rgba(2, 15, 33, 0.94);
      transition: background 0.2s ease;
      line-height: 35px;
      cursor: pointer;
      margin-bottom: 10px;

      &:nth-child(2n) {
        margin-left: 10px;
      }

      &.active,
      &:hover {
        background-color: #2881ca;
      }

      span {
        color: #fff;
        font-size: 12px;
        transform: scale(0.95);
        zoom: 1;
        line-height: 1.2;
        display: inline-block;
        width: 28px;
        text-align: center;
        white-space: normal;
      }
    }
  }

  p {
    @include size(100%, 35px);
    background-color: #000;
    color: #fff;
    text-align: center;
    line-height: 35px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &_visible {
    @include size(50px);
    color: #fff;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    border: 1px solid #2881ca;
    background-color: rgba(2, 15, 33, 0.94);
    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: translateX(-50%);
    z-index: 3;
    display: none;
  }

  &_menu {
    position: absolute;
    top: 13px;
    right: 10px;
    @include flex-center();
    span {
      color: #007eff;
      font-size: 12px;
      @include size(36px, 20px);
      text-align: center;
      line-height: 20px;
      border: 1px solid #007eff;
      margin-right: 10px;

      &:last-child {
        position: relative;
        &::after {
          content: '';
          @include size(16px);
          // background: url('~@/assets/images/dataManage/view-or-fail.png');
          background-position: -10px -10px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &.hide-other::after {
        background-position: -46px -10px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    // display: none;
    width: 58%;
    height: 35%;
    left: 50%;
    top: unset;
    bottom: 84px;
    transform: translateX(-50%);
    border-radius: 10px;
    box-shadow: none;

    ul {
      position: relative;
      padding-top: 32px;
      &::before {
        content: '选择楼层';
        color: #fff;
        font-size: 12px;
        position: absolute;
        top: 0;
        left: 0;
      }

      li {
        margin-right: 10px;

        &:nth-child(4n) {
          margin-right: 0;
        }
        &:nth-child(2n) {
          margin-left: 0;
        }
      }
    }

    &_visible {
      display: block;
    }
  }
}

.key-parts_model {
  @include size(460px, 82%);

  padding: 0 20px;
  background: rgba(3, 9, 18, 0.9);
  border-radius: 5px;

  transition: transform 0.6s;

  position: absolute;
  left: 15px;
  top: 135px;
  z-index: 3;

  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }

  .el-icon-close {
    font-size: 25px;
    color: #fff;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3;
  }

  & > p {
    color: #fff;
    font-size: 16px;
    margin-top: 100px;
    text-align: center;
  }

  &-main {
    width: 100%;
    padding-top: 20px;
    height: 650px;
    overflow-y: scroll;

    li {
      color: #fff;
      padding: 15px 30px;
      width: 100%;
      cursor: pointer;
      border: 1px solid rgba($color: #007eff, $alpha: 0.5);
      margin-bottom: 15px;

      &:hover {
        box-shadow: 0 0 20px #007eff inset;
      }
    }
  }

  @media screen and (max-width: 768px) {
    @include size(250px, 300px);
    padding: 0 20px;

    transform: scale(0.6);
    transform-origin: left top;

    & > p {
      font-size: 12px;
    }

    .key-parts_model-main {
      height: 200px;
      padding-top: 10px;

      li {
        padding: 10px;
      }
    }
  }
}

.change-scene-side {
  position: absolute;
  bottom: 10px;
  right: 19px;
  z-index: 3;
}
</style>
