import flow from './Flow'
import flowImage from '@/assets/images/model/arrow-2.png'
export default class Polyine {
  constructor(viewer, positions, options = {}) {
    this.__viewer = viewer

    if (!positions) throw new Error('positions is undefined')

    this.__positions = positions

    options.type = options.type || 'default'

    this.__options = options
  }

  get entity() {
    return this.__entity
  }

  generate() {
    let options = this.__options

    options.width = options.width || 30
    options.color = options.color || '#ffba49'
    options.zIndex = options.zIndex || 0
    options.distanceDisplayCondition = options.distanceDisplayCondition || [0, 2000]

    let entity

    if (options.type === 'flow') {
      try {
        entity = flow(this.__viewer, {
          flowImage,
          interval: 2000,
          repeat: 20,
          color: Cesium.Color.fromCssColorString(options.color).withAlpha(1),
          options: {
            objId: options.objId,
            shapeType: 'Polyline',
            polyline: {
              width: options.width,
              clampToGround: true,
              positions: this.__positions,
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              distanceDisplayCondition: new Cesium.DistanceDisplayCondition(...options.distanceDisplayCondition),
              zIndex: options.zIndex
            }
          }
        })
      } catch (error) {
        console.log(error)
      }
    } else {
      entity = this.__viewer.entities.add({
        objId: options.objId,
        shapeType: 'Polyline',
        polyline: this.getOptions()
      })
    }
    this.__entity = entity

    return entity
  }
  // 取消监听事件
  removeListener() {
    this.__viewer.scene.preRender.removeEventListener(this.listener)
  }
  //模型高度监听
  modelHeightListener(entityType) {
    let multiple = entityType === 'flow' ? 3 : 1 //multiple判断是线条或箭头
    let lastHeight //记录前一次的高度,避免重复赋值
    this.listener = this.__viewer.scene.preRender.addEventListener((changeToPromptRender) => {
      let { height: modelHeight } = changeToPromptRender.camera.positionCartographic //获取当前相机高度
      let realityWidth //当前宽度

      if (lastHeight === modelHeight) return

      lastHeight = modelHeight //----记录前一次的高度,避免重复赋值

      if (modelHeight < 10) {
        realityWidth = 1000
      } else if (modelHeight <= 20) {
        realityWidth = modelHeight + 400
      } else if (modelHeight <= 50) {
        realityWidth = modelHeight + 200
      } else if (modelHeight <= 70) {
        realityWidth = modelHeight / 2 + 180
      } else if (modelHeight <= 100) {
        realityWidth = modelHeight / 2 + 140
      } else if (modelHeight <= 150) {
        realityWidth = modelHeight / 4 + 110
      } else if (modelHeight <= 200) {
        realityWidth = modelHeight / 4 + 60
      } else if (modelHeight <= 250) {
        realityWidth = modelHeight / 6 + 40
      } else if (modelHeight <= 300) {
        realityWidth = modelHeight / 6 + 30
      } else if (modelHeight <= 400) {
        realityWidth = modelHeight / 10 + 10
      } else {
        realityWidth = 40
      }
      this.__entity.polyline.width = realityWidth / multiple
      // if (changeToPromptRender) {
      // this.__viewer.scene.requestRender();
      // }
    })
    return this
  }

  getOptions() {
    let { type, ...data } = this.__options

    // 线宽
    data.width = data.width || 8
    // 线段颜色
    data.color = data.color || '#00f'
    // 颜色透明度
    data.opacity = data.opacity || 0.9
    // 针对线段的发光效果
    data.glowPower = data.glowPower || 0.25

    // 渐变效果强度的数值属性
    data.taperPower = data.taperPower || 1
    data.clampToGround = data.clampToGround === void 0 ? true : data.clampToGround

    data.distanceDisplayCondition = data.distanceDisplayCondition || [0, 2000]

    data.zIndex = data.zIndex || 0

    let material = null

    switch (type) {
      // 默认
      case 'default':
        {
          material = Cesium.Color.fromCssColorString(data.color).withAlpha(data.opacity)
        }
        break

      // 发光
      case 'glow':
        {
          material = new Cesium.PolylineGlowMaterialProperty({
            glowPower: data.glowPower,
            taperPower: data.taperPower,
            color: Cesium.Color.fromCssColorString(data.color).withAlpha(data.opacity)
          })
        }
        break

      // 箭头线段
      case 'arrow':
        {
          material = new Cesium.PolylineArrowMaterialProperty(Cesium.Color.fromCssColorString(data.color).withAlpha(data.opacity))
        }
        break

      // 虚线段
      case 'dash':
        {
          material = new Cesium.PolylineDashMaterialProperty({
            color: Cesium.Color.fromCssColorString(data.color).withAlpha(data.opacity)
          })
        }
        break
    }

    return {
      width: data.width,
      material,
      clampToGround: data.clampToGround,
      positions: this.__positions,
      interval: 3000,
      disableDepthTestDistance: Number.POSITIVE_INFINITY,
      distanceDisplayCondition: new Cesium.DistanceDisplayCondition(...data.distanceDisplayCondition),
      zIndex: data.zIndex
    }
  }
}
