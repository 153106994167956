import { makeCartesian2 } from '@/utils/cesiumUtil'

import '@/libs/lighter/jquery.lighter.js'
import '@/libs/lighter/jquery.lighter.css'
import Store from '@/store'

/**
 * @description: 信息弹窗
 * @param {Cesium.Viewer} viewer
 * @param {Object}        options
 * * * * * * *
 * options:
 * @param {String} title
 * @param {Array.Object} data
 * @param {Cesium.Cartesian3}   position 笛卡尔坐标
 * @param {Object}    pixelOffset 偏移
 * @return {*}
 */

export default class OverlayHtml {
  constructor(viewer, options) {
    this.__viewer = viewer
    this._position = options.position
    this._drawType = options.drawType

    this._pixelOffset = options.pixelOffset || {
      x: 0,
      y: 0
    }
    this.delBox = null
    this._generatorOverlay(options)

    viewer.scene.preRender.addEventListener(this.onPreRender.bind(this))
  }

  show() {
    this.__isShown = true
    this.__container.style.display = 'block'
  }

  hide() {
    this.__isShown = false
    this.__container.style.display = 'none'
  }

  _fatherNode() {
    return document.getElementById('cesiumContainerBox')
  }

  destroy() {
    this.hide()
    this.__viewer.scene.preRender.removeEventListener(this.onPreRender.bind(this))

    if (this._fatherNode().contains(this.__container)) {
      this._fatherNode().removeChild(this.__container)
    }
  }

  _processOverlay(options) {
    // 工艺流程
    const container = document.createElement('div')
    container.style.cssText = `
        width: 350px;
        display: none;
        position: absolute;
        top: unset;
        bottom: 950px;
        left: -126px;
        text-align: center;
        z-index: 999;
        background:rgba(12, 31, 58, 0.9)
      `
    const borderDiv1 = document.createElement('div')
    borderDiv1.style.cssText = `
      width: 20px;
      height: 20px;
      border: 2px solid #30d7f7;
      position: absolute;
      left: 0;
      top: 0;
      border-right: 0;
      border-bottom: 0;
    `
    const borderDiv2 = document.createElement('div')
    borderDiv2.style.cssText = `
      width: 20px;
      height: 20px;
      border: 2px solid #30d7f7;
      position: absolute;
      right: 0;
      top: 0;
      border-bottom: 0;
      border-left: 0;
    `
    const borderDiv3 = document.createElement('div')
    borderDiv3.style.cssText = `
      width: 20px;
      height: 20px;
      border: 2px solid #30d7f7;
      position: absolute;
      left: 0;
      bottom: 0;
      border-right: 0;
      border-top: 0;
    `
    const borderDiv4 = document.createElement('div')
    borderDiv4.style.cssText = `
      width: 20px;
      height: 20px;
      border: 2px solid #30d7f7;
      position: absolute;
      right: 0;
      bottom: 0;
      border-top: 0;
      border-left: 0;
    `
    container.appendChild(borderDiv1)
    container.appendChild(borderDiv2)
    container.appendChild(borderDiv3)
    container.appendChild(borderDiv4)
    const closeBtn = document.createElement('a')
    closeBtn.appendChild(document.createTextNode('x'))
    closeBtn.style.cssText = `
        position: absolute;
        text-align: center;
        z-index: 999;
        top: 0;
        right: 0;
        padding: 4px 4px 0 0;
        width: 18px;
        height: 14px;
        margin-top: 1px;
        margin-right: 2px;
        color: #fff;
        font: 16px/14px Tahoma,Verdana,sans-serif;
        text-decoration: none;
        font-weight: 700;
        background: transparent;
        cursor: pointer;
      `

    // 绑定点击事件
    closeBtn.addEventListener('click', () => this.hide())

    closeBtn.addEventListener('mouseover', () => {
      closeBtn.style.color = '#c30'
      closeBtn.style.textDecoration = 'underline'
    })

    closeBtn.addEventListener('mouseout', () => {
      closeBtn.style.color = '#fff'
      closeBtn.style.textDecoration = 'none'
    })

    container.appendChild(closeBtn)

    const content = document.createElement('div')
    content.style.cssText = `
        max-width: 350px;
        max-height: 500px;
        overflow-y: auto;
        color: #fff;
        box-shadow: 0 3px 14px rgb(0 0 0 / 40%);
        padding: 20px 34px;
        text-align: left;
        border-radius: 3px;
        background: #01080e82;
        box-sizing: border-box;
      `
    container.appendChild(content)

    const title = document.createElement('p')
    title.appendChild(document.createTextNode(options.title))

    title.style.cssText = `
        font-size: 16px;
        color: #18CFFD;
        text-align: center;
        margin-bottom: 3px;
      `

    content.appendChild(title)
    const genItem = (label, value) => {
      const item = document.createElement('p')
      item.style.cssText = `
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          font-size: 13px;
          margin-top: 3px;
          color: #fff;
          text-align: left;
        `

      const text1 = document.createElement('span')
      const text2 = document.createElement('span')

      text1.style.cssText = 'flex: 0 0 120px'

      text1.appendChild(document.createTextNode(label))
      text2.appendChild(document.createTextNode(value))

      item.appendChild(text1)
      item.appendChild(text2)

      return item
    }

    // 文字内容
    if (options.data && options.data.length > 0) {
      for (const { label, value } of options.data) {
        const item = genItem(label, value)

        content.appendChild(item)
      }
    }
    const imagesContainer = document.createElement('div')
    imagesContainer.style.cssText = `
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-top: 18px;
        `
    const images = options.images
    if (images && images.length > 0) {
      images.forEach(({ url }) => {
        const lighterA = document.createElement('a')
        lighterA.href = url
        lighterA.setAttribute('data-lighter', true)
        const img = document.createElement('img')
        img.src = url
        img.style.cssText = `
            width: 60px;
            height: 60px;
            flex-shrink: 0;
            margin-right: 10px;
            border-radius: 4px;
          `
        lighterA.appendChild(img)
        imagesContainer.appendChild(lighterA)
      })
    }

    if (options.description) {
      const description = document.createElement('p')
      description.style.cssText = `
          font-size: 13px;
          margin-top: 3px;
          color: #fff;
          flex: 1;
        `
      description.appendChild(document.createTextNode('描述：' + options.description))
      imagesContainer.appendChild(description)
    }

    content.appendChild(imagesContainer)
    // 三角形
    const triangle = document.createElement('div')
    triangle.style.cssText = `
      width: 40px;
      height: 20px;
      position: absolute;
      overflow: hidden;
      bottom: -20px;
      left: 50%;
      margin-left: -17px;
    `
    const triangleItem = document.createElement('div')
    triangleItem.style.cssText = `
      box-shadow: 0 3px 14px rgb(0 0 0 / 40%);
      width: 17px;
      height: 17px;
      padding: 1px;
      margin: -10px auto 0;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      background:rgba(1, 8, 14, 0.51);
      box-sizing: border-box;
    `
    triangle.appendChild(triangleItem)
    container.appendChild(triangle)
    this._fatherNode().appendChild(container)
    this.__container = container
  }

  _generatorOverlay(options) {
    if (options.drawToolId === 103) {
      this._processOverlay(options)
      return
    }

    const container = document.createElement('div')

    container.style.cssText = `
      width: 250px;
      display: none;
      position: absolute;
      top: unset;
      bottom: 950px;
      left: -126px;
      text-align: center;
      z-index: 999;
    `

    const closeBtn = document.createElement('a')
    closeBtn.appendChild(document.createTextNode('x'))
    closeBtn.style.cssText = `
      position: absolute;
      text-align: center;
      z-index: 999;
      top: 0;
      right: 0;
      padding: 4px 4px 0 0;
      width: 18px;
      height: 14px;
      margin-top: 1px;
      margin-right: 2px;
      color: #fff;
      font: 16px/14px Tahoma,Verdana,sans-serif;
      text-decoration: none;
      font-weight: 700;
      background: transparent;
      cursor: pointer;
    `

    // 绑定点击事件
    closeBtn.addEventListener('click', () => this.hide())

    closeBtn.addEventListener('mouseover', () => {
      closeBtn.style.color = '#c30'
      closeBtn.style.textDecoration = 'underline'
    })

    closeBtn.addEventListener('mouseout', () => {
      closeBtn.style.color = '#fff'
      closeBtn.style.textDecoration = 'none'
    })

    container.appendChild(closeBtn)

    const content = document.createElement('div')
    content.style.cssText = `
      max-width: 250px;
      max-height: 500px;
      overflow-y: auto;
      color: #fff;
      box-shadow: 0 3px 14px rgb(0 0 0 / 40%);
      padding: 10px;
      text-align: left;
      border-radius: 3px;
      background: #01080e82;
      box-sizing: border-box;
    `
    container.appendChild(content)

    const title = document.createElement('p')
    title.appendChild(document.createTextNode(options.title))

    title.style.cssText = `
      font-size: 15px;
      color: #fff;
      text-align: center;
      margin-bottom: 3px;
    `

    content.appendChild(title)

    if (options.description) {
      const description = document.createElement('p')
      description.style.cssText = `
        font-size: 13px;
        margin-top: 3px;
        color: #fff;
      `
      description.appendChild(document.createTextNode('描述：' + options.description))
      content.appendChild(description)
    }

    const genItem = (label, value) => {
      const item = document.createElement('p')
      item.style.cssText = `
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 13px;
        margin-top: 3px;
        color: #fff;
        text-align: left;
      `

      const text1 = document.createElement('span')
      const text2 = document.createElement('span')

      text1.style.cssText = 'flex: 0 0 120px'

      text1.appendChild(document.createTextNode(label))
      text2.appendChild(document.createTextNode(value))

      item.appendChild(text1)
      item.appendChild(text2)

      return item
    }

    // 文字内容
    if (options.data && options.data.length > 0) {
      for (const { label, value } of options.data) {
        const item = genItem(label, value)

        content.appendChild(item)
      }
    }

    const images = options.images
    // 使用了jquery-lighter
    // https://github.com/ksylvest/jquery-lighter

    if (images && images.length > 0) {
      const imagesContainer = document.createElement('div')
      imagesContainer.style.cssText = `
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 10px;
      `

      images.forEach(({ url }) => {
        const lighterA = document.createElement('a')
        lighterA.href = url
        lighterA.setAttribute('data-lighter', true)

        const img = document.createElement('img')
        img.src = url

        img.style.cssText = `
          width: 60px;
          height: 60px;
          margin-left: 10px;
          margin-bottom: 10px;
          flex-shrink: 0;
        `

        lighterA.appendChild(img)
        imagesContainer.appendChild(lighterA)
      })

      content.appendChild(imagesContainer)
    }

    // 点击按钮
    if (options.modelEntrance && typeof options.modelEntrance === 'function' && !options.hideBtn) {
      const modelEntrance = document.createElement('p')

      modelEntrance.style.cssText = `
        text-align: right;
        font-size: 15px;
        color: #fff;
        margin-top: 5px;
        margin-bottom: 3px;
        cursor: pointer;
      `

      modelEntrance.appendChild(document.createTextNode('进入内部结构'))

      content.appendChild(modelEntrance)

      modelEntrance.addEventListener('click', () => options.modelEntrance())

      modelEntrance.addEventListener('mouseover', () => {
        modelEntrance.style.color = '#c30'
        modelEntrance.style.textDecoration = 'underline'
      })

      modelEntrance.addEventListener('mouseout', () => {
        modelEntrance.style.color = '#fff'
        modelEntrance.style.textDecoration = 'none'
      })
    }
    //删除按钮
    if (options.delItemEntity && typeof options.delItemEntity === 'function' && !options.hideBtn) {
      const delBox = document.createElement('div')
      this.delBox = delBox
      delBox.style.cssText = `
        text-align: right;
      `

      const delButton = document.createElement('button')
      delButton.style.cssText = `
        width:40px;
        height:20px;
        line-height:20px;
        border:none;
        cursor: pointer;
        font-size:16px;
        color:#fff;
        background-color:#aeb1b300;
      `
      delButton.innerText = '删除'
      delBox.appendChild(delButton)
      content.appendChild(delBox)
      delButton.addEventListener('click', () => {
        options.delItemEntity()
      })
      delButton.addEventListener('mouseover', () => {
        delButton.style.color = '#c30'
      })

      delButton.addEventListener('mouseout', () => {
        delButton.style.color = '#fff'
      })
    }

    // 三角形
    const triangle = document.createElement('div')

    triangle.style.cssText = `
      margin: 0 auto;
      width: 40px;
      height: 20px;
      position: relative;
      overflow: hidden;
    `

    const triangleItem = document.createElement('div')
    triangleItem.style.cssText = `
      box-shadow: 0 3px 14px rgb(0 0 0 / 40%);
      width: 17px;
      height: 17px;
      padding: 1px;
      margin: -10px auto 0;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      background: #01080e82;
      box-sizing: border-box;
    `
    triangle.appendChild(triangleItem)

    container.appendChild(triangle)

    this._fatherNode().appendChild(container)

    this.__container = container
  }

  onPreRender() {
    if (!this.__isShown) return

    const { __viewer, _position, _pixelOffset } = this
    const cartesian2 = makeCartesian2(_pixelOffset)
    // const cartesian3 = makeCartesian3(position)
    const scratch = {}
    const canvasPosition = __viewer.scene.cartesianToCanvasCoordinates(_position, scratch)

    if (Cesium.defined(canvasPosition)) {
      //对分区的面特殊处理
      let polyHeight = this._drawType.includes('wallZoneItem') ? 90 : 0
      this.__container.style.transform = `translate3d(${canvasPosition.x + cartesian2.x}px, ${canvasPosition.y + cartesian2.y + polyHeight}px, 0px)`
      // this.__container.style.left = canvasPosition.x + cartesian2.x + 'px'
      // this.__container.style.top = canvasPosition.y + cartesian2.y + 'px'

      const cameraPosition = __viewer.camera.position
      const cartographicPosition = __viewer.scene.globe.ellipsoid.cartesianToCartographic(cameraPosition)

      if (Cesium.defined(cartographicPosition)) {
        let cameraHeight = cartographicPosition.height
        cameraHeight += 1 * __viewer.scene.globe.ellipsoid.maximumRadius
        if (Cesium.Cartesian3.distance(cameraPosition, _position) > cameraHeight) {
          this.__container.style.display = 'none'
        } else {
          this.__container.style.display = 'block'
        }
      }
      if (this.delBox && this._drawType === 'fire') {
        this.delBox.style.display = Store.state.planDrill.currentNavKey === 4 ? 'none' : 'block'
      }
    }
  }
}
