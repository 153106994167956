import { isFunction, isArray, isObject } from './util'
import ZK from '@gis/zk-base'
/**
 * 世界坐标(笛卡尔坐标cartesian3) 转 经纬度, 高度
 * @param {Cesium.Viewer} viewer
 * @param {Cesium.Cartesian3} cartesian3
 */
export function cartesian3ToLonLat(viewer, cartesian3) {
  const cartographic = viewer.scene.globe.ellipsoid.cartesianToCartographic(cartesian3)
  return {
    lon: Cesium.Math.toDegrees(cartographic.longitude),
    lat: Cesium.Math.toDegrees(cartographic.latitude),
    height: viewer.camera.positionCartographic.height / 1000
  }
}

/**
 * 将对象或数组转换为 Cesium.Cartesian2
 * @param {Object} val
 * @returns {Cartesian2 | CallbackProperty} 返回 Cartesian2 或者 CallbackProperty
 * @example
 * const options = [100, 100]
 * // const options = [x: 100, y: 100]
 * const position = makeCartesian2(options)
 */
export function makeCartesian2(val, isConstant = false) {
  const { Cartesian2, CallbackProperty } = Cesium

  if (val instanceof Cartesian2) {
    return val
  }

  if (isObject(val) && Object.prototype.hasOwnProperty.call(val, 'x')) {
    return new Cartesian2(val.x, val.y)
  }

  if (isArray(val)) {
    return new Cartesian2(val[0], val[1])
  }

  if (isFunction(val)) {
    return new CallbackProperty(val, isConstant)
  }
  return val
}

/**
 * @description: 获取Entity 多边形/多个点 中心点
 * @param {Cesium.Cartesian3|Object} positions
 * @return {*}
 */

export function getEntityPolygonCenter(positions, drawType) {
  if (!isArray(positions)) throw new Error('positions is an Array')

  let cartesian3 = []

  if (positions[0] instanceof Object) {
    cartesian3 = positions.map((item) => {
      if (item instanceof Cesium.Cartesian3) return item
      if (!item.x) throw new Error('item does not meet the requirements')
      return new Cesium.Cartesian3(item.x, item.y, item.z)
    })
  } else {
    cartesian3 = arrayHeightsFormat(positions)
  }
  const polyCenter = Cesium.BoundingSphere.fromPoints(cartesian3).center
  //特殊处理立面分区和救生窗
  const excludeList = ['wallZoneItem', 'stereogram']
  if (excludeList.includes(drawType)) {
    return polyCenter
  }
  return Cesium.Ellipsoid.WGS84.scaleToGeodeticSurface(polyCenter)
}

export function arrayHeightsFormat(positions) {
  let cartesian3 = []
  let arr = []

  positions.forEach((item) => {
    if (arr.length === 3) {
      cartesian3.push(coordinates2Cartesian3(...arr))
      arr = [item]
    } else {
      arr.push(item)
    }
  })

  return cartesian3
}

export function coordinates2Cartesian3(lng, lat, height = 0) {
  return Cesium.Cartesian3.fromDegrees(lng, lat, height)
}

// 根据中心点,获取指定距离的四个顶点经纬度
export function fourCornersByCenter({ lon, lat }, distance) {
  const x1 = longitude2Distance(lon, distance)
  const x2 = longitude2Distance(lon, -distance)

  const y1 = longitude2Distance(lat, distance)
  const y2 = longitude2Distance(lat, -distance)

  return [
    [x1, y1],
    [x1, y2],
    [x2, y2],
    [x2, y1]
  ]
  // return [
  //   c23(x1, y1),
  //   c23(x1, y2),
  //   c23(x2, y2),
  //   c23(x2, y1),
  // ]
}

export function longitude2Distance(lon, distance) {
  // 纬度相等的情况下, 比例: 0.1经度 = 10000
  const proportion = 0.1 / 10000

  return lon + distance * proportion
}

export function latitude2Distance(lat, distance) {
  // 经度度相等的情况下, 比例: 0.1纬度 = 10000
  const proportion = 0.1 / 11132

  return lat + distance * proportion
}

/**
 * fromDegreesArrayHeights 去除当中的高度, 获取二维经纬度数组
 * @return [[lng, lat],[lng, lat]]
 */
export function arrayHeight2LngLatAry(positions) {
  let results = [],
    i = 0,
    j = 0
  positions.forEach((p) => {
    if (i === 2) {
      i = 0
      j++
      return
    }
    i++
    if (!results[j]) results[j] = []
    results[j].push(p)
  })

  return results
}

/**
 * 求线段最长的两点
 * @param {Array} positions [[lng, lat],[lng, lat]]
 * @TODO 希望有更优的算法解决
 */
export function maxLineSegment(positions) {
  let index = 0

  // 所有节点按照中心点夹角 的象限分布，进行顺时针排序
  const features = turf.featureCollection(positions.map(item => turf.point(item)))
  const absoluteCenter = turf.center(features).geometry.coordinates

  const angle = (item) => {
    let point1 = turf.point(item[0])
    let point2 = turf.point(item[1])
    return turf.bearing(point1, point2)
  }

  let positions1 = positions.filter((p) => {
    const degree = angle([absoluteCenter, p])
    return degree <= 0
  })

  positions1.sort((p, n) => {
    const degree = angle([absoluteCenter, p])
    const degree2 = angle([absoluteCenter, n])

    return Math.abs(degree) - Math.abs(degree2)
  })

  let positions2 = positions.filter((p) => {
    const degree = angle([absoluteCenter, p])
    return degree > 0
  })

  positions2.sort((p, n) => {
    const degree = angle([absoluteCenter, p])
    const degree2 = angle([absoluteCenter, n])
    return degree2 - degree
  })

  positions = [...positions1, ...positions2]

  // 获取相邻节点数组
  // [0, 1, 2, 3, 4, 5] => [[0,1], [1,2], [2,3], [3,4], [4,5], [5,0]]
  let adjacentPoints = [
    ...positions.reduce((p, n) => {
      if (!p[index]) p[index] = []
      if (index > 0) {
        p[index].push(p[index - 1][1])
      }
      p[index].push(n)
      if (p[index].length === 2) index++
      return p
    }, []),
    [positions[positions.length - 1], positions[0]]
  ]

  // 获取1/2/3象限的集合
  // const quadrant34 = adjacentPoints.filter((points) => {
  //   return points.every((point) => {
  //     const degree = angle([absoluteCenter, point])

  //     console.log(degree)
  //     return (-180 <= degree && degree <= 0) || (90 <= degree && degree <= 180)
  //   })
  // })

  // console.log(quadrant34)

  // 获取两点间距离
  const distances = adjacentPoints.map((item) => {
    return turf.rhumbDistance(turf.point(item[0]), turf.point(item[1]))
  })

  // 获取数组最大值下标
  const maxIndex = distances.indexOf(Math.max.apply(Math, distances))

  return {
    adjacentPoints,
    absoluteCenter,
    maxPoints: adjacentPoints[maxIndex]
  }
}

export function getHeading(point1, point2) {
  let p1 = ZK.Transform.transformWGS84ToCartesian(new ZK.Position(...point1))
  let p2 = ZK.Transform.transformWGS84ToCartesian(new ZK.Position(...point2))

  // let angle = (Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180) / Math.PI;
  let localToWorldMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(p1)
  //求世界坐标到局部坐标的变换矩阵
  let worldToLocalMatrix = Cesium.Matrix4.inverse(localToWorldMatrix, new Cesium.Matrix4())
  //a点在局部坐标的位置，其实就是局部坐标原点

  let s1 = Cesium.Matrix4.multiplyByPoint(worldToLocalMatrix, p1, new Cesium.Cartesian3())
  //B点在以A点为原点的局部的坐标位置
  let s2 = Cesium.Matrix4.multiplyByPoint(worldToLocalMatrix, p2, new Cesium.Cartesian3())
  //弧度
  let angle = Math.atan2(s2.y - s1.y, s2.x - s1.x)
  //角度
  let theta = angle * (180 / Math.PI)
  return 180 - theta
}

export default {
  cartesian3ToLonLat,
  makeCartesian2,
  getEntityPolygonCenter,
  fourCornersByCenter,
  arrayHeight2LngLatAry,
  maxLineSegment
}
