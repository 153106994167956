import { isArray } from '@/utils/util'
import cesiumUtil from '@/utils/cesiumUtil'
import billboardImage from '@/assets/images/model/point/circle_center.png'
import locationIcon from '@/assets/images/model/icon-location.png'
import labelLine from '@/assets/images/model/label-line.png'
import labelLine3 from '@/assets/images/model/label-line2.png'
import ZK from '@gis/zk-base'
// import labelLine2 from '@/assets/images/model/line.png'

import cardon from '@/assets/images/model/cardon.svg'

import Polyine from './Tools/Polyline'
import Particle from './Tools/Particle'

// import Parabola from './Tools/Parabola'

// import leadLine from '@/assets/images/preliminary/DisasterLevelSetting/leadLine.png'

import MonitorListener from './Tools/MonitorListener'

// import LargestRect from './Tools/LargestRect'

function drawText(style) {
  return new Promise((resolve) => {
    const text = style.text
    let width = (text + '').length * style.fontSize
    let height = style.fontSize

    let realWidth = style.vertical ? height : width
    let realHeight = style.vertical ? width : height

    const c1 = document.createElement('canvas')

    c1.width = realWidth
    c1.height = realHeight

    const ctx1 = c1.getContext('2d')
    ctx1.fillStyle = style.color
    ctx1.font = 'bold ' + style.fontSize + 'px 微软雅黑'

    // 绘制文本
    if (style.vertical) {
      ctx1.textBaseline = 'top'
      ctx1.fillTextVertical(text, realWidth / 2, 0, true)
      ctx1.fillTextVertical(text, realWidth / 2, 2, true)
    } else {
      ctx1.textBaseline = 'hanging'
      ctx1.fillStyle = style.color
      ctx1.fillText(text, 2, 0)
      ctx1.fillText(text, 2, 2)
    }

    c1.toBlob((blob) => {
      let img = document.createElement('img')
      img.src = window.URL.createObjectURL(blob)
      img.onload = () => {
        resolve(img)
      }
    })
  })
}

export default class EntityGenerate {
  constructor(viewerData, __tracker) {
    this.__viewer = viewerData
    this.__tracker = __tracker

    this.__layer = new ZK.PrimitiveLayer('layerEntity')
    this.__viewer.addLayer(this.__layer)

    this.__monitor = new MonitorListener(this.__viewer)
  }

  // 文本换行, 超过5个字换行
  _linefe(text) {
    let i = 0
    return text
      .split('')
      .reduce((pre, cur) => {
        const content = pre + cur
        if (i === 15) {
          i = 0
          return content + '\n'
        }
        i++
        return content
      }, '')
      .trim()
  }

  _show(entitys) {
    this.__handlerEntity(entitys, entity => entity.show = true)
  }

  _hide(entitys) {
    this.__handlerEntity(entitys, entity => entity.show = false)
  }

  _remove(entitys) {
    this.__handlerEntity(entitys, (entity) => {
      this.__viewer.entities.remove(entity)
    })
  }

  __handlerEntity(entitys, callback) {
    if (isArray(entitys)) {
      for (const entity of entitys) {
        // eslint-disable-next-line callback-return
        callback(entity)
      }
      return
    }
    callback(entitys)
  }

  __formatPostion(position, height = 0) {
    const viewer = this.__viewer

    return position.map((p) => {
      const { lon, lat } = cesiumUtil.cartesian3ToLonLat(viewer, new Cesium.Cartesian3(p.x, p.y, p.z))

      return Cesium.Cartesian3.fromDegrees(lon, lat, height)
    })
  }

  /**
   * @description: 生成点
   * @param {String} objId
   * @param {Cesium.Cartesian3} position
   * @return {Cesium.Entity}
   */
  point(objId, position, options = {}) {
    options.distanceDisplayCondition = options.distanceDisplayCondition || [0, 2000]

    const entity = this.__viewer.entities.add({
      objId,
      shapeType: 'Billboard',
      position,
      billboard: {
        image: options.icon || billboardImage,
        width: options.iconWidth || options.size || null,
        height: options.iconHeight || options.size || null,
        pixelOffset: options.pixelOffset || new Cesium.Cartesian2(0, -40),

        // 随相机距离改变的缩放参数
        // scaleByDistance: new Cesium.NearFarScalar(200, 1, 500, 0.8),
        // 随相机距离显隐参数
        // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(options.distanceDisplayCondition),
        // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(200, 0, 500, 0.5),

        disableDepthTestDistance: Number.POSITIVE_INFINITY
      }
    })

    let entitys = [entity]

    // 如果有描述
    // if (options.description) {
    //   entitys.push(this.genDescription(objId, position, options.description))
    // }

    return {
      entity: entitys,
      remove: () => this._remove(entitys),
      show: () => this._show(entitys),
      hide: () => this._hide(entitys)
    }
  }

  text(objId, position, options = {}) {
    options.text = options.text || ''
    options.size = options.size || 13
    options.color = options.color || '#fff'
    options.showBackground = options.showBackground || true
    options.backgroundColor = options.backgroundColor || '#4a90e2'

    // options.showPoint = options.showPoint || true
    options.pointIcon = options.pointIcon || locationIcon

    const point = this.point(objId, position, { icon: options.pointIcon }).entity

    const labelOptions = {
      text: this._linefe(options.text),
      font: options.size + 'px Microsoft YaHei',
      fillColor: Cesium.Color.fromCssColorString(options.color).withAlpha(1),
      outlineWidth: 0,
      showBackground: options.showBackground,
      style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      pixelOffset: new Cesium.Cartesian2(0, -80),
      horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
      // scaleByDistance: new Cesium.NearFarScalar(500, 1, 1000, 0.5),
      // 随相机距离显隐参数
      // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 2000),
      pixelOffsetScaleByDistance: new Cesium.NearFarScalar(500, 1, 1000, 0.5)
    }

    if (options.showBackground) {
      labelOptions.backgroundColor = Cesium.Color.fromCssColorString(options.backgroundColor).withAlpha(1)
    }

    const label = this.__viewer.entities.add({
      objId,
      shapeType: 'Label',
      position,
      label: labelOptions
    })

    const entitys = [point, label]

    return {
      entity: entitys,
      remove: () => this._remove(entitys),
      show: () => this._show(entitys, true),
      hide: () => this._hide(entitys, false)
    }
  }

  textLabel(objId, position, options = {}) {
    options.text = options.text || ''
    options.size = options.size || 13
    options.labelStyle = options.labelStyle || Cesium.LabelStyle.FILL, options.color = options.color || '#292d31'
    options.showBackground = options.showBackground || true
    options.backgroundColor = options.backgroundColor || '#12f4ff'
    options.backgroundColorAlpha = options.backgroundColorAlpha || 1

    options.showPoint = options.showPoint || true
    options.pointIconWidth = options.pointIconWidth || 30
    options.pointIconHeight = options.pointIconHeight || 40
    options.pointIcon = options.pointIcon || labelLine
    options.pointPixelOffset = options.pointPixelOffset || [0, 0]
    options.horizontalOrigin = options.horizontalOrigin || 'CENTER'
    options.verticalOrigin = options.verticalOrigin || 'CENTER'
    options.labelPixeOffset = options.labelPixeOffset || [0, -(options.pointIconHeight + Math.abs(options.pointPixelOffset[1]))]
    options.distanceDisplayCondition = options.distanceDisplayCondition || [0, 2000]

    let entityOptions = {
      objId,
      shapeType: 'Label',
      position,
      label: {
        text: this._linefe(options.text),
        font: options.size + 'px Microsoft YaHei',
        fillColor: Cesium.Color.fromCssColorString(options.color).withAlpha(1),
        outlineWidth: 0,
        showBackground: true,
        style: options.labelStyle,
        pixelOffset: new Cesium.Cartesian2(...options.labelPixeOffset),
        horizontalOrigin: Cesium.HorizontalOrigin[options.horizontalOrigin],
        verticalOrigin: Cesium.VerticalOrigin[options.verticalOrigin],
        // 随相机距离显隐参数
        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(...options.distanceDisplayCondition),
        disableDepthTestDistance: Number.POSITIVE_INFINITY
      }
    }
    // const keys = ['/car/', 'human']
    // const labelShown = keys.some((key) => {
    //   return options.modelUrl?.includes(key)
    // })
    // if (labelShown) {
    //   entityOptions.label.text = this._linefe(options.text)
    // }
    if (options.showBackground) {
      entityOptions.label.backgroundColor = Cesium.Color.fromCssColorString(options.backgroundColor).withAlpha(options.backgroundColorAlpha)
    }

    if (options.showPoint) {
      entityOptions.billboard = {
        image: options.pointIcon,
        width: options.pointIconWidth,
        height: options.pointIconHeight,
        pixelOffset: new Cesium.Cartesian2(...options.pointPixelOffset),
        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(...options.distanceDisplayCondition),
        disableDepthTestDistance: Number.POSITIVE_INFINITY
      }
    }

    const entity = this.__viewer.entities.add(entityOptions)
    this.__monitor.joinMonitor(
      {
        entity,
        position,
        shelter() {
          entity.label.backgroundColor = Cesium.Color.fromCssColorString(options.backgroundColor).withAlpha(0.3)
          entity.billboard.color = Cesium.Color.fromCssColorString('#fff').withAlpha(0.3)
        },
        unshelter() {
          entity.label.backgroundColor = Cesium.Color.fromCssColorString(options.backgroundColor).withAlpha(1)
          entity.billboard.color = Cesium.Color.fromCssColorString('#fff').withAlpha(1)
        }
      },
      'ray'
    )

    return {
      entity,
      remove: () => {
        this._remove(entity)
        this.__monitor.clearItemEntity(entity, 'ray')
      },
      show: () => this._show(entity, true),
      hide: () => this._hide(entity, false)
    }
  }

  textLabel2(objId, position, options) {
    options.text = options.text || ''
    options.size = options.size || 13
    options.color = options.color || '#292d31'
    options.showBackground = options.showBackground || true
    options.backgroundColor = options.backgroundColor || '#12f4ff'

    options.pointIcon = options.pointIcon || labelLine

    let label = this.__viewer.entities.add({
      objId,
      shapeType: 'Label',
      position,
      show: true,
      billboard: {
        image: options.pointIcon,
        width: options.pointIconWidth || 116,
        height: options.pointIconHeight || 42,
        color: Cesium.Color.fromCssColorString(options.backgroundColor).withAlpha(1),
        pixelOffset: options.pixelOffset || new Cesium.Cartesian2(70, -30),
        // scaleByDistance: new Cesium.NearFarScalar(600, 1, 1500, 0.3),
        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 2000),
        disableDepthTestDistance: Number.POSITIVE_INFINITY
      },
      label: {
        text: this._linefe(options.text),
        font: options.size + 'px Microsoft YaHei',
        fillColor: Cesium.Color.fromCssColorString(options.color).withAlpha(1),
        outlineWidth: 0,
        showBackground: true,
        style: Cesium.LabelStyle.FILL,
        pixelOffset: new Cesium.Cartesian2(110, -60),
        // scaleByDistance: new Cesium.NearFarScalar(600, 1, 1500, 0.3),
        // 随相机距离显隐参数
        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 2000),
        backgroundColor: Cesium.Color.fromCssColorString(options.backgroundColor).withAlpha(1),
        disableDepthTestDistance: Number.POSITIVE_INFINITY
      }
    })
    this.__monitor.joinMonitor(
      {
        entity: label,
        position,
        shelter() {
          label.label.backgroundColor = Cesium.Color.fromCssColorString(options.backgroundColor).withAlpha(0.5)
          label.billboard.color = Cesium.Color.fromCssColorString(options.backgroundColor).withAlpha(0.5)
        },
        unshelter() {
          label.label.backgroundColor = Cesium.Color.fromCssColorString(options.backgroundColor).withAlpha(1)
          label.billboard.color = Cesium.Color.fromCssColorString(options.backgroundColor).withAlpha(1)
        }
      },
      'ray'
    )
    return {
      entity: label,
      remove: () => {
        this._remove(label)
        this.__monitor.clearItemEntity(label, 'ray')
      },
      show: () => this._show(label, true),
      hide: () => this._hide(label, false)
    }
  }

  /**
   * @description: 生成多边形
   * @param {String} objId
   * @param {Cesium.Cartesian3} position
   * @return {Cesium.Entity}
   */
  polygon(objId, positions, options = {}) {
    const viewer = this.__viewer
    options.backgroundColor = options.backgroundColor || '#ff0'
    options.backgroundOpacity = options.backgroundOpacity || 0.2
    options.lineColor = options.lineColor || '#ff0'

    const material = Cesium.Color.fromCssColorString(options.backgroundColor).withAlpha(options.backgroundOpacity)

    const outlineMaterial = new Cesium.PolylineGlowMaterialProperty({
      glowPower: 0.25,
      color: Cesium.Color.fromCssColorString(options.lineColor).withAlpha(0.7)
    })

    const outlinePositions = [].concat([...positions, positions[0]])

    const entitys = []

    if (options.label && !options.stereogram) {
      const centerPosition = cesiumUtil.getEntityPolygonCenter(positions)
      entitys.push(
        this.textLabel2(objId, centerPosition, {
          ...options.label
          // text: options.description || options.label.text
        }).entity
      )
    }

    const entity = viewer.entities.add({
      objId,
      shapeType: 'Polygon',
      polyline: {
        positions: outlinePositions,
        clampToGround: true,
        width: 2,
        material: outlineMaterial
      },
      polygon: new Cesium.PolygonGraphics({
        hierarchy: positions,
        asynchronous: false,
        material,
        perPositionHeight: options.stereogram
      })
    })

    entitys.push(entity)

    return {
      entity: entitys,
      remove: () => this._remove(entitys),
      show: () => this._show(entitys),
      hide: () => this._hide(entitys)
    }
  }

  //救生窗
  stereogram(objId, positions, options = {}) {
    return this.polygon(objId, positions, {
      ...options,
      stereogram: true,
      backgroundOpacity: 1
    })
  }

  /**
   * @description: 生成折线
   * @param {String} objId
   * @param {Cesium.Cartesian3} position
   * @return {Cesium.Entity}
   */
  line(objId, positions, options = {}) {
    const viewer = this.__viewer

    const polyline = new Polyine(viewer, positions, {
      objId,
      ...options
    })

    let entitys = [polyline.generate()]

    // 如果有描述
    // if (options.description) {
    //   const centerPosition = getEntityPolygonCenter(positions)
    //   entitys.push(this.genDescription(objId, centerPosition, options.description))
    // }

    return {
      entity: entitys,
      remove: () => this._remove(entitys),
      show: () => this._show(entitys),
      hide: () => this._hide(entitys)
    }
  }

  /**
   * @description: 生成矩形
   * @param {String} objId
   * @param {Cesium.Cartesian3} position
   * @return {Cesium.Entity}
   */
  rectangle(objId, positions) {
    const material = Cesium.Color.fromCssColorString('#ff0').withAlpha(0.5)
    const outlineMaterial = new Cesium.PolylineDashMaterialProperty({
      dashLength: 16,
      color: Cesium.Color.fromCssColorString('#00f').withAlpha(0.7)
    })
    const rect = Cesium.Rectangle.fromCartesianArray(positions)
    const arr = [rect.west, rect.north, rect.east, rect.north, rect.east, rect.south, rect.west, rect.south, rect.west, rect.north]

    const outlinePositions = Cesium.Cartesian3.fromRadiansArray(arr)

    const entity = this.__viewer.entities.add({
      objId,
      shapeType: 'Rectangle',
      polyline: {
        positions: outlinePositions,
        clampToGround: true,
        width: 2,
        material: outlineMaterial
      },
      rectangle: {
        coordinates: rect,
        material
      }
    })

    return {
      entity,
      remove: () => this._remove(entity),
      show: () => this._show(entity),
      hide: () => this._hide(entity)
    }
  }

  /**
   * @description: 生成圆形
   * @param {String} objId
   * @param {Cesium.Cartesian3} position
   */
  circle(objId, positions) {
    const material = Cesium.Color.fromCssColorString('#ff0').withAlpha(0.5)
    // const outlineMaterial = new Cesium.PolylineDashMaterialProperty({
    //   dashLength: 16,
    //   color: Cesium.Color.fromCssColorString('#f00').withAlpha(0.7)
    // })
    const radiusMaterial = new Cesium.PolylineDashMaterialProperty({
      dashLength: 16,
      color: Cesium.Color.fromCssColorString('#00f').withAlpha(0.7)
    })
    const pnts = this.__tracker.circleDrawer._computeCirclePolygon(positions)
    const dis = this.__tracker.circleDrawer._computeCircleRadius3D(positions)

    // 圆圈
    const entity = this.__viewer.entities.add({
      objId,
      shapeType: 'Circle',
      position: positions[0],
      label: {
        text: (dis / 1000).toFixed(3) + 'km',
        font: '16px Helvetica',
        fillColor: Cesium.Color.SKYBLUE,
        outlineColor: Cesium.Color.BLACK,
        outlineWidth: 1,
        style: Cesium.LabelStyle.FILL_AND_OUTLINE,
        eyeOffset: new Cesium.ConstantProperty(new Cesium.Cartesian3(0, 0, -9000)),
        pixelOffset: new Cesium.Cartesian2(16, 16)
      },
      billboard: {
        image: billboardImage
        // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
      },
      polyline: {
        positions,
        clampToGround: true,
        width: 2,
        material: radiusMaterial
      },
      polygon: new Cesium.PolygonGraphics({
        hierarchy: pnts,
        asynchronous: false,
        material
      })
    })

    // 圆圈虚线
    // this.__viewer.entities.add({
    //
    //   objId,
    //   shapeType: 'Circle',
    //   polyline: {
    //     positions: pnts,
    //     clampToGround: true,
    //     width: 2,
    //     material: outlineMaterial
    //   }
    // })

    return {
      entity,
      remove: () => this._remove(entity),
      show: () => this._show(entity),
      hide: () => this._hide(entity)
    }
  }

  /**
   * @description: 生成直线箭头
   * @param {String} objId
   * @param {Cesium.Cartesian3} position
   * @return {Cesium.Entity}
   */
  straightArrow(objId, positions, options) {
    options.backgroundColor = options.backgroundColor || '#ff0'

    const material = Cesium.Color.fromCssColorString(options.backgroundColor).withAlpha(0.5)

    const outlineMaterial = new Cesium.PolylineDashMaterialProperty({
      dashLength: 16,
      color: Cesium.Color.fromCssColorString('#fff').withAlpha(0.7)
    })

    const outlinePositions = [].concat([...positions, positions[0]])

    const entitys = [],
      viewer = this.__viewer

    entitys.push(
      viewer.entities.add({
        objId,
        shapeType: 'StraightArrow',
        polyline: {
          positions: outlinePositions,
          clampToGround: true,
          width: 2,
          material: outlineMaterial
        },
        polygon: new Cesium.PolygonGraphics({
          hierarchy: positions,
          asynchronous: false,
          material
        })
      })
    )

    let point2 = []
    positions.forEach((item) => {
      const { lon, lat } = cesiumUtil.cartesian3ToLonLat(viewer, item)
      point2.push(turf.point([lon, lat]))
    })

    const features = turf.featureCollection(point2)
    const center1 = turf.center(features).geometry.coordinates

    let textEntity

    const coordinates = (degree) => {
      const w = 5,
        h = 5
      return turf.rhumbDestination(turf.point([center1[0], center1[1]]), Math.sqrt((h / 2) ** 2 + (options.text.length * w / 2) ** 2) / 6000, degree).geometry.coordinates
    }

    const polygon2 = [coordinates(15), coordinates(-15), coordinates(-165), coordinates(165)]

    const hierarchy2 = polygon2.map((item) => {
      return Cesium.Cartesian3.fromDegrees(...item)
    })

    const angle = () => {
      const { lon, lat } = cesiumUtil.cartesian3ToLonLat(viewer, positions[3])
      let point1 = turf.point([lon, lat])
      let point2 = turf.point(center1)

      return 90 - turf.bearing(point1, point2)
    }

    drawText({
      fontSize: 50,
      text: options.text,
      color: '#fff'
    }).then((image) => {
      textEntity = viewer.entities.add({
        objId: '',
        rectangle: {
          coordinates: Cesium.Rectangle.fromCartesianArray(hierarchy2),
          material: new Cesium.ImageMaterialProperty({
            image,
            transparent: true,
            color: Cesium.Color.WHITE
          }),
          rotation: Cesium.Math.toRadians(angle()),
          stRotation: Cesium.Math.toRadians(90 + angle()),
          classificationType: Cesium.ClassificationType.BOTH
        }
      })
      entitys.push(textEntity)
    })

    return {
      entity: entitys,
      remove: () => this._remove(entitys),
      show: () => this._show(entitys),
      hide: () => this._hide(entitys)
    }
  }

  /**
   * @description: 生成攻击箭头
   * @param {String} objId
   * @param {Cesium.Cartesian3} position
   * @return {Cesium.Entity}
   */
  attackArrow(objId, positions) {
    const material = Cesium.Color.fromCssColorString('#ff0').withAlpha(0.5)

    const outlineMaterial = new Cesium.PolylineDashMaterialProperty({
      dashLength: 16,
      color: Cesium.Color.fromCssColorString('#f00').withAlpha(0.7)
    })

    const outlinePositions = [].concat([...positions, positions[0]])

    const entity = this.__viewer.entities.add({
      objId,
      shapeType: 'AttackArrow',
      polyline: {
        positions: outlinePositions,
        clampToGround: true,
        width: 2,
        material: outlineMaterial
      },
      polygon: new Cesium.PolygonGraphics({
        hierarchy: positions,
        asynchronous: false,
        material
      })
    })

    return {
      entity,
      remove: () => this._remove(entity),
      show: () => this._show(entity),
      hide: () => this._hide(entity)
    }
  }

  /**
   * @description: 生成钳形箭头
   * @param {String} objId
   * @param {Cesium.Cartesian3} position
   * @return {Cesium.Entity}
   */
  pincerArrow(objId, positions) {
    const material = Cesium.Color.fromCssColorString('#ff0').withAlpha(0.5)
    const outlineMaterial = new Cesium.PolylineDashMaterialProperty({
      dashLength: 16,
      color: Cesium.Color.fromCssColorString('#f00').withAlpha(0.7)
    })

    const outlinePositions = [].concat([...positions, positions[0]])

    const entity = this.__viewer.entities.add({
      objId,
      shapeType: 'PincerArrow',
      polyline: {
        positions: outlinePositions,
        clampToGround: true,
        width: 2,
        material: outlineMaterial
      },
      polygon: new Cesium.PolygonGraphics({
        hierarchy: positions,
        asynchronous: false,
        material
      })
    })

    return {
      entity,
      remove: () => this._remove(entity),
      show: () => this._show(entity),
      hide: () => this._hide(entity)
    }
  }

  pointModel(objId, position, options = {}) {
    let entitys = [],
      viewer = this.__viewer

    options.rotate = options.rotate || 0

    // const pointPixelOffset = options.pointPixelOffset || [0, 0]

    const textLabel = this.textLabel(objId, position, {
      ...options,
      // pointIcon: leadLine,
      // pointIconWidth: 20,
      // pointIconHeight: 100,
      // pointPixelOffset: [pointPixelOffset[0], -(Math.abs(pointPixelOffset[1]) + 50)],
      // labelPixeOffset: [pointPixelOffset[0], -(Math.abs(pointPixelOffset[1]) + 115)],
      // 避免重复生成描述
      description: null
    }).entity

    entitys.push(textLabel)

    // const hintPop = this.genDescription(position, options, options.text)

    let waterEntitys
    // 如果有模型

    if (options.modelUrl) {
      let model = viewer.entities.add({
        objId,
        show: true,
        shapeType: 'pointModel',
        model: {
          uri: options.modelUrl,
          scale: options.scale || 1
        },
        position,
        orientation: Cesium.Transforms.headingPitchRollQuaternion(position, new Cesium.HeadingPitchRoll(Cesium.Math.toRadians(options.rotate), 0.0, 0.0))
      })
      entitys.push(model)

      //如果消防车喷水
      const waterGunData = options.waterGunData
      if (waterGunData) {
        const { lon, lat } = cesiumUtil.cartesian3ToLonLat(viewer, new Cesium.Cartesian3(position.x, position.y, position.z))

        waterEntitys = new ZK.FountainParticle([lon, lat])
        waterEntitys.setStyle(waterGunData)
        this.__layer.addOverlay(waterEntitys, {
          speed: 100
        })
        // waterEntitys = new Parabola(viewer, [water[0], water[1]], { objId })
        // entitys = [...entitys, ...waterEntitys.entitys]
      }
      //如果有连接水带
      const waterPipe = options.waterPipePositions
      if (waterPipe && waterPipe.length) {
        let itemPosition = waterPipe.map((item) => {
          return { x: item.x, y: item.y, z: item.z }
        })
        entitys.push(
          new Polyine(viewer, itemPosition, {
            objId,
            width: 6,
            color: '#fff',
            type: 'default',
            clampToGround: true
          }).generate()
        )
      }
    }

    // 如果有描述
    // if (options.description) {
    //   entitys.push(this.genDescription(objId, position, options.description))
    // }

    return {
      entity: entitys,
      show: () => this._show(entitys),
      hide: () => this._hide(entitys),
      remove: () => {
        this._remove(entitys)
        console.log(1)
        this.__layer.removeOverlay(waterEntitys)
      }
    }
  }

  fire(objId, position, options = {}) {
    const { entity, particle } = new Particle(this.__viewer, position, {
      id: objId,
      ...options
    })

    let entitys = [entity]

    // let labelEntity
    // if (options.description) {
    //   const pointPixelOffset = options.pointPixelOffset || [0, 0]
    //   entitys.push(
    //     this.textLabel(objId, position, {
    //       text: options.description,
    //       pointIcon: leadLine,
    //       pointIconWidth: 20,
    //       pointIconHeight: 100,
    //       pointPixelOffset: [pointPixelOffset[0], -(Math.abs(pointPixelOffset[1]) + 50)],
    //       labelPixeOffset: [pointPixelOffset[0], -(Math.abs(pointPixelOffset[1]) + 115)],
    //       // 避免重复生成描述
    //       description: null
    //     }).entity
    //   )
    // }

    return {
      entity: entitys,
      particle,
      remove: () => {
        this._remove(entitys)
        this.__viewer.scene.primitives.remove(particle)
      },
      show: () => this._show([...entitys, particle]),
      hide: () => this._hide([...entitys, particle])
    }
  }

  //立面标注墙体绘制
  wallZoneItem(objId, positions, options) {
    let entitys = [],
      viewer = this.__viewer
    let { backgroundColor, text, minimumHeights } = options

    // let completeLabel = label ? `${text}: ${label}` : text
    let completeLabel = text

    // 墙体实际高度
    const polygonHeight = positions[2] - minimumHeights[0]

    // 绘制当前墙体
    const itemEntity = viewer.entities.add({
      objId,
      polygon: {
        perPositionHeight: true,
        extrudedHeight: minimumHeights[0],
        hierarchy: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
        material: Cesium.Color.fromCssColorString(backgroundColor).withAlpha(0.5)
      }
    })

    entitys.push(itemEntity)

    if (completeLabel) {
      let newPositions = cesiumUtil.arrayHeight2LngLatAry(positions)
      const { adjacentPoints } = cesiumUtil.maxLineSegment(newPositions)

      // 固定文字的宽度
      let textSize = 3,
        textWidth = ('' + completeLabel).length * textSize
      const proportion = textSize / textWidth

      adjacentPoints.forEach((points) => {
        const turfPoints = points.map(item => turf.point(item))

        // 顶点间距离（米）
        const distance = turf.distance(...turfPoints) * 1000

        if (distance <= 0) return

        /* 计算label宽高 */
        // 墙面宽高比例
        let proportion2 = polygonHeight / distance
        let labelHeight, labelWidth
        let isVertical = proportion2 > 1
        // 竖排
        if (isVertical) {
          labelHeight = polygonHeight > textWidth ? textWidth : polygonHeight
          labelWidth = labelHeight * proportion

          if (distance < labelWidth) {
            labelWidth = distance
            labelHeight = distance / proportion
          }
        } else {
          // 横排
          labelWidth = distance > textWidth ? textWidth : distance
          labelHeight = labelWidth * proportion

          if (polygonHeight < labelHeight) {
            labelHeight = polygonHeight
            labelWidth = polygonHeight / proportion
          }
        }

        /**
         * 由中心点出发，向两边平移得到实际墙面的底部顶点
         */

        // 两点的绝对中心点
        const absoluteCenter = turf.center(turf.featureCollection(turfPoints)).geometry.coordinates

        // 平移的宽度（公里）
        let width = labelWidth / 2 / 1000
        const newPoints = points.map((ponit) => {
          // 获取中心点与原顶点间夹角，得到实际平移的方位（角度）
          const bearing = turf.bearing(...[absoluteCenter, ponit].map(item => turf.point(item)))
          return turf.transformTranslate(turf.point(absoluteCenter), width, bearing).geometry.coordinates
        })

        // wall实体实际高度为（离地面高度+墙体高度）
        let newMinimumHeights = [0, 0].map(() => (polygonHeight - labelHeight) / 2 + minimumHeights[0])
        labelHeight += newMinimumHeights[0]

        drawText({
          fontSize: 100,
          text: completeLabel,
          color: '#fff',
          vertical: isVertical
        }).then((image) => {
          const entity = viewer.entities.add({
            objId: '',
            wall: {
              positions: Cesium.Cartesian3.fromDegreesArrayHeights([...newPoints[0], labelHeight, ...newPoints[1], labelHeight]),
              material: new Cesium.ImageMaterialProperty({
                image,
                transparent: true,
                color: Cesium.Color.WHITE
              }),
              minimumHeights: newMinimumHeights,
              distanceDisplayCondition: 10
            }
          })

          entitys.push(entity)
        })
      })
    }

    return {
      entity: entitys,
      hide: () => this._hide(entitys),
      show: () => this._show(entitys),
      remove: () => this._remove(entitys)
    }
  }

  wallGeometry(objId, positions, options) {
    const viewer = this.__viewer
    let newPositions
    //-----内建的wall模型,按照position来分配uv的规则观察可知:点与点之间均分uv,和点的位置没有关系
    newPositions = positions.map((position) => {
      const { lon, lat } = cesiumUtil.cartesian3ToLonLat(viewer, position)
      return [lon, lat, 20]
    })

    newPositions = [...newPositions, newPositions[0]]

    let geometry = Cesium.WallGeometry.createGeometry(
      new Cesium.WallGeometry({
        positions: Cesium.Cartesian3.fromDegreesArrayHeights(newPositions.flat())
      })
    )

    let material = new Cesium.Material({
      fabric: {
        type: 'cardon',
        uniforms: {
          // color: Cesium.Color.fromCssColorString('#ff6565').withAlpha(1),
          color: new Cesium.Color(1.0, 2.0, 1.0, 1.0),
          image: cardon,
          time: 0,
          repeat: 10
        },
        source: `czm_material czm_getMaterial(czm_materialInput materialInput)
        {
            czm_material material = czm_getDefaultMaterial(materialInput);
            vec2 st = materialInput.st;
            vec4 colorImage = texture2D(image, vec2(fract(st.s*repeat - time), st.t));
            material.alpha = colorImage.a * color.a;
            material.diffuse = colorImage.rgb*color.rgb;
            return material;
        }`
      }
    })

    let rectangle = viewer.scene.primitives.add(
      new Cesium.Primitive({
        geometryInstances: new Cesium.GeometryInstance({
          geometry,
          id: objId
        }),
        appearance: new Cesium.EllipsoidSurfaceAppearance({
          aboveGround: false,
          renderState: {
            cull: {
              enabled: false
            }
          }
        }),
        asynchronous: false
      })
    )

    rectangle.appearance.material = material

    viewer.scene.preUpdate.addEventListener(() => {
      material.uniforms.time = material.uniforms.time + 50 * 0.0001
    })
    let entitys = []
    if (options.label) {
      entitys.push(
        this.textLabel2(objId, positions[0], {
          ...options.label
        }).entity
      )
    }

    // let hintPop
    // if (options.description) {
    //   entitys.push(this.genDescription(objId, positions[0], options.description))
    // }

    return {
      entity: [...entitys, rectangle],
      remove: () => {
        viewer.scene.primitives.remove(rectangle)
        this._remove(entitys)
        // hintPop && hintPop.destroy()
      },
      show: () => {
        rectangle.show = true
        // hintPop && hintPop.show()
        this._show(entitys)
      },
      hide: () => {
        rectangle.show = false
        // hintPop && hintPop.hide()
        this._hide(entitys)
      }
    }
  }

  measureDraw(objId, positions, options = {}) {
    const viewer = this.__viewer

    options.text = options.text || ''
    options.size = options.size || 16
    options.color = options.color || '#fff'
    options.showBackground = options.showBackground || true
    options.backgroundColor = options.backgroundColor || '#0027ac'
    options.backgroundOpacity = options.backgroundOpacity || 0.8

    options.distanceDisplayCondition = options.distanceDisplayCondition || [0, 2000]

    options.centerPosition = options.centerPosition || cesiumUtil.getEntityPolygonCenter(positions)

    if (options.type !== 'height') {
      positions = this.__formatPostion(positions, 2)
      options.centerPosition = this.__formatPostion([options.centerPosition], 2)[0]
    }

    const polyline = new Polyine(viewer, positions, {
      objId,
      width: 10,
      color: '#09ec3e',
      type: 'default',
      clampToGround: false,
      distanceDisplayCondition: options.distanceDisplayCondition
    })

    let entityData = {
      objId,
      show: true,
      // polyline: polyline.getOptions(),
      position: options.centerPosition,
      label: {
        text: options.text,
        font: options.size + 'px Microsoft YaHei',
        fillColor: Cesium.Color.fromCssColorString(options.color).withAlpha(1),
        outlineWidth: 0,
        showBackground: true,
        style: Cesium.LabelStyle.FILL,
        // pixelOffset: new Cesium.Cartesian2(110, -60),
        // scaleByDistance: new Cesium.NearFarScalar(200, 1, 500, 0.8),
        // 随相机距离显隐参数
        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(...options.distanceDisplayCondition),
        backgroundColor: Cesium.Color.fromCssColorString(options.backgroundColor).withAlpha(0.4),
        disableDepthTestDistance: Number.POSITIVE_INFINITY
      }
    }

    // 面积绘制面
    if (options.type === 'area') {
      entityData.polygon = new Cesium.PolygonGraphics({
        hierarchy: positions,
        asynchronous: false,
        material: Cesium.Color.fromCssColorString(options.backgroundColor).withAlpha(options.backgroundOpacity)
      })
    }

    let entity = viewer.entities.add(entityData)

    let points = [positions[0], positions[positions.length - 1]].map((position) => {
      return viewer.entities.add({
        objId,
        position,
        point: {
          color: Cesium.Color.SKYBLUE,
          pixelSize: 10,
          outlineColor: Cesium.Color.YELLOW,
          outlineWidth: 3
        }
      })
    })

    let entitys = [entity, polyline.generate(), ...points]

    return {
      entity: entitys,
      remove: () => this._remove(entitys),
      show: () => this._show(entitys),
      hide: () => this._hide(entitys)
    }
  }

  genDescription(objId, positions, description) {
    return this.textLabel(objId, positions, {
      text: description,
      pointIcon: labelLine,
      pointIconWidth: 110,
      pointIconHeight: 40,
      pointPixelOffset: [45, -40],
      backgroundColor: '#029ed9',
      labelPixeOffset: [62, -56],
      backgroundColorAlpha: 0.8,
      horizontalOrigin: 'LEFT',
      verticalOrigin: 'BOTTOM',
      color: '#fff'
    }).entity
  }

  /**
   * @description: 周边道路/疏散路线, 此处需要叠加两条路线
   * @param {String} objId
   * @param {Cesium.Cartesian3} position
   * @return {Cesium.Entity}
   */
  road(objId, positions, options = {}) {
    const viewer = this.__viewer

    const glowRoad = new Polyine(viewer, positions, {
      objId,
      type: 'arrow',
      width: 30,
      color: options.color || '#ffba49',
      zIndex: 0
    })
    //监听线条动态更改宽度
    // const listener = [flowRoad.modelHeightListener('flow'), glowRoad.modelHeightListener('glow')]

    // const entitys = [flowRoad.generate(), glowRoad.generate()]
    const entitys = [glowRoad.generate()]

    let center
    if (positions.length === 2) {
      center = cesiumUtil.getEntityPolygonCenter(positions)
    } else if (positions.length > 2) {
      center = positions[3] || positions[2]
    }

    if (options.text) {
      entitys.push(
        this.textLabel2(objId, center, {
          ...options,
          color: null,
          pointIcon: labelLine3,
          backgroundColor: '#f3b34d'
        }).entity
      )
    }

    return {
      entity: entitys,
      remove: () => this._remove(entitys),
      show: () => this._show(entitys),
      hide: () => this._hide(entitys)
    }
  }
}
