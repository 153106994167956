import { DELETE, POST, GET } from '@/utils/http'

const HOST = '/service-socialize-v2'

export default {
  // 获取企业信息
  getCompanyInfo(params) {
    return GET({ url: `${HOST}/socializeCompanyInfo/getCompany`, params })
  },

  // 获取标注菜单
  drawToolTree(params) {
    return GET({ url: `${HOST}/drawTool/tree`, params })
  },

  // 获取标注菜单
  getUnitDrawList(params) {
    return GET({ url: `${HOST}/drawInfo/unitDraw/list`, params })
  },

  //获取标注菜单-室外
  getUnitDrawListBak(params) {
    return GET({ url: `${HOST}/drawInfo/listByUnitIdAndRadius`, params })
  },

  // 获取已经标注好的信息
  getDrawList(params) {
    return GET({ url: `${HOST}/drawInfo/unitDraw/list`, params })
  },

  delDrawList(params) {
    return DELETE({ url: `${HOST}/drawInfo/unitDraw/delete/${params}` })
  },

  addDrawList(params) {
    return POST({ url: `${HOST}/drawInfo/add/unitDraw`, params })
  },

  rangeGet(params) {
    return GET({ url: `${HOST}/socialize/range/get`, params })
  },

  // 获取绘画工具列表
  getDrawToolList(params) {
    return GET({ url: `${HOST}/drawTool/list`, params })
  },

  deleteDrawData(params) {
    return DELETE({ url: `${HOST}/drawInfo/unitDraw/delete`, params })
  },

  // 建筑物树
  getbuildTreeData(params) {
    return GET({ url: `${HOST}/socialize/build/getBuildTree`, params })
  },

  // 内部模型库
  internalModelData(params) {
    return GET({ url: `${HOST}/modelInfo/list`, params })
  },

  statisByCaseId(params) {
    return GET({ url: `${HOST}/socialize/deploy/statisByCaseId`, params })
  },

  // 更新数据管理标绘的自定义config
  updateUnitDrawConfig(params) {
    return POST({ url: `${HOST}/drawInfo/update/unitDraw/config`, params })
  }
}
