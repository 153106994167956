import GlobeTooltip from './GlobeTooltip'

import CreateToolbar from './CreateToolbar'
var GlobeRectangleDrawer = function () {
  this.init.apply(this, arguments);
};

GlobeRectangleDrawer.prototype = {
  viewer: null,
  scene: null,
  clock: null,
  canvas: null,
  camera: null,
  ellipsoid: null,
  tooltip: null,
  entity: null,
  positions: [],
  drawHandler: null,
  modifyHandler: null,
  okHandler: null,
  cancelHandler: null,
  dragIconLight: require('@/assets/images/model/point/circle_red.png'),
  material: null,
  outlineMaterial: null,
  fill: true,
  outline: true,
  outlineWidth: 2,
  extrudedHeight: 0,
  toolBarIndex: null,
  layerId: "globeEntityDrawerLayer",
  init: function (viewer) {
    var _this = this;
    _this.viewer = viewer;
    _this.scene = viewer.scene;
    _this.clock = viewer.clock;
    _this.canvas = viewer.scene.canvas;
    _this.camera = viewer.scene.camera;
    _this.ellipsoid = viewer.scene.globe.ellipsoid;
    _this.tooltip = new GlobeTooltip(viewer.container);
  },
  clear: function () {
    var _this = this;
    if (_this.drawHandler) {
      _this.drawHandler.destroy();
      _this.drawHandler = null;
    }
    if (_this.modifyHandler) {
      _this.modifyHandler.destroy();
      _this.modifyHandler = null;
    }
    if (_this.toolBarIndex != null) {
      layer.close(_this.toolBarIndex);
    }
    _this._clearMarkers(_this.layerId);
    _this.tooltip.setVisible(false);
  },
  showModifyRectangle: function (positions, okHandler, cancelHandler) {
    var _this = this;
    _this.positions = positions;
    _this.okHandler = okHandler;
    _this.cancelHandler = cancelHandler;

    _this._showModifyRegion2Map();
    _this._startModify();
  },

  _getCartesian: function (event) {
    const _this = this
    const position = event.endPosition || event.position
    const target = _this.viewer.scene.pick(position)

    if (target) {
      return _this.scene.pickPosition(position)
    }

    const ray = _this.camera.getPickRay(position)

    return _this.scene.globe.pick(ray, _this.scene)
  },


  startDrawRectangle: function (okHandler, cancelHandler) {
    var _this = this;
    _this.okHandler = okHandler;
    _this.cancelHandler = cancelHandler;

    _this.positions = [];
    var floatingPoint = null;
    _this.drawHandler = new Cesium.ScreenSpaceEventHandler(_this.canvas);

    _this.drawHandler.setInputAction(function (event) {
      var position = event.position;
      if (!Cesium.defined(position)) {
        return;
      }
      var cartesian = _this._getCartesian(event)
      var num = _this.positions.length;
      if (num == 0) {
        _this.positions.push(cartesian);
        floatingPoint = _this._createPoint(cartesian, -1);
        _this._showRegion2Map();
      }
      _this.positions.push(cartesian);
      var oid = _this.positions.length - 2;
      _this._createPoint(cartesian, oid);
      if (num > 1) {
        _this.positions.pop();
        _this.viewer.entities.remove(floatingPoint);
        _this.tooltip.setVisible(false);
        _this._startModify();
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

    _this.drawHandler.setInputAction(function (event) {
      var position = event.endPosition;
      if (!Cesium.defined(position)) {
        return;
      }
      if (_this.positions.length < 1) {
        _this.tooltip.showAt(position, "<p>选择起点</p>");
        return;
      }
      _this.tooltip.showAt(position, "<p>选择终点</p>");

      var cartesian = _this._getCartesian(event)
      floatingPoint.position.setValue(cartesian);
      _this.positions.pop();
      _this.positions.push(cartesian);
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  },
  _startModify: function () {
    var _this = this;
    var isMoving = false;
    var pickedAnchor = null;
    if (_this.drawHandler) {
      _this.drawHandler.destroy();
      _this.drawHandler = null;
    }
    _this._showToolBar();

    _this.modifyHandler = new Cesium.ScreenSpaceEventHandler(_this.canvas);

    _this.modifyHandler.setInputAction(function (event) {
      var position = event.position;
      if (!Cesium.defined(position)) {
        return;
      }
      var cartesian = _this._getCartesian(event)
      if (isMoving) {
        isMoving = false;
        pickedAnchor.position.setValue(cartesian);
        var oid = pickedAnchor.oid;
        _this.positions[oid] = cartesian;
        _this.tooltip.setVisible(false);
      } else {
        var pickedObject = _this.scene.pick(position);
        if (!Cesium.defined(pickedObject)) {
          return;
        }
        if (!Cesium.defined(pickedObject.id)) {
          return;
        }
        var entity = pickedObject.id;
        if (entity.layerId != _this.layerId || entity.flag != "anchor") {
          return;
        }
        pickedAnchor = entity;
        isMoving = true;
        _this.tooltip.showAt(position, "<p>移动控制点</p>");
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

    _this.modifyHandler.setInputAction(function (event) {
      if (!isMoving) {
        return;
      }
      var position = event.endPosition;
      if (!Cesium.defined(position)) {
        return;
      }
      _this.tooltip.showAt(position, "<p>移动控制点</p>");

      var cartesian = _this._getCartesian(event)
      pickedAnchor.position.setValue(cartesian);
      var oid = pickedAnchor.oid;
      _this.positions[oid] = cartesian;
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  },
  _createPoint: function (cartesian, oid) {
    var _this = this;
    var point = _this.viewer.entities.add({
      position: cartesian,
      billboard: {
        image: _this.dragIconLight,
        // eyeOffset: new Cesium.ConstantProperty(new Cesium.Cartesian3(0, 0, -500)),
        // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
      }
    });
    point.oid = oid;
    point.layerId = _this.layerId;
    point.flag = "anchor";
    return point;
  },
  _showRegion2Map: function () {
    var _this = this;
    if (_this.material == null) {
      _this.material = Cesium.Color.fromCssColorString('#ff0').withAlpha(0.5);
    }
    if (_this.outlineMaterial == null) {
      _this.outlineMaterial = new Cesium.PolylineDashMaterialProperty({
        dashLength: 16,
        color: Cesium.Color.fromCssColorString('#00f').withAlpha(0.7)
      });
    }
    var dynamicPositions = new Cesium.CallbackProperty(function () {
      if (_this.positions.length > 1) {
        var rect = Cesium.Rectangle.fromCartesianArray(_this.positions);
        return rect;
      } else {
        return null;
      }
    }, false);
    var outlineDynamicPositions = new Cesium.CallbackProperty(function () {
      if (_this.positions.length > 1) {
        var rect = Cesium.Rectangle.fromCartesianArray(_this.positions);
        var arr = [rect.west, rect.north, rect.east, rect.north, rect.east, rect.south, rect.west, rect.south, rect.west, rect.north];
        var positions = Cesium.Cartesian3.fromRadiansArray(arr);
        return positions;
      } else {
        return null;
      }
    }, false);
    var bData = {
      rectangle: {
        coordinates: dynamicPositions,
        material: _this.material,
        show: _this.fill
      },
      polyline: {
        positions: outlineDynamicPositions,
        clampToGround: true,
        width: _this.outlineWidth,
        material: _this.outlineMaterial,
        show: _this.outline
      }
    };
    if (_this.extrudedHeight > 0) {
      bData.rectangle.extrudedHeight = _this.extrudedHeight;
      bData.rectangle.extrudedHeightReference = Cesium.HeightReference.RELATIVE_TO_GROUND;
      bData.rectangle.closeTop = true;
      bData.rectangle.closeBottom = true;
      bData.rectangle.outline = false;
      bData.rectangle.outlineWidth = 0;
    }
    _this.entity = _this.viewer.entities.add(bData);
    _this.entity.layerId = _this.layerId;
  },
  _showModifyRegion2Map: function () {
    var _this = this;
    if (_this.material == null) {
      _this.material = Cesium.Color.fromCssColorString('#ff0').withAlpha(0.5);
    }
    if (_this.outlineMaterial == null) {
      _this.outlineMaterial = new Cesium.PolylineDashMaterialProperty({
        dashLength: 16,
        color: Cesium.Color.fromCssColorString('#00f').withAlpha(0.7)
      });
    }
    var dynamicPositions = new Cesium.CallbackProperty(function () {
      if (_this.positions.length > 1) {
        var rect = Cesium.Rectangle.fromCartesianArray(_this.positions);
        return rect;
      } else {
        return null;
      }
    }, false);
    var outlineDynamicPositions = new Cesium.CallbackProperty(function () {
      if (_this.positions.length > 1) {
        var rect = Cesium.Rectangle.fromCartesianArray(_this.positions);
        var arr = [rect.west, rect.north, rect.east, rect.north, rect.east, rect.south, rect.west, rect.south, rect.west, rect.north];
        var positions = Cesium.Cartesian3.fromRadiansArray(arr);
        return positions;
      } else {
        return null;
      }
    }, false);
    var bData = {
      rectangle: {
        coordinates: dynamicPositions,
        material: _this.material,
        show: _this.fill
      },
      polyline: {
        positions: outlineDynamicPositions,
        clampToGround: true,
        width: _this.outlineWidth,
        material: _this.outlineMaterial,
        show: _this.outline
      }
    };
    if (_this.extrudedHeight > 0) {
      bData.rectangle.extrudedHeight = _this.extrudedHeight;
      bData.rectangle.extrudedHeightReference = Cesium.HeightReference.RELATIVE_TO_GROUND;
      bData.rectangle.closeTop = true;
      bData.rectangle.closeBottom = true;
      bData.rectangle.outline = false;
      bData.rectangle.outlineWidth = 0;
    }
    _this.entity = _this.viewer.entities.add(bData);
    _this.entity.layerId = _this.layerId;
    var positions = _this.positions;
    for (var i = 0; i < positions.length; i++) {
      _this._createPoint(positions[i], i);
    }
  },
  _computeRectangle: function (p1, p2) {
    var _this = this;
    var c1 = _this.ellipsoid.cartesianToCartographic(p1);
    var c2 = _this.ellipsoid.cartesianToCartographic(p2);
    var rect = Cesium.Rectangle.fromCartesianArray([p1, p2]);
    return rect;
  },
  _showToolBar: function () {
    var _this = this;
    _this._createToolBar();
    var width = $(window).width();
    var wTop = 60;
    var wLeft = parseInt((width - 145) / 2);
    _this.toolBarIndex = layer.open({
      title: false,
      type: 1,
      fixed: false,
      resize: false,
      shade: 0,
      content: $("#shapeEditContainer"),
      offset: [wTop + "px", wLeft + "px"],
      move: "#shapeEditRTCorner"
    });
    var cssSel = "#layui-layer" + _this.toolBarIndex + " .layui-layer-close2";
    $(cssSel).hide();
  },
  _createToolBar: function () {

    var _this = this;

    CreateToolbar(() => {
      _this.clear();
      layer.close(_this.toolBarIndex);
      if (_this.okHandler) {

        const textareaContent = $('#shapeEditContainer textarea.layui-textarea').val() || ''

        _this.okHandler(_this.positions, textareaContent);
      }
    }, () => {
      _this.clear();
      layer.close(_this.toolBarIndex);
      if (_this.cancelHandler) {
        _this.cancelHandler();
      }
    })

    // var _this = this;
    // var objs = $("#shapeEditContainer");
    // objs.remove();
    // var html = `<div id="shapeEditContainer" style="padding: 10px 10px;">
    //     <div class="layui-form-item">
    //       <label class="layui-form-label">描述</label>
    //       <div class="layui-input-block">
    //         <textarea name="desc" placeholder="描述内容" class="layui-textarea"></textarea>
    //       </div>
    //     </div>
    //     <div>
    //       <button name="btnCancel" class="layui-btn layui-btn-xs layui-btn-danger"> <i class="layui-icon">ဆ</i> 取消 </button>
    //       <button name="btnOK" class="layui-btn layui-btn-xs layui-btn-normal"> <i class="layui-icon"></i> 确定 </button>
    //     </div>
    //     <div id="shapeEditRTCorner" style="width: 16px; position: absolute; right: 0px; top: 0px; bottom: 0px">
    //   </div>
    // </div>`
    // $("#cesiumContainerBox").append(html);

    // var btnOK = $("#shapeEditContainer button[name='btnOK']");
    // var btnCancel = $("#shapeEditContainer button[name='btnCancel']");
    // btnOK.unbind("click").bind("click", function () {
    //   _this.clear();
    //   layer.close(_this.toolBarIndex);
    //   if (_this.okHandler) {

    //     const textareaContent = $('#shapeEditContainer textarea.layui-textarea').val() || ''

    //     _this.okHandler(_this.positions, textareaContent);
    //   }
    // });
    // btnCancel.unbind("click").bind("click", function () {
    //   _this.clear();
    //   layer.close(_this.toolBarIndex);
    //   if (_this.cancelHandler) {
    //     _this.cancelHandler();
    //   }
    // });
  },
  _isSimpleXYZ: function (p1, p2) {
    if (p1.x == p2.x && p1.y == p2.y && p1.z == p2.z) {
      return true;
    }
    return false;
  },
  _clearMarkers: function (layerName) {
    var _this = this;
    var viewer = _this.viewer;
    var entityList = viewer.entities.values;
    if (entityList == null || entityList.length < 1)
      return;
    for (var i = 0; i < entityList.length; i++) {
      var entity = entityList[i];
      if (entity.layerId == layerName) {
        viewer.entities.remove(entity);
        i--;
      }
    }
  },
  CLASS_NAME: "GlobeRectangleDrawer"
};

export default GlobeRectangleDrawer;