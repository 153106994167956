<template>
  <div
    :class="{
      'iframe-box': true,
      'iframe-hide': iframeIsMiniSize
    }"
  >
    <!-- @NODE 如果需要在静默加载(视图不存在,但实际需要预先运行)，前提条件是，iframe最起码要有在浏览器上有所展示（即使展示0.1像素）才可以 -->
    <iframe v-show="isShown" class="iframe" ref="iframe" id="thing" name="thing" scrolling="no" border="none" :src="thingSrc"></iframe>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import HtmlHostMsg from 'HtmlHostMsg'

import { getListIncludeSelfBuild } from '@/api/PlanDrill'

const handlerCallBackItemList = ['load', 'floor', 'screen', 'tool', 'linkWater', 'play']

const isDevelopment = process.env.NODE_ENV !== 'production'

const callBackFnList = {
  load: 'callBackLoadFn',
  floor: 'callBackFloorFn',
  screen: 'callBackScreenFn',
  tool: 'callBackToolFn', // event: line、point、polygon
  linkWater: 'callBackLinkWaterFn', //水带

  // 播放结束回调
  play: 'callBackPlayPlan'
}

export default {
  name: 'TDContain',

  data() {
    return {
      isShown: false,
      thingSrc: `http${isDevelopment ? '' : 's'}://www.thingjs.com/s/fb23be4dd1f266439024d47a?params=105b0f77fd24654d4eebc434e9`,
      iframeIsMiniSize: false
    }
  },

  created() {
    this.$events = [
      [
        'change.iframe.model.size',
        (status) => {
          this.iframeIsMiniSize = status
        }
      ]
    ]

    this.$events.forEach(event => this.$EventBus.$on(...event))
  },

  beforeDestroy() {
    this.iframeFirstLoad = true
    this.$events.forEach(event => this.$EventBus.$off(...event))
  },

  mounted() {
    this.$nextTick().then(() => {
      HtmlHostMsg.init(this.$refs.iframe)

      handlerCallBackItemList.forEach((v) => {
        HtmlHostMsg.handler(v, this.handlerFn.bind(this))
      })
    })
  },

  computed: {
    ...mapState('planDrill', ['moduleBuildId', 'loadUrl']),

    unitId() {
      return this.$store.state.user.currentUnitId || this.$route.query.id
    }
  },

  watch: {
    loadUrl: {
      handler(val) {
        if (val) {
          this.isShown = true
          if (this.iframeFirstLoad) {
            setTimeout(() => {
              HtmlHostMsg.event('load', { url: val, reload: true, drawEndClick: 0 })
            }, 500)
          }
        } else {
          this.isShown = false
          this.iframeFirstLoad = true
        }
      }
    }
  },

  methods: {
    handlerFn(obj, data, event) {
      if (!this.unitId) return

      if (callBackFnList[event]) {
        this[callBackFnList[event]](data, event)
      }
    },

    /* 加载条 */
    callBackLoadFn(data) {
      if (data === 1) {
        this.iframeFirstLoad = false
        HtmlHostMsg.event('load', {
          reload: true,
          url: this.loadUrl,
          drawEndClick: 0
        })
      }
      if (data.load) {
        this.$EventBus.$emit('iframe.load.complete')

        if (this.loadUrl) {
          this.getBuildArrFn()
        }
      }
    },

    /* 获取建筑物，层级 */
    callBackFloorFn(data) {
      this.$store.commit('iframe/SET_IFRAME_ISRELEVANCE', data)
    },

    /* 绘画回调 */
    callBackToolFn(editData) {
      console.log(editData)
      this.$EventBus.$emit('draw.tool.callback.inside', editData)
      this.$store.commit('planDrill/SET_PLANDRILL_TOOL', editData)
    },

    callBackScreenFn(url) {
      this.$store.commit('planDrill/SET_PLAN_SCREEN', url)
    },

    /* 连接水带回调 */
    callBackLinkWaterFn(editData) {
      this.$store.commit('planDrill/SET_PLANDRILL_LINKWATER', editData)
    },

    callBackPlayPlan() {
      this.$EventBus.$emit('plan.play.end')
    },

    /* 获取当前build建筑 */
    getBuildArrFn() {
      if (!this.moduleBuildId || !this.unitId) return
      getListIncludeSelfBuild({
        buildId: this.moduleBuildId,
        unitId: this.unitId
      }).then((res) => {
        if (!res) return this.$message.error('请先进行建筑物关联，再进行预案演练哦~')
        HtmlHostMsg.event('floor', res)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.iframe-box {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;

  .iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
}

.iframe-hide {
  transition: width, height 0.2s;
  .iframe {
    width: 1px;
    height: 1px;
    opacity: 0.1;
  }
}
</style>
