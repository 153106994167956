import ZK from '@gis/zk-base'
import { isPc } from '@/utils/util'
import EntityGenerate from './EntityGenerate'
import GlobeTracker from '../drawHelper/GlobeTracker'

let viewer, tracker, entityGenerate, tilesetLayer
let layerindex = 0

// const tiandituToken = 'c5911e8f70fdde87023863e3bb137d45'
// const tiandituToken = '8f8841cf222ba3a8c8a2a4d1d83f96b6'

/**
 * Cesium基础代码
 * 跟业务没有强关联
 */
export default {
  mounted() {
    this.initZkCesium()
  },

  destroyed() {
    if (!viewer) return
    try {
      //cesium引擎销毁无效导致GPU内存持续增长，需注销vue组件时手动强制清除webgl上下文
      viewer.canvas.getContext('webgl').getExtension('WEBGL_lose_context').loseContext()
      viewer = null
    } catch (error) {
      console.log(error)
    }
  },

  methods: {
    getViewer() {
      return viewer || window.viewerData
    },

    getEntity() {
      return entityGenerate
    },

    getTracker() {
      return tracker
    },

    // 初始化ZKCesium
    initZkCesium() {
      ZK.ready(() => {
        // 开始cesium自带可视化报错弹框
        viewer = new ZK.Viewer(this.id, {
          contextOptions: {
            webgl: {
              stencil: true,
              preserveDrawingBuffer: true
            }
          }
        })

        viewer.showRenderLoopErrors = false
        window.viewerData = viewer
        this.readyZkCesium()
        this.listenMouseEvent()
        this.init3dTilesetLayer()
      })
    },

    // viewer初始化
    readyZkCesium() {
      // let imglayer = ZK.ImageryLayerFactory.createTdtImageryLayer({
      //   key: tiandituToken,
      //   style: 'img'
      // })

      // let veclayer = ZK.ImageryLayerFactory.createTdtImageryLayer({
      //   key: tiandituToken
      // })

      // let cvalayer = ZK.ImageryLayerFactory.createTdtImageryLayer({
      //   key: tiandituToken,
      //   style: 'cva'
      // })

      const imgLayer = ZK.ImageryLayerFactory.createAmapImageryLayer({
        style: 'img',
        crs: 'WGS84'
      })
      // viewer.addBaseLayer([imglayer, veclayer, cvalayer])

      viewer.addBaseLayer(imgLayer)
      // 开启罗盘
      viewer.compass.enable = true

      // 关闭错误弹窗
      viewer.showRenderLoopErrors = false

      // 性能优化
      viewer.scene.requestRenderMode = true
      // 深度检测
      // viewer.scene.globe.depthTestAgainstTerrain = true

      // 关闭特效
      viewer.scene.moon.show = false
      viewer.scene.fog.enabled = false
      viewer.scene.sun.show = false
      viewer.scene.skyBox.show = false

      tracker = new GlobeTracker(viewer)
      entityGenerate = new EntityGenerate(viewer)

      // 修改默认的鼠标手势
      // 当前为触摸屏
      const isTouch = this.$store.state.isTouchScreen
      if (isPc() && !isTouch) {
        this.changeOperatingGestures()
      } else {
        // this.changeOperatingGesturesByMobile()
      }
    },

    //底图切换
    handleSwitchMap() {
      layerindex = layerindex === 0 ? 1 : 0
      viewer.changeBaseLayer(layerindex)
      if (layerindex) {
        tilesetLayer.eachOverlay((e) => {
          e.setStyle(
            new ZK.TilesetStyle({
              color: 'color("rgba(255,255,255,0)")'
            })
          )
        }, this)
      } else {
        tilesetLayer.eachOverlay((e) => {
          e.setStyle(
            new ZK.TilesetStyle({
              color: 'color("rgba(255,255,255,1)")'
            })
          )
        }, this)
      }
    },

    handleBirdCamera() {
      this.birdCamera = true
      let viewCenter = new Cesium.Cartesian2(Math.floor(viewer.canvas.clientWidth / 2), Math.floor(viewer.canvas.clientHeight / 2))
      let worldPosition = viewer.scene.camera.pickEllipsoid(viewCenter)
      let distance = Cesium.Cartesian3.distance(worldPosition, viewer.scene.camera.positionWC)
      viewer.scene.camera.lookAt(worldPosition, new Cesium.Cartesian3(0.0, 0.0, distance))
      viewer.scene.camera.lookAtTransform(Cesium.Matrix4.IDENTITY)
      // viewer.flyToPosition(new ZK.Position(center.longitude, center.latitude, 500, 0, -90, 0))
    },

    // 修改鼠标默认的操作
    changeOperatingGestures() {
      const { LEFT_DRAG, RIGHT_DRAG, MIDDLE_DRAG, WHEEL, PINCH } = Cesium.CameraEventType
      // 倾斜视图 鼠标左键旋转
      viewer.scene.screenSpaceCameraController.tiltEventTypes = [RIGHT_DRAG]
      // 缩放设置 重新设置缩放成员
      viewer.scene.screenSpaceCameraController.zoomEventTypes = [MIDDLE_DRAG, WHEEL, PINCH]
      // 鼠标右键平移
      viewer.scene.screenSpaceCameraController.rotateEventTypes = [LEFT_DRAG]
    },

    // 修改鼠标默认的操作(手机)
    changeOperatingGesturesByMobile() {
      const { LEFT_DRAG, RIGHT_DRAG, WHEEL, PINCH, MIDDLE_DRAG } = Cesium.CameraEventType
      // 倾斜视图 鼠标左键旋转
      viewer.scene.screenSpaceCameraController.tiltEventTypes = [LEFT_DRAG]
      // 缩放设置 重新设置缩放成员
      viewer.scene.screenSpaceCameraController.zoomEventTypes = [PINCH, WHEEL, MIDDLE_DRAG]
      // 鼠标右键平移
      viewer.scene.screenSpaceCameraController.rotateEventTypes = [RIGHT_DRAG]
    },

    // 加载3dTile实景数据
    init3dTilesetLayer(tilesUrls) {
      if (!tilesUrls) {
        const { modelUrl, modelUrls = [] } = this
        if (!modelUrls.length && !modelUrl) {
          console.error('没有实景三维数据')
          return Promise.reject()
        }

        tilesUrls = modelUrls.length ? modelUrls : [modelUrl]
      }

      const layer = new ZK.TilesetLayer('layer')
      tilesetLayer = layer
      // 场景添加图层
      viewer.addLayer(layer)

      const tiles = tilesUrls.map(url => new ZK.Tileset(url))

      // 图层添加tileset组
      layer.addOverlays(tiles)

      return Promise.all(
        tiles.map((tile) => {
          // tile加载成功
          return tile.readyPromise.then((tileset) => {
            this.tileReadyPromise(tileset)
            tileset.dynamicScreenSpaceErrorHeightFalloff = -30
            // tileset.classificationType = Cesium.ClassificationType.CESIUM_3D_TILE
            tileset.loadProgress.addEventListener(this.onLoadProgress.bind(this))
          })
        })
      )
    },

    // 移除3dtile
    removeTilesetLayer() {
      if (tilesetLayer) {
        viewer.removeLayer(tilesetLayer)
        tilesetLayer = null
      }
    },

    // 3dtile加载成功回调
    tileReadyPromise(tileset) {
      if (this.readyProxy) {
        return this.$emit('ready', tileset)
      }

      // 特殊处理，兼容中化珠海石化模型数据
      const unitId = +this.$store.state.user.currentUnitId || +this.$route.query.id
      if (unitId === 70) {
        tileset.readyPromise.then((tileset) => {
          let heightOffset = -5.0 //高度
          let boundingSphere = tileset.boundingSphere
          let cartographic = Cesium.Cartographic.fromCartesian(boundingSphere.center)
          let surface = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 0.0)
          let offset = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, heightOffset)
          let translation = Cesium.Cartesian3.subtract(offset, surface, new Cesium.Cartesian3())
          tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation)
        })
      }

      viewer.zoomTo(tileset)

      // 飞到设定好的视角
      this.$nextTick(() => {
        if (this.isDefaultFly) {
          setTimeout(() => {
            this.flyToDefaultPerspective()
          }, 1000)
        }

        this.$emit('ready', tileset)
      })
    },

    // 监听tile数据完全加载完毕
    onLoadProgress(numberOfPendingRequests, numberOfTilesProcessing) {
      if (numberOfPendingRequests === 0 && numberOfTilesProcessing === 0) {
        // 只执行一次
        if (this.lock) return

        this.lock = true
        setTimeout(() => {
          this.$emit('fully-load')
        }, 500)
      }
    },

    /**
     * 监听场景鼠标事件 - start
     * onLeftClick   -> 监听鼠标左键点击事件
     * onRightClick  -> 监听鼠标右键点击事件
     * onDoubleClick -> 监听鼠标双击点击事件
     * onWheelChange -> 监听鼠标滚轮事件
     * onPinchMove   -> 触摸双指事件
     */
    listenMouseEvent() {
      const listeners = new Map([
        [ZK.MouseEventType.CLICK, this.onLeftClick],
        [ZK.MouseEventType.RIGHT_CLICK, this.onRightClick],
        [ZK.MouseEventType.DB_CLICK, this.onDoubleClick],
        [ZK.MouseEventType.WHEEL, this.onWheelChange],
        [ZK.MouseEventType.PINCH_MOVE, this.onPinchMove]
      ])

      listeners.forEach((fn, key) => viewer.on(key, fn.bind(this)))
    },

    onLeftClick({ target, surfacePosition, windowPosition }) {
      this.lastPosition = surfacePosition
      this.$emit('left-click', {
        target,
        movement: { position: windowPosition },
        cartesian: surfacePosition,
        isDrawing: this.isDrawing || this.isMeasureIng
      })
    },

    onWheelChange() {
      this.$emit('wheel')
    },

    onRightClick({ target, windowPosition }) {
      this.$emit('right-click', {
        movement: { position: windowPosition },
        target,
        isDrawing: this.isDrawing || this.isMeasureIng
      })
    },

    onDoubleClick({ target }) {
      this.$emit('double-click', target)
    },

    onPinchMove() {
      this.$emit('pinch-move')
    }

    /* 监听场景鼠标事件-end */
  }
}
