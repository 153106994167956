<template>
  <div>
    <drawing-operation ref="drawingOperation" :shown="operateShown" @cancel="cancelDraw" @confirm="handleDraw"></drawing-operation>
    <div
      :id="id"
      class="cesium-container"
      :class="{
        'is-draw': isDrawing,
        'cesium-pip': usePip,
        'cesium-pip_min': usePip && !cesiumPip,
        'cesium-pip_max': usePip && cesiumPip
      }"
    >
      <!-- 画中画的切换按钮 -->
      <i v-if="usePip && !cesiumPip" class="el-icon-sort cesium-pip" @click="changeCesiumBoxPip()"></i>
    </div>
  </div>
</template>

<script>
/**
 * @TODO: 需要将业务逻辑解耦, 目前与信息弹窗是否显示, 消防设施等耦合在一起.
 */
import DrawingOperation from '@/components/DrawingOperation'

import api from '@/api/model'
import baseMixin from './base'

import OverlayHtml from './Tools/OverlayHtml'

import measureMixin from './measure'
import ZK from '@gis/zk-base'

import { isPc } from '@/utils/util'
import { cartesian3ToLonLat, getEntityPolygonCenter } from '@/utils/cesiumUtil'

const keys = {
  polygon: 'polygon',
  line: 'polyline',
  point: 'point',
  pointModel: 'point',
  fire: 'point',
  gasLeak: 'point',
  wallZoneItem: 'polygon',
  stereogram: 'polyline',
  waterPipe: 'polyline'
}
const pointRequire = {
  polygon: 3,
  polyline: 2,
  point: 1
}
export default {
  components: { DrawingOperation },
  mixins: [baseMixin, measureMixin],

  props: {
    //地图容器
    id: {
      type: String,
      default: 'cesiumContainerBox'
    },
    // 单位id
    unitId: [String, Number],

    // 实景地址
    modelUrl: String,

    modelUrls: Array,

    loadLayerImagery: {
      type: Boolean,
      default: true
    },

    // 准备完成代理
    readyProxy: {
      type: Boolean,
      default: false
    },

    isPc: isPc(),

    usePip: Boolean,

    //是否默认定位到默认建筑
    isDefaultFly: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      isDrawing: false,
      operateShown: false,
      cesiumPip: true
    }
  },

  methods: {
    changeCesiumBoxPip(cesiumPip = this.cesiumPip) {
      this.cesiumPip = !cesiumPip
      this.$emit('change-pip', this.cesiumPip)
    },
    /**
     * 世界坐标(笛卡尔坐标cartesian3) 转 经纬度, 高度
     */
    cartesian3ToLonLat(cartesian3) {
      const viewer = this.getViewer()
      const cartographic = viewer.scene.globe.ellipsoid.cartesianToCartographic(cartesian3)
      return {
        lon: Cesium.Math.toDegrees(cartographic.longitude),
        lat: Cesium.Math.toDegrees(cartographic.latitude),
        height: viewer.camera.positionCartographic.height / 1000
      }
    },

    formatPostion(position, height = 0) {
      const viewer = this.getViewer()
      return position.map((p) => {
        const { lon, lat } = cartesian3ToLonLat(viewer, new Cesium.Cartesian3(p.x, p.y, p.z))

        return Cesium.Cartesian3.fromDegrees(lon, lat, height)
      })
    },

    // 设置当前倾斜模型的高度 (目前用不上)
    set3DTileHeight(tileset) {
      let boundingSphere = tileset.boundingSphere
      let cartographic = Cesium.Cartographic.fromCartesian(boundingSphere.center)
      let surface = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 0.0)
      let offset = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 5)

      let translation = Cesium.Cartesian3.subtract(offset, surface, new Cesium.Cartesian3())
      tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation)
    },

    handlerCommonTrack(data) {
      if (this.isDrawing) return this.$message.warning('请先完成当前绘制')
      this.layer = undefined
      this.tracker = undefined
      this.trackerPositons = []
      this.trackData = data
      const viewer = this.getViewer()
      this.tracker = new ZK.Plot(viewer, { clampToGround: false })
      let pointNum = 0

      const key = data.key
      // 点类型标绘不能取贴地坐标，模型获取的坐标会在3d tiles 底下
      this.tracker = new ZK.Plot(viewer, { clampToGround: keys[key] !== 'point' })

      this.tracker.on(ZK.PlotEventType.DRAW_ANCHOR, (position) => {
        this.trackerPositons.push(position)
        this.operateShown = ++pointNum >= pointRequire[keys[key]]
      })

      this.isDrawing = true
      this.lastTrackerKey = key
      this.tracker.draw(
        keys[key],
        (overlay) => {
          this.layer = new ZK.VectorLayer('layer')
          viewer.addLayer(this.layer)
          this.layer.addOverlay(overlay)
          this.operateShown = true
        },
        false
      )
    },

    finishDrawing() {
      if (this.layer) {
        const viewer = this.getViewer()
        viewer.removeLayer(this.layer)
      } else {
        this.tracker?.deactivate()
      }
      this.isDrawing = false
      this.operateShown = false
    },

    cancelDraw() {
      this.$emit('reset')
      this.finishDrawing()
    },

    handleDraw() {
      let positions = this.trackerPositons
      const key = keys[this.trackData.key]
      if (['point', 'billboard'].includes(key)) {
        positions = this.trackerPositons[0]
      }
      this.$emit('success', {
        positions,
        ...this.trackData
      })
      this.finishDrawing()
    },

    // 生成信息框
    generateOverlay(item) {
      const attrs = item.attrsList
      let contentLists = []

      if (attrs && attrs.length) {
        contentLists = attrs.filter(item => !!item.attrValue).map(({ label, attrValue }) => ({ label, value: attrValue }))

        // // 如果就一个位置那就不显示了
        if (contentLists.length > 0) {
          contentLists.push({
            label: '位置',
            value: item.location
          })
        }
      }

      let position = JSON.parse(item.config).positions

      if (position instanceof Array) {
        position = getEntityPolygonCenter(position, item.drawConfig.drawType)
      } else if (position) {
        position = new Cesium.Cartesian3(position.x, position.y, position.z)
      } else {
        position = Cesium.Cartesian3.fromDegrees(item.longitude, item.latitude)
      }

      let otherFunction = {}

      if (item.buildId && item.modelInfo && item.modelInfo.modelUrl) {
        otherFunction.modelEntrance = () => {
          // 进入内部模型
          this.$emit('enter-internal', {
            id: item.buildId,
            modelUrl: item.modelInfo.modelUrl
          })
        }
      } else {
        if (!item.isViews) {
          otherFunction.delItemEntity = () => this.$emit('del-entity', item)
        }
      }
      const viewer = this.getViewer()

      return new OverlayHtml(viewer, {
        position,
        data: contentLists,
        images: item.pics,
        title: item.drawName || item.drawTool.name,
        drawType: item.drawConfig.drawType,
        description: item.descr || item?.drawConfig?.options?.description,
        ...otherFunction,
        drawToolId: item.drawToolId,
        hideBtn: item.hideBtn
      })
    },

    // 获取所设置的视角并flyTo
    flyToDefaultPerspective(unitId = this.unitId) {
      if (!unitId) return Promise.resolve()

      if (this.perspectiveData) {
        this.handlerFlyToDefault()
        return Promise.resolve()
      }

      return api
        .rangeGet({
          unitId
        })
        .then((data) => {
          this.perspectiveData = data
          this.handlerFlyToDefault()
        })
        .catch(console.error)
    },

    handlerFlyToDefault() {
      const data = this.perspectiveData
      if (+data.id === 0) return
      const { position: p, heading, pitch, roll } = JSON.parse(data.config)

      const viewer = this.getViewer()
      viewer.camera.flyTo({
        destination: new Cesium.Cartesian3(p.x, p.y, p.z),
        orientation: {
          heading,
          pitch,
          roll
        }
      })
    },

    toggleLayerImagery() {
      //@TODO: 控制底图的透明度
    },

    // 飞向雷达扫描范围
    flyToScan(radius) {
      const viewer = this.getViewer()

      const { lng, lat } = this.scanPosition
      viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(lng, lat, 3 * radius),
        orientation: {
          heading: Cesium.Math.toRadians(0), // east, default value is 0.0 (north) //东西南北朝向
          pitch: Cesium.Math.toRadians(-90), // default value (looking down)  //俯视仰视视觉
          roll: 0.0
        },
        duration: 3 //3秒到达战场
      })
    },

    flyToEntity(entity) {
      const viewer = this.getViewer()

      viewer.flyTo(entity, {
        duration: 1
      })
    },

    // 根据destination飞向莫表
    handlerFlyTo(destination, options = {}) {
      let orientation = {
        heading: Cesium.Math.toRadians(0), // east, default value is 0.0 (north) //东西南北朝向
        pitch: Cesium.Math.toRadians(-90), // default value (looking down)  //俯视仰视视觉
        roll: 0
      }
      // const data = this.perspectiveData
      // if (+data.id !== 0)  {
      //   const { heading, pitch, roll } = JSON.parse(data.config)
      //   orientation = { heading, pitch, roll }
      // }
      return new Promise((resolve) => {
        const viewer = this.getViewer()

        viewer.camera.flyTo({
          destination,
          orientation,
          duration: 1.5,
          ...options,
          complete: resolve
        })
      })
    },

    handlerViewerFlyToEntity(primitive) {
      let entity = primitive

      if (primitive instanceof Array) {
        entity = primitive.filter(item => item instanceof Cesium.Entity)[0]
      }

      if (!(entity instanceof Cesium.Entity)) return

      // return new Promise(resolve => {
      //   console.log(entity)
      // })
    },

    // 飞向一个集合
    handlerFlyToAssemble(positions) {
      const viewer = this.getViewer()

      const boundingSphere = Cesium.BoundingSphere.fromPoints(positions)

      return new Promise((resolve) => {
        viewer.camera.flyToBoundingSphere(boundingSphere, {
          complete: resolve
        })
      })
    },

    c2LonLat(cartesian3) {
      const viewer = this.getViewer()

      return cartesian3ToLonLat(viewer, cartesian3)
    },

    // 聚焦后闪烁
    focuAndflash(primitive, data) {
      let entity = primitive

      if (primitive instanceof Array) {
        entity = primitive.filter(item => item instanceof Cesium.Entity)[0]
      }

      if (!(entity instanceof Cesium.Entity)) return

      this.getEntityColor(entity, data)
    },

    getEntityColor(entity, data) {
      const shapeType = entity.shapeType.toLowerCase()
      const entityByType = entity[shapeType]

      let x = 1

      //清除历史标签
      // if (this.$parent.lastEntity) {
      //   this.$parent.lastEntity.label.text = ''
      // }
      //生成详情弹窗
      // this.$parent.handlerHideLastOverlay()
      // this.$parent.changeOverlay(`${data.drawToolId}-${data.id}`, true)

      switch (shapeType) {
        case 'billboard':
          {
            let i = 3

            const handler = () => {
              entityByType.scale = 2
              i--
              let timer = setTimeout(() => {
                clearTimeout(timer)
                entityByType.scale = 1
                if (i === 0) return
                handler()
              }, i * 500)
            }

            handler()

            // setTimeout(() => {
            //   entityByType.scale = true
            // }, 2000)
          }
          break
        case 'polygon':
          {
            const lastColor = entityByType.material
            let flog = true

            entityByType.material = new Cesium.ColorMaterialProperty(
              new Cesium.CallbackProperty(function () {
                if (flog) {
                  x = x - 0.02
                  if (x <= 0) {
                    flog = false
                  }
                } else {
                  x = x + 0.02
                  if (x >= 1) {
                    flog = true
                  }
                }
                return Cesium.Color.RED.withAlpha(x)
              }, false)
            )

            // 恢复正常
            setTimeout(() => {
              entityByType.material = lastColor
            }, 2000)
          }
          break
        case 'label':
          {
            entity.label.text = data.drawName
            this.$parent.lastEntity = entity
          }
          break
      }
    },

    // 获取当前摄像机的二维图像
    getCurrentCanvas() {
      const viewer = this.getViewer()

      const deferred = Cesium.when.defer()
      const scene = viewer.scene
      const removeCallback = scene.postRender.addEventListener(function () {
        removeCallback()
        try {
          deferred.resolve(viewer.scene.canvas)
        } catch (e) {
          deferred.reject(e)
        }
      }, this)

      scene.render(viewer.clock.currentTime)

      return deferred.promise
    },

    captureScreenshot(qualityOf = 0.8) {
      return this.getCurrentCanvas().then((canvas) => {
        return canvas.toDataURL('image/webp', qualityOf)
      })
    },

    // 获取当前摄像机高度
    getCameraHeight() {
      const viewer = this.getViewer()
      let ellipsoid = viewer.scene.globe.ellipsoid
      let height = ellipsoid.cartesianToCartographic(viewer.camera.position).height

      return height
    },

    // 根据实体对象飞过去
    handlerFlyToByEntity(entity, options) {
      options.height = options.height || 200
      options.angle = options.angle || 45
      options.duration = options.duration || 1.2

      let heading = 0
      let offset = new Cesium.HeadingPitchRange(Cesium.Math.toRadians(heading), -Cesium.Math.toRadians(options.angle), options.height)

      return this.getViewer().flyTo(entity, {
        offset,
        duration: options.duration
      })
    }
  }
}
</script>

<style>
@import './index.css';

#cesiumContainerBox .zk-compass {
  top: 60px;
  left: 50%;
  transform: translateX(-50%) scale(1.2);
}

@media screen and (max-width: 880px) {
  #cesiumContainerBox .zk-compass {
    top: 6px;
    left: 10px;
    transform: scale(0.8);
  }
}

[data-ops-rec-box] {
  display: none;
}
</style>

<style lang="scss" scoped>
#cesiumContainerBox {
  // width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 1;
  /deep/ .cesium-widget-credits {
    display: none !important;
  }
  .viewer-container {
    width: 100%;
    height: 100vh;
  }
}

/*弹出框样式*/
.cesium-popup {
  position: absolute;
  left: 0;
  top: 5px;
  text-align: left;
}
.cesium-popup-background {
  background: rgba(35, 72, 72, 0.6);
  border-radius: 6px;
}
.cesium-popup-content-wrapper {
  text-align: center;
  max-height: 600px;
  overflow-y: auto;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
  text-align: left;
  border-radius: 3px;
}
.cesium-popup-color {
  color: white;
}
.cesium-popup-content {
  margin: 15px 10px 10px;
  line-height: 1.4;
  font-size: 13px;
  max-width: 439px;
  min-width: 50px;
}
.cesium-popup-tip-container {
  margin: 0 auto;
  width: 40px;
  height: 13px;
  position: relative;
  overflow: hidden;
}
.cesium-popup-tip {
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.cesium-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  text-decoration: none;
  font-weight: 700;
  background: transparent;
  z-index: 9999;
}
.cesium-popup-close-button:hover {
  cursor: pointer;
  color: #23527c;
}

#cesiumContainerBox.cesium-pip {
  &_min {
    width: 400px;
    height: 300px;
    position: absolute;
    top: 64px;
    left: unset;
    right: 19px;
    z-index: 20;
  }

  .el-icon-sort {
    padding: 10px;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;

    border: 1px solid #18cffd;
    transform: rotate(90deg);
    color: #18cffd;
    border-radius: 50%;
    font-size: 18px;
    padding: 2px;
    cursor: pointer;
    &:hover {
      transform: rotate(90deg) scale(1.1);
    }
  }
}
::v-deep {
  .zk-map-switch {
    display: none;
  }
}
</style>
