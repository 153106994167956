const PI = 3.14159265358979324
const X_PI = PI * 3000.0 / 180.0

// 卫星椭球坐标投影到平面地图坐标系的投影因子。
const PROJECTION_FACTOR = 6378245.0

// 椭球的偏心率
const ECCENTRICITY = 0.00669342162296594323

export default {
  // 百度坐标bd09II转火星坐标GCJ02
  baiduTomars(lng, lat) {
    let marsPoint = { lng: 0, lat: 0 }
    let x = lng - 0.0065
    let y = lat - 0.006
    let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI)
    let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI)
    marsPoint.lng = z * Math.cos(theta)
    marsPoint.lat = z * Math.sin(theta)
    return marsPoint
  },

  // 火星坐标GCJ02转百度坐标bd09II
  marsTobaidu(marsPoint) {
    let baiduPoint = { lng: 0, lat: 0 }
    let x = marsPoint.lng
    let y = marsPoint.lat
    let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI)
    let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI)
    baiduPoint.lng = z * Math.cos(theta) + 0.0065
    baiduPoint.lat = z * Math.sin(theta) + 0.006
    return baiduPoint
  },

  // 公用转换纬度
  transformLat(x, y) {
    let ret = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x))
    ret += (20.0 * Math.sin(6.0 * x * PI) + 20.0 * Math.sin(2.0 * x * PI)) * 2.0 / 3.0
    ret += (20.0 * Math.sin(y * PI) + 40.0 * Math.sin(y / 3.0 * PI)) * 2.0 / 3.0
    ret += (160.0 * Math.sin(y / 12.0 * PI) + 320 * Math.sin(y * PI / 30.0)) * 2.0 / 3.0
    return ret
  },

  // 公用转换经度
  transformLng(x, y) {
    let ret = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x))
    ret += (20.0 * Math.sin(6.0 * x * PI) + 20.0 * Math.sin(2.0 * x * PI)) * 2.0 / 3.0
    ret += (20.0 * Math.sin(x * PI) + 40.0 * Math.sin(x / 3.0 * PI)) * 2.0 / 3.0
    ret += (150.0 * Math.sin(x / 12.0 * PI) + 300.0 * Math.sin(x / 30.0 * PI)) * 2.0 / 3.0
    return ret
  },

  // 地球坐标系WGS84转火星坐标系GCJ02
  wg842Gcj02(wgLng, wgLat) {
    /*判断是否在国内，不在国内则不做偏移*/
    const outOfChina = (lng, lat) => (lng < 72.004 || lng > 137.8347) && (lat < 0.8293 || lat > 55.8271)

    let marsPoint = { lng: 0, lat: 0 }
    if (outOfChina(wgLng, wgLat)) {
      marsPoint.lat = wgLat
      marsPoint.lng = wgLng
      return
    }
    let dLat = this.transformLat(wgLng - 105.0, wgLat - 35.0)
    let dLng = this.transformLng(wgLng - 105.0, wgLat - 35.0)
    let radLat = wgLat / 180.0 * PI
    let magic = Math.sin(radLat)
    magic = 1 - ECCENTRICITY * magic * magic
    let sqrtMagic = Math.sqrt(magic)
    dLat = dLat * 180.0 / (PROJECTION_FACTOR * (1 - ECCENTRICITY) / (magic * sqrtMagic) * PI)
    dLng = dLng * 180.0 / (PROJECTION_FACTOR / sqrtMagic * Math.cos(radLat) * PI)
    marsPoint.lat = wgLat + dLat
    marsPoint.lng = wgLng + dLng
    return marsPoint
  },

  // 火星坐标系GCJ02转地球坐标系WGS84
  gcj02Wg84(gcjLng, gcjLat) {
    const delta = (lng, lat) => {
      let dLat = this.transformLat(lng - 105.0, lat - 35.0)
      let dLng = this.transformLng(lng - 105.0, lat - 35.0)
      let radLat = lat / 180.0 * PI
      let magic = Math.sin(radLat)
      magic = 1 - ECCENTRICITY * magic * magic
      let sqrtMagic = Math.sqrt(magic)
      dLat = dLat * 180.0 / (PROJECTION_FACTOR * (1 - ECCENTRICITY) / (magic * sqrtMagic) * PI)
      dLng = dLng * 180.0 / (PROJECTION_FACTOR / sqrtMagic * Math.cos(radLat) * PI)
      return {
        lat: dLat,
        lng: dLng
      }
    }

    let d = delta(gcjLng, gcjLat)

    return {
      lat: gcjLat - d.lat,
      lng: gcjLng - d.lng
    }
  },

  // wg84 -> 百度
  wg842Baidu(wgLng, wgLat) {
    const gcj02 = this.wg842Gcj02(wgLng, wgLat)

    return this.marsTobaidu(gcj02)
  }
}
