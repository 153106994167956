<template>
  <div>
    <div class="info_model" v-if="showFireOrganization">
      <div class="fire-protection-organization">
        <top-title-bg>组织结构</top-title-bg>
        <ul class="fire-protection-organization-content">
          <li v-for="group in fireGroupList" :key="group.id">
            <el-tooltip effect="dark" :content="group.duty" placement="left">
              <p class="group-title">{{ group.name }}</p>
            </el-tooltip>
            <ul class="group-content_box">
              <li class="group-content" v-for="item in group.humans" :key="item.id">
                <el-avatar :src="item.headImage"></el-avatar>
                <div class="user-info">
                  <p>{{ item.name }}</p>
                  <p>{{ item.contact }}</p>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="showFireOrganization" class="infor-model_mask" @click="onClose"></div>
  </div>
</template>

<script>
import { deepClone } from '@/utils/util'
import { humanGroupList } from '@/api/file-info'

const infoData = {
  id: null
}

export default {
  props: {
    inPlan: Boolean,
    info: {
      type: Object,
      default: () => deepClone(infoData)
    }
  },

  watch: {
    info: {
      handler(val = {}) {
        let userCompanyInfo = deepClone(val)
        this.$set(this, 'userCompanyInfo', userCompanyInfo)
        if (userCompanyInfo) {
          this.fetchHumanGroupLists()
        }
      },
      deep: true
    }
  },

  data() {
    return {
      showFireOrganization: false,
      fireGroupList: [],
      userCompanyInfo: deepClone(infoData)
    }
  },

  methods: {
    open() {
      this.showFireOrganization = true
      if (this.userCompanyInfo.id) {
        this.fetchHumanGroupLists()
      }
    },

    fetchHumanGroupLists() {
      return humanGroupList({
        unitId: this.userCompanyInfo.id
      }).then((data) => {
        this.fireGroupList = data
      })
    },
    changeOpenState() {
      this.showFireOrganization = !this.showFireOrganization
    },
    onClose() {
      this.showFireOrganization = false
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/styles/mixins';

$colorYellow: #d09f29;
$colorWhite: #ececec;
$bgColor: rgba(3, 9, 18, 0.9);
$colorBlue: #07eef5;

.infor-model_mask {
  @include size(100vw, 100vh);
  background-color: rgba($color: #000000, $alpha: 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
}

.info_model {
  @include size(430px, 74%);

  padding: 15px 25px;
  background: rgba(3, 9, 18, 0.9);
  border-radius: 5px;

  transition: transform 0.6s;

  position: absolute;
  left: 50%;
  top: 59px;
  transform: translateX(-50%);
  z-index: 5;

  .el-icon-close {
    font-size: 25px;
    color: #fff;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3;
  }

  @media screen and (max-width: 768px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.638);
    zoom: 1;
  }
}

.fire-protection-organization {
  width: 489px;
  height: 100%;
  background: linear-gradient(0deg, rgba(2, 15, 33, 0.4), rgba(2, 15, 33, 0.4)), url('~@/assets/images/planDrill/planDrill_bg.png') no-repeat center center;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 10;
  padding: 22px;
  padding-top: 0;
  box-sizing: border-box;
  .fire-protection-organization-content {
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - 100px);
    padding-bottom: 30px;
    padding-top: 10px;
    overflow-y: scroll;
    li {
      width: 100%;
      margin-bottom: 10px;
      // margin-right: 12px;
      user-select: none;
      .group-content_box {
        @include flex-center();
        flex-wrap: wrap;
        li {
          margin-right: 10px;
          &:nth-child(2n) {
            margin-right: 0;
          }
        }
      }

      .group-title {
        margin-bottom: 15px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
      }
      .group-content {
        width: 212px;
        height: 84px;
        background: rgba(3, 37, 74, 0.54);
        border: 1px solid #1077ba;
        padding: 12px;
        box-sizing: border-box;
        @include flex-center(flex-start);
      }
      .el-avatar {
        width: 60px;
        height: 60px;
        margin-right: 9px;
      }
      .user-info {
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        p {
          margin-bottom: 12px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
