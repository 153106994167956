<template>
  <div class="carousel-box" v-if="show">
    <el-carousel trigger="click">
      <el-carousel-item v-for="(item, index) in pictureList" :key="index">
        <img :src="item" alt="" />
      </el-carousel-item>
    </el-carousel>
    <div class="el-icon-close" @click="closeCarouselBox"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pictureList: [
        'https://fastdfs.equiplink.cn/group1/M00/00/17/wKgAW2C0r2OAT_qFAAIDyKZhYyk392.jpg?filename=1F.jpg',
        'https://fastdfs.equiplink.cn/group1/M01/00/17/wKgAo2C0sNmAaJ1nAAHFUKsVf54618.jpg?filename=2F.jpg',
        'https://fastdfs.equiplink.cn/group1/M01/00/17/wKgAW2C0sNmAUhRdAAIGfGvaFmg833.jpg?filename=3F.jpg',
        'https://fastdfs.equiplink.cn/group1/M02/00/17/wKgAo2C0sNmATIYUAAH9fZ2YA8g933.jpg?filename=4F.jpg',
        'https://fastdfs.equiplink.cn/group1/M02/00/17/wKgAW2C0sNmAZ2-tAAGEpHZF2yQ349.jpg?filename=5F.jpg',
        'https://fastdfs.equiplink.cn/group1/M00/00/17/wKgAo2C0sNmAVyDwAAGfybOcrvk520.jpg?filename=6F.jpg',
        'https://fastdfs.equiplink.cn/group1/M00/00/17/wKgAW2C0sNmAQWj7AAGASC0FaAc092.jpg?filename=11F.jpg',
        'https://fastdfs.equiplink.cn/group1/M01/00/17/wKgAo2C0sNmATXOkAAF8JnC6BqA426.jpg?filename=14F.jpg',
        'https://fastdfs.equiplink.cn/group1/M01/00/17/wKgAW2C0sNmAFRRnAAGLFPS9EzI813.jpg?filename=15F.jpg',
        'https://fastdfs.equiplink.cn/group1/M02/00/17/wKgAo2C0sNmAHZSYAAGSWmXmNvU650.jpg?filename=16_23F.jpg',
        'https://fastdfs.equiplink.cn/group1/M02/00/17/wKgAW2C0sNmAUyrsAAGL2zzseK4523.jpg?filename=24F.jpg',
        'https://fastdfs.equiplink.cn/group1/M00/00/17/wKgAo2C0sNmAYE_6AAGhe6M0ZGE902.jpg?filename=25_29F.jpg',
        'https://fastdfs.equiplink.cn/group1/M00/00/17/wKgAW2C0sNmAZOIlAAGO00Egakw665.jpg?filename=30_33F.jpg',
        'https://fastdfs.equiplink.cn/group1/M01/00/17/wKgAo2C0sNmAO0WoAAH2JElzZdA087.jpg?filename=B1.jpg',
        'https://fastdfs.equiplink.cn/group1/M01/00/18/wKgAW2C0sNmAMdlwAAIh7DgIc94178.jpg?filename=B2.jpg',
        'https://fastdfs.equiplink.cn/group1/M02/00/17/wKgAo2C0sNqAFZGQAAKUnLpqhpw972.jpg?filename=B3.jpg',
        'https://fastdfs.equiplink.cn/group1/M02/00/18/wKgAW2C0sNqAI0mtAAGMR9JOpYo599.jpg?filename=%E6%9C%BA%E6%88%BF%E5%B1%82.jpg'
      ],
      show: false
    }
  },
  methods: {
    closeCarouselBox() {
      this.show = false
    },
    openCarouselBox() {
      this.show = true
    }
  }
}
</script>

<style scoped lang="scss">
.carousel-box {
  position: absolute;
  top: 60px;
  bottom: 0;
  right: 20%;
  left: 20%;
  background-color: #000;
  z-index: 10;
  /deep/.el-carousel {
    height: 100%;
    .el-carousel__container {
      height: 100% !important;
      .el-carousel__arrow {
        font-size: 30px;
        height: 60px;
        width: 60px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .el-carousel__indicators {
      .el-carousel__indicator {
        padding: 12 10px;
        .el-carousel__button {
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }
      }
    }
  }
  .el-icon-close {
    font-size: 40px;
    position: absolute;
    right: 10px;
    top: 10px;
    color: #fff;
    z-index: 2;
    cursor: pointer;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }

  @media screen and (max-width: 768px) {
    left: 0;
    right: 0;
  }
}
</style>
